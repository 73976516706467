import Layout from "../../../Layout";
import ManageMembersTable from "./ManageMembersTable/ManageMembersTable";

const ManageMembers = () => {
   

    return (
     
                <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row" style={{paddingTop : '80px'}}>
                                <div className="col-12 grid-margin">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4>Users Table</h4>
                                            <ManageMembersTable/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
    );
}

export default ManageMembers;