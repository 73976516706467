import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Sidebar = (props) => {
  const currentRoute = useLocation();
  const userType = localStorage.getItem('type');
  // const navbarComponents = useState([
  //   'dashboard',
  //   'add-onboarding',
  //   'manage-onboarding',
  //   'documents',
  //   'manage-documents',
  //   'polygon-mapping',
  //   'manage-polygon-mapping',
  //   'export-polygons',
  //   'add-feild-information',
  //   'manage-feild-information',
  //   'add-landrecords',
  //   'manage-landrecords',
  //   'add-aeration',
  //   'manage-aeration',
  //   'manage-members',
  //   'track',
  //   'approvals',
  //   'manage-location'
  // ]);
  // const [rout, setRout] = useState(false)
  // const [routName, setRoutName] = useState("")
 

  //  const routesWithPrompt = [
  //   "/add-onboarding",
  //   "/documents",
  //   "/polygon-mapping",
  //   "/add-feild-information",
  //   "/add-landrecords",
  //   "/add-aeration"
  // ];


  // useEffect(() => {
  //   if (routesWithPrompt.includes(location.pathname)) {
  //     setRout(true)
  //     setRoutName(location.pathname)
  //   }
  // }, [location.pathname]);


  // useEffect(() => {
  //   if (rout) {
  //     if (routName !== location.pathname) {
  //       alert("hello")
  //       setRout(false)
  //       setRoutName("")
  //     }
  //   }
  // }, [location.pathname])

  if (userType === null) {
    return <div>Loading...</div>;
  }
    return (
        <nav className={`sidebar sidebar-offcanvas ${props.isSidebarOpen==true? 'active' : ''} bg-green`} id="sidebar"  >
        <ul className="nav">
          <li className={`nav-item ${currentRoute.pathname === '/dashboard' ? 'active' : ''}`}>
            <Link className="nav-link" to="/dashboard">
              <i className="typcn typcn-chart-area menu-icon"></i>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>

          {/* Onboarding */}
          {
            userType != 'intern' && <li className={`nav-item ${currentRoute.pathname === '/manage-onboarding' || currentRoute.pathname === '/add-onboarding' ? 'active' : ''}`}>
            <a className="nav-link " data-toggle="collapse" href="#onboarding" aria-expanded="false" aria-controls="onboarding">
              <i className="typcn typcn-th-list-outline menu-icon"></i>
              <span className="menu-title">Onboarding</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="onboarding">
              <ul className="nav flex-column sub-menu">
                { userType != 'viewer' ? <li className="nav-item"> <Link className="nav-link " to="/add-onboarding">Add </Link></li> : null }
                <li className="nav-item"> <Link className="nav-link " to="/manage-onboarding">Manage </Link></li>
              </ul>
            </div>
          </li>
          }

          {/* Documents */}
          {
            userType!= 'intern' && <li className={`nav-item ${currentRoute.pathname === '/documents' || currentRoute.pathname === '/manage-documents' ? 'active' : ''}`}>
              <a className="nav-link " data-toggle="collapse" href="#documents" aria-expanded="false" aria-controls="documents">
                <i className="typcn typcn-document-text menu-icon"></i>
                <span className="menu-title">Documents</span>
                <i className="menu-arrow"></i>
              </a>
              <div className="collapse" id="documents">
                <ul className="nav flex-column sub-menu">
                  { userType != 'viewer' ? <li className="nav-item"> <Link className="nav-link " to="/documents">Upload </Link></li> : null }
                  <li className="nav-item"> <Link className="nav-link " to="/manage-documents">Manage </Link></li>
                </ul>
              </div>
            </li>
          }

          {/* Polygon map */}

          {
            userType != 'intern' && <li className={`nav-item ${currentRoute.pathname === '/polygon-mapping' || currentRoute.pathname === '/manage-polygon-mapping' || currentRoute.pathname === '/export-polygons' ? 'active' : ''}`}>
            <a className="nav-link " data-toggle="collapse" href="#auth" aria-expanded="false" aria-controls="auth">
              <i className="typcn typcn-map menu-icon"></i>
              <span className="menu-title">Polygon Mapping</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="auth">
              <ul className="nav flex-column sub-menu">
                {
                  userType != 'viewer' ? <li className="nav-item"> <Link className="nav-link " to="/polygon-mapping"> Add </Link></li> : null
                }
                
                <li className="nav-item"> <Link className="nav-link" to="/manage-polygon-mapping"> Manage </Link></li>
                {/* <li className="nav-item"> <Link className="nav-link" to="/export-polygons"> Download KML </Link></li> */}
              </ul>
            </div>
          </li>
          }

          {/* Feild Information */}
          {
            userType != 'intern' && <li className={`nav-item ${currentRoute.pathname === '/add-feild-information' || currentRoute.pathname === '/manage-feild-information' ? 'active' : ''}`}>
              <a className="nav-link " data-toggle="collapse" href="#form-elements" aria-expanded="false" aria-controls="form-elements">
                <i className="typcn typcn-film menu-icon"></i>
                <span className="menu-title">Crops Information</span>
                <i className="menu-arrow"></i>
              </a>
              <div className="collapse" id="form-elements">
                <ul className="nav flex-column sub-menu">
                  {
                    userType != 'viewer' ? <li className="nav-item"><Link className="nav-link " to="/add-feild-information">Add </Link></li> : null
                  }
                  <li className="nav-item"><Link className="nav-link " to="/manage-feild-information">Manage</Link></li>
                </ul>
              </div>
            </li>
          }

          {/* Land Records */}
          <li className={`nav-item ${currentRoute.pathname === '/add-landrecords' || currentRoute.pathname === '/manage-landrecords' ? 'active' : ''}`}>
            <a className="nav-link " data-toggle="collapse" href="#landrecords" aria-expanded="false" aria-controls="landrecords">
              <i className="typcn typcn-document-text menu-icon"></i>
              <span className="menu-title">Land Records</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="landrecords">
              <ul className="nav flex-column sub-menu">
                {
                  userType!='viewer' ? <li className="nav-item"> <Link className="nav-link " to="/add-landrecords">Add </Link></li> : null
                }
                
                <li className="nav-item"> <Link className="nav-link " to="/manage-landrecords">Manage </Link></li>
              </ul>
            </div>
          </li>

          {/* aeration */}
          <li className={`nav-item ${currentRoute.pathname === '/add-aeration' || currentRoute.pathname === '/manage-aeration' ? 'active' : ''}`}>
            <a className="nav-link " data-toggle="collapse" href="#charts" aria-expanded="false" aria-controls="charts">
              <i className="typcn typcn-chart-pie-outline menu-icon"></i>
              <span className="menu-title">Aeration</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="charts">
              <ul className="nav flex-column sub-menu">
                {
                  userType != 'viewer' ? <li className="nav-item"> <Link className="nav-link " to="/add-aeration">Add </Link></li> : null
                }
                
                <li className="nav-item"> <Link className="nav-link" to="/manage-aeration">Manage</Link></li>
              </ul>
            </div>
          </li>
          {/* Feild Teams */}
          {
            userType === 'super-admin' &&
            <li className={`nav-item ${currentRoute.pathname === '/manage-members' ? 'active' : ''}`}>
            <a className="nav-link" data-toggle="collapse" href="#feild-teams" aria-expanded="false" aria-controls="feild-teams">
              <i className="typcn typcn-user-add-outline menu-icon"></i>
              <span className="menu-title">Users</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="feild-teams">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className="nav-link" to="/manage-members"> Manage </Link></li>
              </ul>
            </div>
          </li> 
          }



          {
            userType === 'super-admin' &&
            <li className={`nav-item ${currentRoute.pathname === '/track' ? 'active' : ''}`}>
            <a className="nav-link " data-toggle="collapse" href="#track-users" aria-expanded="false" aria-controls="track-users">
              <i className="typcn typcn-group menu-icon"></i>
              <span className="menu-title">Track Users</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="track-users">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className="nav-link " to="/track"> Manage </Link></li>
              </ul>
            </div>
          </li> 
          }

          {
            userType === 'super-admin' &&
            <li className={`nav-item ${currentRoute.pathname === '/approvals' ? 'active' : ''}`}>
              <a className="nav-link " data-toggle="collapse" href="#approvals" aria-expanded="false" aria-controls="track-users">
                <i className="typcn typcn-input-checked menu-icon"></i>
                <span className="menu-title">Approvals</span>
                <i className="menu-arrow"></i>
              </a>
              <div className="collapse" id="approvals">
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className="nav-link" to="/approvals"> Manage </Link></li>
                </ul>
              </div>
            </li> 
          }
          
          {
            (userType === 'super-admin' || userType === 'admin') &&
            <li className={`nav-item ${currentRoute.pathname === '/manage-location' ? 'active' : ''}`}>
              <a className="nav-link " data-toggle="collapse" href="#location" aria-expanded="false" aria-controls="track-users">
                <i className="typcn typcn-location menu-icon"></i>
                <span className="menu-title">Locations</span>
                <i className="menu-arrow"></i>
              </a>
              <div className="collapse" id="location">
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className="nav-link" to="/manage-location"> Manage </Link></li>
                </ul>
              </div>
            </li> 
          }
        
        </ul>
      </nav>
    );
}

export default Sidebar;