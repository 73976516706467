import { useState } from "react";
import { adjustWidth } from "./Utils/commonFunctions";
import Header from "./Components/Header/Header";
import Sidebar from "./Components/Sidebar/Sidebar";
import { Outlet } from "react-router-dom";

const Layout = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth <= 991 ? false : true);
    
    const clickHandler = () => {
        const flag = adjustWidth(isSidebarOpen);
        setIsSidebarOpen(flag);
    }
    useState(() => {
     
    })
    return (
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper">
                <Header clickHandler={clickHandler} />
                <Sidebar isSidebarOpen = {isSidebarOpen}/>
                <Outlet/>
            </div>
        </div>
    );
}

export default Layout;