import { useFormik } from "formik";
import { irrigationSources } from "./dropdownOptions";
import styles from './../AddFeildInformation.module.css';
import { getTodaysDate } from "../../../../Utils/commonFunctions";
import WarningMessage from "../../../../Components/WarningMessage/WarningMessage";

const validate = (values) => {
    const errors = {};
    if(values.type === ''){
        errors.type = "Type is required"
    }
    if(values.type === 'other' && values.otherType === ""){
        errors.otherType = "Type is required"
    }
    if(values.source === ''){
        errors.source = "Source is required"
    }
    if(values.date === ''){
        errors.date = "Date is required"
    }
    return errors;
}

const IrrigationForm = ({change, irrigations}) => {
    const todaysDate = getTodaysDate();
    const formik = useFormik({
        initialValues : {
            type : '',
            otherType : '',
            source : '',
            date : ''
        },
        validate,
        onSubmit : (values) => {
            change('irrigations', values);
            formik.resetForm({});
        }
    })
    return (
        <div>
            <form>
                <div className="row">
                    <div className="col-md-3">
                        <label className="col-form-label">Type Of Irrigation</label>   
                        <select 
                        className={`form-control ${ formik.submitCount>0 && formik.errors.type ? styles.formInputError : ""}`} 
                        id='type' placeholder="Type Of Irrigation" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.type}>
                            <option value=''>Select</option>
                            {
                                irrigations.map((type, index) => <option key={index} value={type.name}>{type.name}</option>)
                            }
                            <option value='other'>Other</option>
                        </select>
                        {formik.submitCount>0 && formik.errors.type ? <div className={styles.error}>{formik.errors.type}</div> : ""}
                    </div>

                    {
                        formik.values.type === 'other' ? 
                        <div className="col-md-3">
                            <label className="col-form-label">Mention type of Irrigation</label>   
                            <input type="text" 
                             className={`form-control ${ formik.submitCount>0 && formik.errors.otherType ? styles.formInputError : ""}`} 
                            id='otherType' placeholder="Irrigation" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.otherType}/>    
                            {formik.submitCount>0 && formik.errors.otherType ? <div className={styles.error}>{formik.errors.otherType}</div> : ""}  
                        </div> : ''
                    }

                    <div className="col-md-3">
                        <label className="col-form-label">Source Of Irrigation</label>   
                        <select 
                        className={`form-control ${ formik.submitCount>0 && formik.errors.source ? styles.formInputError : ""}`} 
                        id='source' placeholder="Source Of Irrigation" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.source}>
                            <option value=''>Select</option>
                            {
                                irrigationSources.map((source, index) => <option key={index} value={source}>{source}</option>)
                            }
                        </select>
                        {formik.submitCount>0 && formik.errors.source ? <div className={styles.error}>{formik.errors.source}</div> : ""}
                    </div>
                    <div className="col-md-3">
                        <label className="col-form-label">Date of Application</label>   
                        <input max={todaysDate}
                        className={`form-control ${ formik.submitCount>0 && formik.errors.date ? styles.formInputError : ""}`} 
                        type="date" id='date' placeholder="Date" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.date}/>
                        {formik.submitCount>0 && formik.errors.date ? <div className={styles.error}>{formik.errors.date}</div> : ""}
                    </div>
                    
                </div>
                <div className="row col-md-4">
                        <button className="btn btn-secondary mt-2" type="button" onClick={formik.handleSubmit}>Add</button>
                </div>
                <WarningMessage/>
            </form>
        </div>
    ); 
}

export default IrrigationForm;