import { Map, TileLayer, FeatureGroup, MapContainer, LayersControl, Polygon } from 'react-leaflet';

import { EditControl } from 'react-leaflet-draw';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { useEffect, useState } from 'react';
import 'leaflet-geodesy';
import { calculateAreaAndPerimeter, convertCoords, polygonsToKML } from '../../../Utils/commonFunctions';
import mapicon from '../../../assets/img/mapicon2.svg';
import { addPolygon } from '../../../APIs/apiCalls';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/styles.css";
import Location from './Location';


const Map_ = ({farmer, location}) => {
   
    const [disabled, setDisabled] = useState(false);
    const [polygon, setPolygon] = useState([]);
    const [drawnPolygons, setDrawnPolygons] = useState([])

    const customIcon = new L.Icon({
        // https://i.pinimg.com/564x/a9/84/aa/a984aa49d768a03df577acd8094d6b63.jpg
        iconUrl: mapicon, // Replace with t/he path to your custom icon image
        iconSize: [52, 52], // Set the size of the icon
        iconAnchor: [16, 32], // Set the anchor point for the icon
    });

    const _onCreated = (event) => {
        // console.log(event);
        const { layerType, layer } = event;
        
            if (layerType === 'polygon') {

                setDrawnPolygons((prevData) => [...prevData, layer]);//for KML Download

                const points = layer.toGeoJSON().geometry.coordinates;
                
                let {area, perimeter} = calculateAreaAndPerimeter(layer)
                
                const convertedPoints = convertCoords(points[0]);  //convert an array of [longitude, latitude] pairs to [latitude, longitude]
                setPolygon((prevData) => [...prevData,{ points :  convertedPoints, area : area, perimeter : perimeter}]);

                layer.bindPopup(
                    `NAME : ${(farmer.firstname + ' ' + farmer.lastname).toUpperCase()} <br/> FATHER'S NAME : ${farmer.guardian.toUpperCase()}   <br/> BLOCK : ${farmer.block.toUpperCase()} <br/> TEHSIL : ${farmer.tehsil.toUpperCase()} <br/> VILLAGE : ${farmer.village.toUpperCase()} <br/> AREA: ${area.toFixed(2)} square meters<br />PERIMETER: ${(perimeter*1000).toFixed(2)} meters`
                );
            }
        
        
    }

    const onEdited = (event) => {
        console.log('====================================');
        console.log(event);
        console.log('====================================');
    }

    const handleSaveFile = () => {
       
        if(polygon.length === 0){
            NotificationManager.error('Please select the points to save the polygon.')
            return;
        }
        const totalArea = polygon.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.area;
        }, 0);

        const totalPerimeter = polygon.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.perimeter;
        }, 0);

        const obj = {};
        obj['points'] = polygon.map((item) => item.points);
        obj['polygon'] = polygon;
        obj['farmerId'] = farmer._id;
        obj['farmername'] = farmer.firstname + ' ' + farmer.lastname;
        obj['tehsil'] = farmer.tehsil;
        obj['block'] = farmer.block;
        obj['district'] = farmer.district;
        obj['village'] = farmer.village;
        obj['area'] = totalArea;
        obj['mode'] = 'map';
        obj['type'] = 'draw-kml';
        obj['perimeter'] = totalPerimeter;
        
      
        setDisabled(true);
        addPolygon(obj).then(async (response) => {
            setDisabled(false)
           
            if(response.result === 'success') {
                
                NotificationManager.success("Your Map has been saved to the list");
            }
            else if(response.error){
                NotificationManager.error(response.error)
            }
        })
    }

    const handleKMLDownload = () => {
        const kmlData = polygonsToKML(drawnPolygons, farmer);
        const blob = new Blob([kmlData], { type: 'application/vnd.google-earth.kml+xml' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = url;
        a.download = `${farmer.firstname}_${farmer.lastname}_${farmer.village}_${farmer.block}_${farmer.tehsil}_${farmer.district}.kml`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };
    const handleDelete = (event) => {
       
        const {layers} = event;
        

        layers.eachLayer((layer) => {
            let {area} = calculateAreaAndPerimeter(layer);
            
            setPolygon((prevData) => prevData.filter((item) => item.area!=area));
            setDrawnPolygons((prevData) => prevData.filter((item) => item!=layer));
        })
    }
    useEffect(() => {
        // //Defining the Geolocation framework
        // L.control.locate().addTo(map);
    }, []);

    if(farmer===undefined){
        return <>No value is selected from the feilds</>
    }
    if(typeof location === 'undefined'){
        return <>Loading...</>
    }
    return (
        <div className='map-container'>
            <h4>Polygon Maps</h4>
            <p className='text-warning'><u><i className='typcn typcn-warning'></i>Read the Steps to draw polygon and save : </u> </p>
            <ol>
                <li>Zoom your map to the place where the polygon supposed to be drawn.</li>
                <li>Click on the polygon ⬟ icon.</li>
                <li>Pick a start point and then start drawing the polygon.</li>
                <li>When you are done, then bring the point to the start point to complete the shape.</li>
                <li>Click on save button once you are sure with it.</li>
            </ol>
            <p className='text-warning'><u><i className='typcn typcn-warning'></i>Points to remember : </u> </p>
            <ol>
                <li>When you are deleting<i className='typcn typcn-trash'></i> the polygon, then do remember to click on the save button, otherwise changes will not be saved.</li>
                <li>Currently, Edit option is not working, our team is working on it.</li>
            </ol>
            {
                location!=null && farmer!={}  ? 
                <>
                <MapContainer center={[54.989, 73.369]} 
                zoom={13}
                style={{ width: '100%', height: '500px', zIndex : 50 }}
                scrollWheelZoom={false}
                maxZoom={18}
                >
                  <div>
                    <LayersControl position="topright">
                        <LayersControl.BaseLayer name="Street Map" >
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <FullscreenControl />
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name="Satellite Map" checked>
                            <TileLayer 
                                attribution='&copy; <a href="http://mapbox.com/copyright">Mapbox</a>'
                                url='http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}'
                                subdomains={['mt1','mt2','mt3']}
                            />
                        </LayersControl.BaseLayer>
                    </LayersControl>
                    
                    
                    {/* {location && (
                        <Marker position={location} icon={customIcon}>
                            <Popup>Your current location</Popup>
                        </Marker>
                    )} */}
                    <Location/>
                    <FeatureGroup>
                       
                        <EditControl
                            position="topright"
                            onCreated={(event) => _onCreated(event)}
                            onEdited={(event) => onEdited(event)}
                            onDeleted={(event) => handleDelete(event)}
                            draw={{
                                polyline : false,
                                rectangle: false,
                                circle: false,
                                circlemarker: false,
                                marker : false
                            }}
                            
                        />
                    </FeatureGroup>
                    </div>  
                </MapContainer>
                <br/> 
                    <button className='btn btn-sm btn-secondary' onClick={handleSaveFile} disabled={disabled}>Save</button>  &nbsp;
                    <button className='btn btn-sm btn-primary' onClick={handleKMLDownload}>Download KML File</button>
                </>
                : ''
            }
            <NotificationContainer/>
        </div>
    );
}


export default Map_;