import { useEffect, useState } from "react";
import { getMimeTypeFromDataUri } from "../../Utils/commonFunctions";
import { getMimeTypeFromUrl } from "../../APIs/apiCalls";


const DisplayFile = ({uri, mimetype}) => {
   
    useEffect(() => {
    },[])
    return (
        <div className="mt-2">
        <p>Preview File : {mimetype}</p>
        {
            mimetype === 'application/pdf' ? 
            <iframe src={uri} title="PDF Viewer" width="100%" height="200px"></iframe> :  
            <img src={uri} width={260}/> 
        }
        </div>
    );
}
export default DisplayFile;