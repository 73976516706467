import { Button } from "@mui/material/node";
import MaterialReactTable from "material-react-table";
import {  useEffect, useState } from "react";
import { CreateNewAccountModal } from "../../CreateNewLocation/CreateNewAccountModal";
import { addFeildData, addFeildTeam, addSingleLocation, deleteFarmer, deleteFeildTeamById, deleteLocationById, getFeildTeamRecords, getLocation, updateFeildTeamRecord, updateLocationRecord } from "../../../../APIs/apiCalls";
import { NotificationContainer, NotificationManager } from "react-notifications";
import AlertConfirm from "react-alert-confirm";
import { useNavigate } from "react-router-dom";
import Spinners1 from "../../../../Components/Spinners/Spinners1";

const ManageLocationsTable = () => {
    const [data, setData] = useState([]);
    const [createModalOpen,setCreateModalOpen] = useState(false);
    const [feildTeamId, setFeildTeamId] = useState("add");
   
    const [disabled, setDisabled] = useState(false);
    const columns = [
      
      {
        accessorKey : 'state',
        header: 'State',
        // accessorFn: (data) => {
         
        //   if(data){
        //       return data.firstname + ' ' + data.lastname;
        //   }
        // }
        accessorFn: (data) => {
          if(data){
              return data.state.toUpperCase();
          }
        },
      },
      {
        accessorKey: 'district',
        header: 'District',
        accessorFn: (data) => {
          if(data){
              return data.district.toUpperCase();
          }
        },
      },
      {
        accessorKey: 'block',
        header: 'Block',
        accessorFn: (data) => {
          if(data){
              return data.block.toUpperCase();
          }
        },
      },
      {
        accessorKey: 'tehsil',
        header: 'Tehsil',
        accessorFn: (data) => {
          if(data){
              return data.tehsil.toUpperCase();
          }
        },
      },
      {
        accessorKey: 'village',
        header: 'Village',
        accessorFn: (data) => {
          if(data){
              return data.village.toUpperCase();
          }
        },
      },
    ];
      const handleCreateNewRow = (values) => {
        setDisabled(true);
        if(feildTeamId=="add"){
          //add
          addSingleLocation(values).then((response) => {
  
            if(response.result === 'success'){
              NotificationManager.success('Location has been added Successfully');
            }else{
              NotificationManager.error(response.result);
            }
            setDisabled(false);

            localStorage.setItem('locations', JSON.stringify(response));
            setData(response.data);
        
          }).catch((error) => {
            setDisabled(false);
            NotificationManager.error('Internal Server Error');
          })
        }else{
          //update
          updateLocationRecord(feildTeamId, values).then((response) => {
  
            if(response.result === 'success'){
              NotificationManager.success('Feild Record has been updated Successfully');
            }else{
              NotificationManager.error(response.result);
            }
            setDisabled(false);
            localStorage.setItem('location', response.data);
            setData(response.data);
          }).catch((error) => {
            setDisabled(false);
            NotificationManager.error('Internal Server Error');
          })
        }
       
      }
      const handleSaveRowEdits = (row) => {
        setCreateModalOpen(true);
        setFeildTeamId(row.original._id);
      }

      const handleCancelRowEdits = (row) => {
        AlertConfirm({
            title: 'Are you sure?',
            desc: 'This cannot be undone.',
            onOk: () => {
              deleteLocationById(row.original._id).then((response)=>{
                
                if(response.result === "success"){
                    NotificationManager.success("Farmer has been removed from the list");
                    setData(response.data);
                }
              })
            },
            onCancel: () => {
             
            }
        });
      }

      
      useEffect(() => {
        getLocation().then((response) => {
          setData(response.data);
        })
      }, [])

      if(data.length===0){
        return <Spinners1/>
      }

    return (
        <div>
            <MaterialReactTable
            columns={columns}
            data={data}
            editingMode="modal"
            muiTableProps={{
                sx: {
                border: '1px solid rgba(81, 81, 81, 1)',
                },
            }}
            muiTableHeadCellProps={{
                sx: {
                border: '1px solid rgba(81, 81, 81, 1)',
                },
            }}
            muiTableBodyCellProps={{
                sx: {
                border: '1px solid rgba(81, 81, 81, 1)',
                },
            }}
            renderTopToolbarCustomActions={() => (
                
              <button className="btn btn-bluish-green" onClick={() => { setCreateModalOpen(true); setFeildTeamId("add") }}>
                New Location
              </button>
            )}
            enableEditing={true}
            onEditingRowSave={handleSaveRowEdits}
            onEditingRowCancel={handleCancelRowEdits}
            muiTablePaginationProps={{
              showFirstButton: true,
              showLastButton: true,  
            }}
            renderRowActions={({ row, table }) => (
              <div style={{ display: 'flex'}}>
                 <button type="button" className="btn btn-sm btn-success btn-icon-text mr-1" style={{width : '100px'}} onClick={()=>handleSaveRowEdits(row)}>Edit<i className="typcn typcn-edit btn-icon-append"></i>                                                                              
                 </button>
                 <button type="button" className="btn btn-sm btn-danger btn-icon-text" onClick={()=> handleCancelRowEdits(row)}>
                Delete<i className="typcn typcn-trash btn-icon-append"></i>                                                                              
                  </button>
              </div>
            )}
            />
            <CreateNewAccountModal
                columns={columns}
                open={createModalOpen}
                id = {feildTeamId}
                onClose={() => setCreateModalOpen(false)}
                onsubmit={handleCreateNewRow}
                data={data}
                disabled={disabled}
            />
            <NotificationContainer/>
        </div>
    );
}

export default ManageLocationsTable;