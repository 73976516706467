import ClipLoader from 'react-spinners/ClipLoader';


const CompressionLoader = () => {
    return (
        <p className='text-info'>We are compressing the image. Please be patient. <ClipLoader
                loading={true}
                size={14}
                aria-label="Loading Spinner"
                data-testid="loader"
            /> <ClipLoader
                loading={true}
                size={14}
                aria-label="Loading Spinner"
                data-testid="loader"
            /> <ClipLoader
                loading={true}
                size={14}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </p>
    );
}

export default CompressionLoader;