import { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
const Spinners = () => {
    
    const [loading, setLoading] = useState(true);
    const [color, setColor] = useState("#173f48");
    const override = {
        display: "block",
        margin: "0 auto",
        alignItems : "center",
        height : '65px',
        width : '65px',
    };
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="row" style={{paddingTop : '80px'}}>
                    <div className="col-12 grid-margin"> 
                        <div className="card">
                            <div className="card-body1">
                                <div className="d-flex">
                                    <ClipLoader
                                        color={color}
                                        loading={loading}
                                        cssOverride={override}
                                        size={150}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Spinners;