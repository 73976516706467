import { Button,  Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useFormik } from 'formik';
import styles from './../../FeildTeam/ManageMembers/ManageMembers.module.css';
import { useEffect, useState } from 'react';
import validate from './validate';
import { getFeildTeamById, getLocationById, updateFeildTeamRecord } from '../../../APIs/apiCalls';
import ClipLoader from 'react-spinners/ClipLoader';
export const CreateNewAccountModal = ({ open, onClose, onsubmit, id, data, disabled }) => {  
    
    const formik = useFormik({
      initialValues : {
        state : '',
        district : '',
        tehsil : '',
        block : '',
        village : '',
      },
      validate : validate,
      onSubmit : (values) => {
        formik.setFieldValue('village', values.village.toLowerCase());
        onsubmit(values);
      }
    });
    useEffect(() => {
      formik.resetForm({});
      if(id!="add"){
        getLocationById(id).then((response) => formik.setValues(response.data) )
      }else{
        formik.resetForm({});
      }

    },[open])
  
    return (
      <Dialog open={open}>
        <DialogTitle textAlign="center">Add Location</DialogTitle>
        
        <DialogContent>
          {
            id==='add' || formik.values.state ? 
              <form onSubmit={formik.handleSubmit}>
                <div className='row'>
                <Stack
                sx={{
                  width: '100%',
                  minWidth: { xs: '280px', sm: '360px', md: '400px' },
                  gap: '1.5rem',
                }}
              >
                  <div className='col-md-12'>
                      <div className='form-group'>
                        <select className={`form-control ${  formik.touched.state === true && formik.errors.state ? styles.formInputError : ""}`}
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id='state'
                        >
                            <option value=''>
                                Select State
                            </option>
                            {
                                Array.from(new Set(data.map(item => item.state))).sort().map((item, index) => <option value={item.toLowerCase()} key={index}>{item.toUpperCase()}</option>)
                            }
                        </select>
                        {formik.touched.state === true && formik.errors.state ? <div className={styles.error}>{formik.errors.state}</div> : ""}
                      </div>
                      <div className='form-group'>
                        <select className={`form-control ${ formik.touched.district === true && formik.errors.district ? styles.formInputError : ""}`}
                        value={formik.values.district}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id='district'
                        >
                              <option value=''>
                                  Select District
                              </option>
                              {
                                  Array.from(new Set(data.filter(item => item.state === formik.values.state).map(item => item.district))).sort().map((item,index) => <option value={item.toLowerCase()} key={index}>{item.toUpperCase()}</option>)
                              }
                        </select>
                        {formik.touched.district === true && formik.errors.district ? <div className={styles.error}>{formik.errors.district}</div> : ""} 
                      </div>
                      <div className='form-group'>
                          <select className={`form-control ${ formik.touched.tehsil === true && formik.errors.tehsil ? styles.formInputError : ""}`}
                            value={formik.values.tehsil}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id='tehsil'
                            >
                                  <option value=''>
                                      Select Tehsil
                                  </option>
                                  {
                                      Array.from(new Set(data.filter(item => item.district === formik.values.district).map(item => item.tehsil))).sort().map((item,index) => <option value={item.toLowerCase()} key={index}>{item.toUpperCase()}</option>)
                                  }
                            </select>
                            {formik.touched.tehsil === true && formik.errors.tehsil ? <div className={styles.error}>{formik.errors.tehsil}</div> : ""}
                      </div>
                      <div className='form-group'>
                          <select className={`form-control ${ formik.touched.block === true && formik.errors.block ? styles.formInputError : ""}`}
                            value={formik.values.block}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id='block'
                          >
                                  <option value=''>
                                      Select Block
                                  </option>
                                  {
                                      Array.from(new Set(data.filter(item => item.tehsil === formik.values.tehsil).map(item => item.block))).sort().map((item,index) => <option value={item.toLowerCase()} key={index}>{item.toUpperCase()}</option>)
                                  }
                            </select>
                         
                          {formik.touched.block === true && formik.errors.block ? <div className={styles.error}>{formik.errors.block}</div> : ""}
                      </div>
                      <div className='form-group'>
                          <input className={`form-control ${ formik.touched.village === true && formik.errors.village ? styles.formInputError : ""}`}
                          value={formik.values.village}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          id='village' placeholder='Enter village'/>
                          {formik.touched.village === true && formik.errors.village ? <div className={styles.error}>{formik.errors.village}</div> : ""}
                      </div>
                  </div>
                </Stack>
              </div>
              <DialogActions sx={{ p: '1.25rem' }}>
                  <Button onClick={onClose}>
                    Cancel
                  </Button>
                  <Button  color="success" variant="contained" type='submit' disabled={disabled}>
                      {disabled === true ? <span>Saving... <ClipLoader color='grey' size={14}/></span> : <span>Save</span>}
                  </Button>
              </DialogActions>
          </form> : 'Loading...'
          }
       
        </DialogContent>
        
      </Dialog>
    );
  };
  