import { IconButton } from '@mui/material/node';
import { MaterialReactTable} from 'material-react-table';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

const Table = ({data, change, delete_, heading, collection}) => {
   
    
    const columns = [
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            // Add a column for the delete button
            accessorKey: 'delete',
            header: 'Delete',
            enableSorting: false, 
            enableEditing : false,
            Cell: ({ row }) => (
                <IconButton color="error" onClick={() => handleDeletion(row)}>
                    <DeleteIcon />
                </IconButton>
            ),
        }, // end
    ]
    const handleDeletion = (row) => {
        const id = row.original._id;
        delete_(id);
    }
    const handleSaveRow = async ({ exitEditingMode, row, values }) => {
        
        //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here.
        // console.log(row.original._id);
        change(row.original._id ,values, collection);

        exitEditingMode(); //required to exit editing mode
      };
    
      return (
        <>
        <h4>{heading}</h4>
        <MaterialReactTable
          columns={columns}
          data={data}
          editingMode="modal" //default
          enableEditing
          onEditingRowSave={handleSaveRow}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
            initialState={{ density: 'compact' }}
            enableFilters = {false}
            enableHiding={false}
        />
        </>
      );
}

export default Table;