import { Button } from "@mui/material/node";
import MaterialReactTable from "material-react-table";
import {  useEffect, useState } from "react";
import { CreateNewAccountModal } from "../../CreateNewAccountModal/CreateNewAccountModal";
import { addFeildData, addFeildTeam, deleteFarmer, deleteFeildTeamById, getFeildTeamRecords, updateFeildTeamRecord } from "../../../../APIs/apiCalls";
import { NotificationContainer, NotificationManager } from "react-notifications";
import AlertConfirm from "react-alert-confirm";
import { useNavigate } from "react-router-dom";
import Spinners1 from "../../../../Components/Spinners/Spinners1";
import moment from "moment";

const ManageMembersTable = () => {
    const [data, setData] = useState(undefined);
    const [createModalOpen,setCreateModalOpen] = useState(false);
    const [feildTeamId, setFeildTeamId] = useState("add");
   
    const columns = [
      
      {
        id : 'name',
        header: 'Name',
        accessorFn: (data) => {
         
          if(data){
              return (data.firstname + ' ' + data.lastname).toUpperCase();
          }
        }
      },
      {
        accessorKey: 'email',
        header: 'Email'
      },
      {
        accessorKey: 'dob',
        header: 'Date Of Birth',
        Cell: ({cell}) => {
         return <>{moment(cell.getValue()).format('DD-MM-YYYY')}</>
        }
      },
      {
        accessorKey: 'type',
        header: 'Role',
        accessorFn: (data) => {
          if(data){
              return data.type.toUpperCase();
          }
        },
        filterVariant : 'select',
        filterSelectOptions : ['editor', 'viewer', 'admin']
      },
      {
        header: 'Locations',
        accessorFn: (data) => {
          if(data){
              return data.locations?.map((item, index) => <p>{index+1}. {item.village.label.toUpperCase()}/ {item.tehsil.toUpperCase()}/ {item.block.toUpperCase()}/ {item.district.toUpperCase()}/ {item.state.toUpperCase()}</p>)
          }
        }
      },
    ];
      const handleCreateNewRow = (values) => {

        if(feildTeamId=="add"){
          //add
          addFeildTeam(values).then((response) => {
  
            if(response.result === 'success'){
              NotificationManager.success('Field Record has been added Successfully');
            }else{
              NotificationManager.error(response.result);
            }
  
            setData(response.data);
          })
        }else{
          //update
          updateFeildTeamRecord(feildTeamId, values).then((response) => {
  
            if(response.result === 'success'){
              NotificationManager.success('Field Record has been updated Successfully');
            }else{
              NotificationManager.error(response.result);
            }
  
            setData(response.data);
          })
        }
       
      }
      const handleSaveRowEdits = (row) => {
        setCreateModalOpen(true);
        setFeildTeamId(row.original._id);
      }

      const handleCancelRowEdits = (row) => {
        AlertConfirm({
            title: 'Are you sure?',
            desc: 'This cannot be undone.',
            onOk: () => {
              deleteFeildTeamById(row.original._id).then((response)=>{
                // console.log(response);
                if(response.result === "success"){
                    NotificationManager.success("Farmer has been removed from the list");
                    setData(response.data);
                }
              })
            },
            onCancel: () => {
              // console.log('cancel');
            }
        });
      }

      useEffect(() => {
        getFeildTeamRecords().then((response) => setData(response.data));
        
      }, [])
      if(data===undefined){
        return <Spinners1/>
      }
    return (
        <div>
            <MaterialReactTable
              columns={columns}
              data={data}
              editingMode="modal"
              muiTableProps={{
                  sx: {
                  border: '1px solid rgba(81, 81, 81, 1)',
                  },
              }}
              muiTableHeadCellProps={{
                  sx: {
                  border: '1px solid rgba(81, 81, 81, 1)',
                  },
              }}
              muiTableBodyCellProps={{
                  sx: {
                  border: '1px solid rgba(81, 81, 81, 1)',
                  },
              }}
              renderTopToolbarCustomActions={() => (
                  
                <button className="btn btn-bluish-green" onClick={() => { setCreateModalOpen(true); setFeildTeamId("add") }}>
                  New Account
                </button>
              )}
              enableEditing={true}
              onEditingRowSave={handleSaveRowEdits}
              onEditingRowCancel={handleCancelRowEdits}
              muiTablePaginationProps={{
                showFirstButton: true,
                showLastButton: true,  
              }}
              renderRowActions={({ row, table }) => (
                <div style={{ display: 'flex'}}>
                  <button type="button" className="btn btn-sm btn-success btn-icon-text mr-2" style={{ width : '100px'}} onClick={()=>handleSaveRowEdits(row)}>
                    Edit <i className="typcn typcn-edit btn-icon-append"></i>                                                                              
                  </button>
                  <button type="button" className="btn btn-sm btn-danger btn-icon-text" style={{width : '100px'}} onClick={()=> handleCancelRowEdits(row)}>
                    Delete <i className="typcn typcn-trash btn-icon-append"></i>                                                                              
                    </button>
                </div>
              )}
              
            />
            
            <CreateNewAccountModal
                columns={columns}
                open={createModalOpen}
                id = {feildTeamId}
                onClose={() => setCreateModalOpen(false)}
                onsubmit={handleCreateNewRow}
            />
            <NotificationContainer/>
        </div>
    );
}

export default ManageMembersTable;