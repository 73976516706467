import { useEffect, useState } from "react";
import {  getTableData } from "../../APIs/apiCalls";
import styles from './FarmerSearchBar.module.css';
import ClipLoader from "react-spinners/ClipLoader";
import { fetchUrl } from "../../APIs/server";
const url = new URL(`${fetchUrl}/search-farmer`);
url.searchParams.set('page', 1);
url.searchParams.set('limit', 20);


const FarmerSearchBar = ({change, farmerValue, isAWDFarmer}) => {
    const [inputValue, setInputValue] = useState('');
    const [display, setDisplay] = useState('none');
    const [suggestions, setSuggestions] = useState(undefined);
    const [loading, setLoading] = useState(true);

    const fetchSuggestions = async (value) => {
        let [farmername, guardian, village] = value.split('-');
        
        farmername = farmername.toLowerCase().trim();
        guardian = guardian?.toLowerCase().trim();
        village = village?.toLowerCase().trim();
        
        setInputValue(value);
        setLoading(true);

        farmername!='' ? url.searchParams.set('farmername', farmername) : url.searchParams.delete('farmername');
        guardian!=undefined ? url.searchParams.set('guardian', guardian) : url.searchParams.delete('guardian');
        village!=undefined ? url.searchParams.set('village', village) : url.searchParams.delete('village');
        
        const response = await getTableData(url.href);
        setSuggestions(response.data);
        setLoading(false);
      
    }

    const selectHandler = (farmer) => {
        const val = `${farmer.farmername} - ${farmer.guardian} - ${farmer.village}`;
        setInputValue(val);
        setDisplay('none');
        change(farmer);
    }

    useEffect(() => {
        
        getTableData(url.href).then((response) => {
            setSuggestions(response.data);
            setLoading(false)
        })
    },[farmerValue]);
    if(suggestions === undefined) {
        return <div>
            Loading <ClipLoader
                loading={true}
                size={14}
                aria-label="Loading Spinner"
                data-testid="loader"
            /> <ClipLoader
                loading={true}
                size={14}
                aria-label="Loading Spinner"
                data-testid="loader"
            /> <ClipLoader
                loading={true}
                size={14}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>
    }

    
   
    return (
        <div className="row">
            <div className={`ml-2 ${styles.warningMessage}`}>
                <i className='typcn typcn-warning'></i> Please use this real time search bar to select the farmers.
            </div>
            <div className="col-md-12">
                <input className="form-control text-uppercase" value={inputValue} placeholder="search farmer by name" onFocus={() => setDisplay('block')} onChange={(event) => fetchSuggestions(event.target.value)}/>
            </div>
            
            {
                suggestions.length>0?
                <>
                {
                    
                    <ul className="list-group mt-2 ml-2 mr-3 col-md-12 p-2" style={{display, maxHeight : '200px', overflowY : 'scroll', borderRadius : '0px', boxShadow :'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'}} >
                    {
                        loading === true ? 
                        <li>Loading <ClipLoader
                            loading={true}
                            size={10}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        /> <ClipLoader
                            loading={true}
                            size={10}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        /></li> : 
                        <>
                        {
                            suggestions.map((suggestion, index) => (
                                <li className="text-uppercase farmer-list p-2" key={index} onClick={() => selectHandler(suggestion)}><b>{suggestion.farmername} - {suggestion.guardian} : </b>  <u>{suggestion.village}/ {suggestion.block}/ {suggestion.tehsil} / {suggestion.district}/ {suggestion.state}</u></li>
                            ))
                        }
                        </>
                    }
                    </ul>
                }
                
                <button style={{display}} type="button" className="mt-1 ml-2 btn btn-sm btn-secondary" onClick={() => setDisplay('none')}>close</button>
                </> : ''
            }
        </div>
    );
}       
export default FarmerSearchBar;