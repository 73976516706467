import { useEffect, useState } from "react";
import { Circle, Marker, Popup, useMap } from "react-leaflet";
import mapicon from '../../../assets/img/mapicon2.svg';
import L, { point } from 'leaflet';

const customIcon = new L.Icon({
    // https://i.pinimg.com/564x/a9/84/aa/a984aa49d768a03df577acd8094d6b63.jpg
    iconUrl: mapicon, // Replace with t/he path to your custom icon image
    iconSize: [32, 32], // Set the size of the icon
    iconAnchor: [16, 32], // Set the anchor point for the icon
});

const Location = () => {
    const map = useMap();
    const [position, setPosition] = useState(null);
    const [accuracy, setAccuracy] = useState(15);
  
    useEffect(() => {
      map.locate({
        setView: true
      })
      map.on('locationfound', (event) => {
        console.log(event);
        setAccuracy(event.accuracy)
        setPosition(event.latlng)
      })
    }, [map])
  
  
    return position
      ? (
        <>
          <Circle center={position} weight={2} color={'red'} fillColor={'red'} fillOpacity={0.1} radius={accuracy}></Circle>
          <Marker position={position}  icon={customIcon}>
            <Popup>You are here</Popup>
          </Marker>
        </>
      )
      : null
  }

  export default Location;