import ClipLoader from 'react-spinners/ClipLoader';

const SubmitButton = ({disabled}) => {
    return (
        <button className="btn btn-bluish-green" type="submit" disabled={disabled}>
        {
            disabled === true ? 
            <span>Saving.. &nbsp;<ClipLoader
                loading={true}
                size={14}
                aria-label="Loading Spinner"
                data-testid="loader"
            /></span> : 'SAVE'
        }
        </button>
    );
}
export default SubmitButton;