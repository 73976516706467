import { useEffect, useState } from "react";
import FarmerRegistration from "./FarmerRegistration/FarmerRegistration";
import 'react-notifications/lib/notifications.css';
import Layout from "../../../Layout";
import { useNavigate } from "react-router-dom";




const AddOnboardingInformation = () => {
    // const [finishStatus, setFinishStatus] = useState(false);
    // const navigate = useNavigate();

    const alertUser = (event) => {
        event.preventDefault();
        event.returnValue = "Are you sure you want to leave? Changes you made may not be saved."; // Custom message may not work in all browsers
    };

    // const onBackButtonEvent = (event) => {
    //     event.preventDefault();
    //     if (!finishStatus) {
    //         if (window.confirm("Do you want to go back to the dashboard?")) {
    //             setFinishStatus(true); // Confirm navigation
    //             navigate("/dashboard");
    //         } else {
    //             window.history.pushState(null, null, window.location.hash);
    //             setFinishStatus(false); // Stay on the page
    //         }
    //     }
    // };

     

    useEffect(() => {
        // window.history.pushState(null, null, window.location.hash);
        // window.addEventListener('popstate', onBackButtonEvent);
        window.addEventListener('beforeunload', alertUser);

        // return () => {
        //     window.removeEventListener('popstate', onBackButtonEvent);
        //     window.removeEventListener('beforeunload', alertUser);
        // };
    }, []);

  

    return (
      
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row" style={{paddingTop : '80px'}}>
                            <div className="col-12 grid-margin">
                                <div className="card">
                                    <div className="card-body">
                                        <FarmerRegistration />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
    );
}

export default AddOnboardingInformation;