import { useEffect, useState } from "react";
import {
  CircularProgressbar,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
// This component shows the circular progress bar of AWD Farmers covered.
const PieChart = ({data}) => {
    const awdFarmerNumbers = data.awdFarmers;
    const totalFarmers = data.farmerCount;
    const nonAwdFarmers = totalFarmers - awdFarmerNumbers; 
    
    // console.log(farmers)
    useEffect(() => {
    },[]);
    if(data === undefined){
        return <>Loading..</>
    }
    return (
        <div >
            <div className='dashboard-heading'>
                  <h5 className="m-0 text-white">AWD Farmers Coverage</h5>
            </div>
            <p className=" pt-3 pt-3 text-center mb-0" style={{color : "grey", fontSize : '14px', fontWeight : 'bolder', backgroundColor : 'ghostwhite'}}>Progress Bar Shows the AWD Farmers covered percentage</p>
            <div className="dashboard-chart">
            <CircularProgressbar
              value={(awdFarmerNumbers*100)/totalFarmers}
              text={`${((awdFarmerNumbers*100)/totalFarmers).toFixed(0)}%`}
              className="mt-3"
              background
              backgroundPadding={7}
              strokeWidth={10}
            
              styles={buildStyles({
                backgroundColor: "#d18a49",
                textColor: "#fff",
                pathColor: "#fff",
                trailColor: "transparent"
               
              })}
            />
            </div>
            {/* <PercentStats /> */}
          </div>
    );
}

export default PieChart;