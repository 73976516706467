import { useEffect, useState } from "react";
import styles from './Profile.module.css';
import { season } from "../FeildInformation/AddFeildInformation/FeildForm/dropdownOptions";
import moment from "moment";
const currentYear = new Date().getFullYear();
const yearsArray = Array.from({ length: currentYear - 2019 }, (_, index) => 2020 + index);

const EmptyRecord = () => {
    return (
        <div>
            <span className="text-danger">No Records to show</span>
        </div>
    );
}
const NoneRecord = () => {
    return (
       
            <span className="text-danger">Not Entered</span>
       
    );
}

const CropDetails = ({data}) => {
    console.log(data);
    const [year, setYear] = useState(undefined)
    const [season1, setSeason1] = useState(undefined)
  
    const [crops, setCrops] = useState([]);
    const seasonChangeHandler = (event) => {
        let {value} = event.target;
       
        setSeason1(value);

        if(year){
            let arr = data.filter((item) => item.year === year && item.season === value);
            
            setCrops(arr);
        }else{
            let arr = data.filter((item) => item.season === value);
           
            setCrops(arr);
        }
    }
    const yearChangeHandler = (event) =>{
        let {value} = event.target;
        console.log(value);
        value = parseInt(value)
        setYear(value);
        if(season1){
            
            let arr = data.filter((item) => item.year === value && item.season === season1);
            
            setCrops(arr);

        }
        
        else{
            let arr = data.filter((item) => item.year === value);
            console.log(arr);
            setCrops(arr);
        }
    }
    useEffect(() => {
    
    },[]);
    return(
        <div className="card p-3" style={{fontStyle : 'italic'}}>
            <span className="row text-small "><i className="typcn typcn-warning"></i>Please filter the values to see the crop details.</span>
            <div className="row">
                <select className="col-md-6 form-control" onChange={(event) => yearChangeHandler(event)}>
                    <option value=''>Select Year</option>
                    {   
                        yearsArray.map((item, index) => <option key={index} value={item}>{item}</option>)
                    }
                </select>
                <select className="col-md-6 form-control" onChange={(event) => seasonChangeHandler(event)}>
                    <option value=''>Select season</option>
                    {
                        season.map((item, index) => <option key={index} value={item}>{item}</option>)
                    }
                </select>
            </div>
            <div className="row">
                {
                    crops.length === 0 ? 
                    <span className="mt-3">No crops are available.</span> : 
                    <div className="w-100">
                        {
                            crops.map((item, index) => 
                            <div key={index} style={{textTransform : 'capitalize'}}>
                                <div className={styles.cropCard}>
                                    <h5 className="px-3">Basic Information</h5>
                                    <div className="row p-3"> 
                                        <div className="col-md-6" style={{borderRight : '1px solid grey'}}>
                                            <p>Crop : {item.crop!='other' ? item.crop : item.otherCrop}</p>
                                            <p>Season : {item.season}</p>
                                            <p>Variety : {item.variety}</p>
                                            <p>Area : {item.area} acres</p>
                                            <p>Expected Harvesting Date : {moment(item.expectedHarvestingDate).format('DD-MM-YYYY') || <NoneRecord/> }</p>
                                        </div>
                                        <div className="col-md-6">
                                            <p>Expected Sowing Date/Transplantation : {moment(item.expectedSowingDate).format('DD-MM-YYYY') || <NoneRecord/>}</p>
                                            <p>Date of Sowing/Transplantation : {moment(item.sowingDate).format('DD-MM-YYYY') || <NoneRecord/> }</p>
                                            <p>Harvesting Date : {moment(item.harvestingDate).format('DD-MM-YYYY') || <NoneRecord/>}</p>
                                            <p>Land Prepration Date : {moment(item.landPreprationDate).format('DD-MM-YYYY') || <NoneRecord/>}</p>
                                            <p>Major Issues : {item.majorIssues.map((issue) => issue.label).join(', ') || <NoneRecord/>}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.cropCard}>
                                    <h5 className="px-3">Costings</h5>
                                    <div className="row p-3 mt-2">
                                        <div className="col-md-6" style={{borderRight : '1px solid grey'}}>
                                            <p>Per acre cost of Pesticides : {item.pesticideCost!=null ? `Rs.${item.pesticideCost}` : <NoneRecord/>}</p>
                                            <p>Per acre cost of Fertilizers : {item.fertilizerCost !=null ? `Rs.${item.fertilizerCost}` : <NoneRecord/>}</p>
                                            <p>Per acre cost of Labour : {item.labourCost !=null ? `Rs.${item.labourCost}` : <NoneRecord/>}</p>
                                            <p>Other Cost Involved : {item.otherCost !=null ? `Rs.${item.otherCost}` : <NoneRecord/>}</p>
                                        </div>
                                        <div className="col-md-6">
                                            
                                            <p>Total cost of cultivation(per acre) : {`Rs.${(item.pesticideCost || 0) +( item.fertilizerCost || 0) + (item.labourCost || 0) + (item.otherCost || 0)}`}</p>
                                            <p>Yield Per Acre : {item.yieldPerAcre!=undefined ? `${item.yieldPerAcre} Quintals` : <NoneRecord/>} </p>
                                            <p>Total Yield : {item.yieldPerAcre!=undefined ? `${item.yieldPerAcre * item.area} Quintals` : <NoneRecord/>} </p>
                                            <p>Revenue : {item.revenue!=undefined ? `Rs.${item.revenue}` : <NoneRecord/>}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles.cropCard} table-responsive p-3`}>
                                    <h5>Organic Fertlizers</h5>
                                    {
                                        item.organicFertlizers.length!=0 ?
                                        <table className="table table-bordered">
                                        <thead>
                                         <tr>
                                             <td>Organic Manure</td>
                                             <td>Quantity(Quintal Per Acres)</td>
                                             <td>Date</td>
                                             </tr>
                                         </thead> 
                                         <tbody>
                                             {
                                                 item.organicFertlizers?.map((item1, idx) => <tr key={idx}>
                                                     <td>{item1.type!='other' ? item1.type : `${item1.otherType}(Other)`}</td>
                                                     <td>{item1.quantity}</td>
                                                     <td>{moment(item1.date).format('DD-MM-YYYY')}</td>
                                                 </tr>)
                                             }
                                         </tbody>
                                        </table> : <EmptyRecord/>
                                    }
                                </div>
                                <div className={`${styles.cropCard} table-responsive p-3`}>
                                    <h5>Fertilizers</h5>
                                    {
                                        item.fertilizers.length!=0 ?
                                        <table className="table table-bordered">
                                       <thead>
                                            <tr>
                                                <td>Name</td>
                                                <td>Quantity Applied</td>
                                                <td>Date</td>
                                            </tr>
                                        </thead> 
                                        <tbody>
                                            {
                                                item.fertilizers?.map((item1, idx) => <tr key={idx}>
                                                    <td>{item1.fertilizer!='other' ? item1.fertilizer : `${item1.otherFertilizer}(Other)`}</td>
                                                    <td>{item1.dosage} {item1.unit}</td>
                                                    <td>{moment(item1.date).format('DD-MM-YYYY')}</td>
                                                </tr>)
                                            }
                                        </tbody>
                                        </table> : <EmptyRecord/>
                                    }
                                </div>
                                <div className={`${styles.cropCard} table-responsive p-3`}>
                                    <h5>Pests</h5>
                                    {
                                        item.pests.length!=0 ? 
                                        <table className="table table-bordered">
                                            <thead>
                                                    <tr>
                                                        <td>Pesticide Used</td>
                                                        <td>Major Pest</td>
                                                        <td>Dosage</td>
                                                        <td>Date</td>
                                                    </tr>
                                                </thead> 
                                                <tbody>
                                                    {
                                                        item.pests?.map((item1, idx) => <tr key={idx}>
                                                            <td>{item1.pesticideUsed!='other' ? item1.pesticideUsed : `${item1.otherPesticide}(Other)`}</td>
                                                            <td>{item1.majorPest!='other' ? item1.majorPest : `${item1.otherPest}(Other)`}</td>
                                                            <td>{item1.dosage} {item1.unit}</td>
                                                            <td>{moment(item1.date).format('DD-MM-YYYY')}</td>
                                                        </tr>)
                                                    }
                                                </tbody>
                                        </table> : <EmptyRecord/>
                                    }
                                </div>
                                <div className={`${styles.cropCard} table-responsive p-3`}>
                                    <h5>Major Diseases</h5>
                                    {
                                        item.majorDiseases.length!=0 ? 
                                        <table className="table table-bordered">
                                       <thead>
                                            <tr>
                                                <td>Major Disease</td>
                                                <td>Bacter./Fungicides </td>
                                                <td>Dosage</td>
                                                <td>Date</td>
                                            </tr>
                                        </thead> 
                                        <tbody>
                                            {
                                                item.majorDiseases?.map((item1, idx) => <tr key={idx}>
                                                    <td>{item1.majorDiseases!='other' ? item1.majorDiseases : `${item1.otherDisease}(Other)`}</td>
                                                    <td>{item1.bactericideOrFungicideUsed!='other' ? item1.bactericideOrFungicideUsed : `${item1.otherBactericideOrFungicideUsed}(Other)`}</td>
                                                    <td>{item1.dosage} {item1.unit}</td>
                                                    <td>{moment(item1.date).format('DD-MM-YYYY')}</td>
                                                </tr>)
                                            }
                                        </tbody>
                                        </table> : <EmptyRecord/>
                                    }
                                </div>
                                <div className={`${styles.cropCard} table-responsive p-3`}>
                                    <h5>Irrigation</h5>
                                    {
                                        item.irrigations.length!=0 ? 
                                        <table className="table table-bordered">
                                       <thead>
                                        <tr>
                                            <td>Type</td>
                                            <td>Source</td>
                                            <td>Date</td>
                                            </tr>
                                        </thead> 
                                        <tbody>
                                            {
                                                item.irrigations?.map((item1, idx) => <tr key={idx}>
                                                    <td>{item1.type!='other' ? item1.type : `${item1.otherType}(Other)`}</td>
                                                    <td>{item1.source}</td>
                                                    <td>{moment(item1.date).format('DD-MM-YYYY')}</td>
                                                </tr>)
                                            }
                                        </tbody>
                                        </table> : <EmptyRecord/>
                                    }
                                </div>
                                <div className={`${styles.cropCard} table-responsive p-3`}>
                                    <h5>Credits</h5>
                                    {
                                        item.credits.length!=0  ? 
                                        <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <td>Credit Type</td>
                                                <td>Amount available</td>
                                            
                                            </tr>
                                            </thead> 
                                            <tbody>
                                                {
                                                    item.credits?.map((item1, idx) => <tr key={idx}>
                                                        <td>{item1.creditType}</td>
                                                        <td>Rs.{item1.creditAmountAvailable}</td>
                                                    </tr>)
                                                }
                                            </tbody>
                                        </table> : <EmptyRecord/>
                                    }
                                </div>
                            </div>
                            
                            )
                        }
                    </div>
                }
            </div>
        </div>
    );
}
export default CropDetails;