import { getMimeTypeFromDataUri } from "../../../Utils/commonFunctions";

const validate = (values) => {
    const errors = {};
    const aadharFormat = new RegExp('^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$');
    const mobileNumberFormat = new RegExp('^[6-9]{1}[0-9]{9}');
    const panFormat = new RegExp("^[A-Z]{5}[0-9]{4}[A-Z]{1}$");
    const pincodeFormat = new RegExp("^[1-9][0-9]{4,5}$");
    if(values.firstname == ""){
        errors.firstname = "First name is required";
    }
    if(values.gender == ""){
        errors.gender = "Gender is required";
    }
    if(values.guardian == ""){
        errors.guardian = "Guardian Name is required";
    }
    if(values.mobileAccess == ""){
        errors.mobileAccess = "Mobile Access is required";
    }
    if(values.mobileNumber == ""){
        errors.mobileNumber = "Mobile Number is required";
    }
    else if(mobileNumberFormat.test(values.mobileNumber)==false){
        errors.mobileNumber = "Mobile Number is invalid";
    }

    if(values.aadharNumber!=undefined && values.aadharNumber!="" && aadharFormat.test(values.aadharNumber) === false){
        errors.aadharNumber = "Aadhar Number is invalid";
    }
    
    if(values.panNumber!="" && values.panNumber!=undefined && panFormat.test(values.panNumber) === false){
        errors.panNumber = "PAN Number is invalid";
    }

    if(values.organization == ""){
        errors.organization = "Organization is required";
    }
    if(values.state == ""){
        errors.state = "State is required";
    }
    if(values.district == ""){
        errors.district = "District is required";
    }
    if(values.tehsil == ""){
        errors.tehsil = "Tehsil is required";
    }
    if(values.village == ""){
        errors.village = "Village is required";
    }
    if(values.block == ""){
        errors.block = "Block is required";
    }

    if(values.projectName === ''){
        errors.projectName = "Project name is required";
    }
    
    if(values.pincode!=undefined && values.pincode != '' && values.pincode?.length != 6){
        errors.pincode = "Pincode is invalid.";
    }
    
    console.log(errors);
    console.log('====================================');
    return errors;
}

export default validate;

// if(values.ownedLandArea===''){
//     errors.ownedLandArea = "Area is required";
// }
// else if(values.ownedLandArea<0){
//     errors.ownedLandArea = "Value should be greater than zero";
// }

// if(values.leasedLandArea===''){
//     errors.leasedLandArea = "Area is required";
// }
// else if(values.leasedLandArea<0){
//     errors.leasedLandArea = "Value should be greater than zero";
// }

// if((values.leasedLandArea + values.ownedLandArea) < 0){
//     errors.totalLandArea = "Value should be greater than zero";
// }

// let n1 = values.ownedLandKhasraNumbers.length;
// if(n1 > 0){
//     const lastElement = values.ownedLandKhasraNumbers[n1 - 1];
//     if(lastElement===""){
//         //console.log("hit");
//         errors.ownedLandKhasraNumbers = "Field can't be empty";
//     }else{
//         const subArray = values.ownedLandKhasraNumbers.slice(0, n1-1);
//         //check for last element under subarray.
//         if(subArray.includes(lastElement) === true){
//             errors.ownedLandKhasraNumbers = "Entry exists."
//         }
//     }
    
// }

// let n2 = values.leasedLandKhasraNumbers.length;
// if(n2 > 0){
//     const lastElement = values.leasedLandKhasraNumbers[n2 - 1];
//     if(lastElement === ''){
//         errors.leasedLandKhasraNumbers = "Field can't be empty";
//     }else{
//         const subArray = values.leasedLandKhasraNumbers.slice(0, n2-1);
//         //check for last element under subarray.
//         if(subArray.includes(lastElement) === true){
//             errors.leasedLandKhasraNumbers = "Entry exists."
//         }
//     }
    
// }