import { useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
// This component shows the Pie chart of number of farmers in different states
const StatesBarChart = ({data}) => {
    const [data_, setData] = useState([]);
    const [labels, setLabels] = useState([]);
    let statesObj = {};
    console.log(data);

    useState(() => {
        let states = ["Andaman & Nicobar Island",
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chandigarh",
        "Chhattisgarh",
        "Delhi",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu & Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Lakshadweep",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Puducherry",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal"];

        for(let item of data.states){
            statesObj[item.state.toUpperCase()] = item.numberOfFarmers;
        }

        
        
        setData(Object.values(statesObj));
        setLabels(Object.keys(statesObj));

    },[]); 
    return (
      <>
      <div className='dashboard-heading'>
                  <h5 className="m-0 text-white">Each State Farmers Contribution</h5>
          </div>
      
        <div className='dashboard-chart'>
             <Pie
                data={{
                    labels :  labels,
                    
                    datasets: [
                    {
                        label: "Number of farmers",
                        data : data_,
                        
                        backgroundColor: [
                          '#163e33', '#d18a49', '#8a812c', '#8dbe3f', '#e8ba3a', '#578019', '#9a693d'
                        ],
                        borderColor: "black",
                        borderWidth: 1,
                    }
                  ]
              }}
              
                options={{
                  plugins: {
                    legend: {
                      display: true
                    },
                    datalabels : {
                        display : true,
                        color: 'white', // label text color
                        textAlign: 'top',
                        font: {
                          size: 12
                        },
                        formatter: (value, context) =>{
                          return `${value} Farmers`
                        }

                    },
                    pieceLabel: {
                        render: 'value'
                    },
                    title : {
                      display : true,
                      align : 'left',
                      text : 'Pie chart shows number of farmers onboarded from different states'
                    }
                  }
                }}
                plugins={[ChartDataLabels]}
              />
        </div>
        </>
    );

}

export default StatesBarChart;