import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';

export const AWDStateBarChart = ({data}) => {
    const [object, setObject] = useState({});
    useEffect(() => {
        let states = {};
       
        for(let item of data.blocks){
            states[item.block.toUpperCase()] = item.numberOfFarmers;
        }
        setObject(states)
    },[])
    return (
        <div className="col-md-12">
            <div className='dashboard-heading'>
                <h5 className="m-0 text-white">Farmers List from different Blocks</h5>
            </div>
            <div className='dashboard-chart'>
                {/*  farmer${value>1?'s' : ''} */}
                <Bar options={{
                    responsive: true,
                    plugins: {
                        datalabels: {
                            display: true,
                            color: "black",
                            formatter: (value, context) => { return `${value}` },
                            anchor: "end",
                            offset: -25,
                            align: "start",
                            textStrokeWidth  : 0.6
                        },
                        legend: {
                            position: 'top',
                        },
                        title: {
                        display: true,
                        text: 'Bar Chart Shows about farmers enrolled from different blocks',
                        },
                    },
                }} 
                data={{
                    labels : Object.keys(object),
                    datasets: [
                    {
                        label: 'Number of Farmers',
                        data : Object.values(object),
                        backgroundColor: ['#d18a49','#769f34'],
                        borderWidth : 2,
                        borderColor : 'black'
                    }
                    ],
                }} plugins={[ChartDataLabels]} />
            </div>
        </div>
    );
}