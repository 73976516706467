import Layout from "../../../Layout";
import FeildInformation from "./FeildInformation/FeildInformation";

const ManageFeildInformation = () => {
    
    return (
        
                <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row" style={{paddingTop : '80px'}}>
                                <div className="col-12 grid-margin">
                                    <div className="card">
                                        <div className="card-body1">
                                            <h4>Crop Details</h4>
                                            <FeildInformation />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
    );
}

export default ManageFeildInformation;