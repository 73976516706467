
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import styles from './Profile.module.css';
import PDFContent from "../Documents/Agreement/PDFContent";
import { mapping } from "../../Utils/Map";
import moment from 'moment';
const NoneRecord = () => {
    return (
       <span className="text-danger">Not Entered</span>
    );
}


const AboutFarmer = (props) => {
    const {data} = props;
    
    // console.log(data);
    return (
        <>
            <div className="card" style={{fontStyle : 'italic', textTransform : 'capitalize'}}>
                <div className="row" > 
                    <div className="col-md-6 mt-2 p-4">
                        <h5 className="text-info">Personal Details  </h5>
                        <p>Gender : {data.gender} </p>
                        <p>Guardian's Name : {data.guardian} </p>
                        <p>Date of Birth : {moment(data.dob).format('DD-MM-YYYY')} </p>
                        <p>Mobile Access : {mapping[data.mobileAccess]} </p>
                        <p>Mobile Number : {data.mobileNumber} </p>
                        <p>Organization : {mapping[data.organization]} </p>
                        <p>Project Name : {data.projectName != undefined ? data.projectName : <NoneRecord/>} </p>
                    </div>
                    <div className="col-md-6 mt-2 p-4">
                        <h5 className="text-info">Address Details  </h5>
                        <p>State : {data.state} </p>
                        <p>District : {data.district} </p>
                        <p>Block : {data.block} </p>
                        <p>Tehsil : {data.tehsil} </p>
                        <p>Village : {data.village} </p>
                        <p>Pincode : {data.pincode != undefined ? data.pincode : <NoneRecord/> } </p>
                        <p>Is AWD Farmer : {data.isAWDFarmer === true ? 'YES' : 'NO'} </p>
                    </div>
                </div>  
                <div className="row">
                    <div className="col-md-12 mt-3">
                        
                        <div className={`p-3`}>
                            <h5 className="text-info">Signed Agreement</h5>
                            {
                                data.isAgreementSigned === 'success' ? 
                                <>
                                {
                                    window.innerWidth<=991 ? 
                                    <>
                                     <p><strong>PDF View is not supported for mobile.</strong></p>
                                     <p>You can download and view the file using a PDF viewer app on your mobile device.</p>
                                     <PDFDownloadLink document={<PDFContent farmerDetails={data} signatureURI = {data.signature} />} fileName={`${data.firstname}_${data.lastname}_${data.village}`}>
                                            {
                                                ({ blob, url, loading, error }) => loading ? 'Loading document...' : <button className="btn-sm btn-primary"><i className="typcn typcn-download btn-icon-prepend"></i>Download Agreement</button>
                                            }
                                    </PDFDownloadLink>
                                    </>
                                    : 
                                    <>
                                    {
                                        data.agreementMode != 'export' ? <PDFViewer height={400} style={{width : '100%'}} children={<PDFContent farmerDetails={data} signatureURI = {data.signature} />}/> : 
                                        <iframe height={400} style={{width : '100%'}} src={data.agreementFileLink}></iframe>
                                    }
                                        
                                    </>
                                }
                                </> : <span className="text-danger">Agreement Signature is pending.</span>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AboutFarmer;