import React from 'react'

const ProfileLink = ({farmername, _id}) => {
	const userType = localStorage.getItem('type');
	return (
		<div>
			{
				userType === 'intern' ? 
				<p>{farmername.toUpperCase()}</p> : 
				<a style={{color : "blue", textDecoration : 'underline', cursor : 'pointer', textTransform : 'uppercase'}} target="_blank" href={`/#/farmer-profile/${_id}`}>{farmername}</a>
			}
		</div>
	)
}

export default ProfileLink