import { getMimeTypeFromDataUri } from "../../../../Utils/commonFunctions";

const validate = (values) => {
    let errors = {};
    if(values.type === ''){
        errors.type = 'Type is required';
    }
    if(values.file === '') {
        errors.file = 'File Photo is required';
    }
    else if(getMimeTypeFromDataUri(values.file)?.includes('image') === false){
        errors.file = 'File Type must be either png/jpg';
    }
    if(values.date_ === '') {
        errors.date_ = 'Date is required';
    }if(values.file2 === '') {
        errors.file2 = 'File Photo is required';
    }
    else if(getMimeTypeFromDataUri(values.file2)?.includes('image') === false){
        errors.file2 = 'File Type must be either png/jpg';
    }
    // console.log(errors);
    return errors;
}
export default validate;