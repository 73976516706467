import { useEffect, useState } from "react";
import { DocumentTypes } from "./options";
import { fetchDocumentsByQuery} from "../../APIs/apiCalls";

import AadharCardForm from "./AadharCardForm";
import PanCardForm from "./PanCardForm";
import BankDetailsForm from "./BankDetailsForm";
import FarmerAgreement from "./Agreement/FarmerAgreement";
import FarmerSearchBar from "../../Components/FarmerSearchBar/FarmerSearchBar";
import DocumentStatus from "./DocumentStatus";

const Filter = () => {
   
    const [farmerId, setFarmerId] = useState(undefined);
    const [farmer, setFarmer] = useState(undefined);
    const [type, setType] = useState('');
    
    const resetHandler = async () =>{
       
        const entries = (await fetchDocumentsByQuery(farmerId)).data;
        setFarmer(entries)
        setType('');
    }
    const changeDropdowns = async (farmer) => {
        setFarmerId(farmer._id);
        setType('');
        
        const entries = (await fetchDocumentsByQuery(farmer._id)).data;
        console.log(entries);
        setFarmer(entries);
        
    }
    useEffect(()=>{
       
    },[]);
    return (
        <>
        <h4>Select Farmer</h4>
        <FarmerSearchBar change = {changeDropdowns}/>
        <div className="row">
           
            <div className="col-md-3 mt-2">
            <label className="col-form-label">Choose Document to upload</label>
                <select  className="form-control" disabled={farmer === undefined} onChange={(event) => setType(event.target.value)}>
                    <option value=''>Select</option>
                    {
                        !farmer?.aadhar ? <option value={DocumentTypes[0]}>{DocumentTypes[0].toUpperCase()}</option> : null
                    }
                    {
                        !farmer?.pan ? <option value={DocumentTypes[1]}>{DocumentTypes[1].toUpperCase()}</option> : null
                    }
                    {
                        !farmer?.bank ? <option value={DocumentTypes[2]}>{DocumentTypes[2].toUpperCase()}</option> : null
                    }
                    {
                        farmer?.isAgreementSigned === 'pending' ? <option value={DocumentTypes[3]}>{DocumentTypes[3].toUpperCase()}</option> : null
                    }
                </select>
            </div>

            
        </div>

        {
            farmer ? 
            <DocumentStatus farmer={farmer}/> : null
        }
       
            { type===DocumentTypes[0] && farmerId ? <AadharCardForm type={type} farmerId={farmerId} farmer={farmer} reset={resetHandler}/> : null }
            { type===DocumentTypes[1] && farmerId  ? <PanCardForm type={type} farmerId={farmerId} farmer={farmer} reset={resetHandler}/> : null }
            { type===DocumentTypes[2] && farmerId  ? <BankDetailsForm type={type} farmerId={farmerId} farmer={farmer} reset={resetHandler}/> : null }
            { type===DocumentTypes[3] && farmerId  ? <FarmerAgreement farmerDetails={farmer} reset={resetHandler}/> : null  }
        </>
    );
}

export default Filter;