import { useState, useEffect } from "react";

const CSVErrorMessages = ({errors}) => {
   
    return (
        <div>
            
            {
                errors.length > 0 ? 
                <>
                <div id="accordion" style={{backgroundColor : 'rgb(225, 225, 225)', padding : '10px'}}>
                <h6 className="text-danger">There are some records which are already uploaded. Please check.</h6>
                <div className="card" style={{boxShadow : 'none'}}>
                    <div id="headingOne">
                        <h5 className="mb-0">
                            <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Errors <i className="typcn typcn-arrow-sorted-down"></i>
                            </button>
                        </h5>
                    </div>

                    <div id="collapseOne" className="collapse collapsed" aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="card-body">
                            {
                                errors.map((error, index) => <p className="text-danger">{index+1}. {error}</p>)
                            }
                        </div>
                    </div>
                </div>
            </div><br/></> : ''
            }
        </div>
    );
}

export default CSVErrorMessages;