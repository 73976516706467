import { useFormik } from "formik";
import { creditTypes } from "./dropdownOptions";
import styles from './../AddFeildInformation.module.css';
import WarningMessage from "../../../../Components/WarningMessage/WarningMessage";

const validate = (values) => {
    const errors = {};
    if(values.creditType === ''){
        errors.creditType = "Credit Type is required"
    }
    if(values.creditAmountAvailable === ''){
        errors.creditAmountAvailable = "Credit Amount is required"
    }
    if(values.creditAmountAvailable < 0){
        errors.creditAmountAvailable = "Credit Amount can't be negative"
    }
    return errors;
}

const CreditForm = (props) => {
    const formik = useFormik({
        initialValues : {
            creditType : '',
            creditAmountAvailable : ''
        },
        validate,
        onSubmit : (values) => {
            props.change('credits', values);
            formik.resetForm({});
        }
    })
    return (
        <form>
        <div className="row">
            
                <div className="col-md-6">
                    <label className="col-form-label">Types of credit being availed</label>
                    <select className={`form-control ${ formik.submitCount>0 && formik.errors.creditType ? styles.formInputError : ""}`} id='creditType' placeholder="Types of credit" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.creditType}>
                        <option value=''>Select</option>
                        {
                            creditTypes.map((item,index) => <option value={item} key={index}>{item}</option>)
                        }
                    </select>
                    {formik.submitCount>0 && formik.errors.creditType ? <div className={styles.error}>{formik.errors.creditType}</div> : ""}
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Amount of credit available (Finance)</label>
                    <input className={`form-control ${ formik.submitCount>0 && formik.errors.creditAmountAvailable ? styles.formInputError : ""}`}  min={0}
                    type="Number" id='creditAmountAvailable' placeholder="Credit Amount Available" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.creditAmountAvailable}/>
                    {formik.submitCount>0 && formik.errors.creditAmountAvailable ? <div className={styles.error}>{formik.errors.creditAmountAvailable}</div> : ""}
                </div>
                <div className="col-md-6">
                <button className="btn btn-secondary mt-2" type="button" onClick={formik.handleSubmit}>Add</button>
               </div>
               <WarningMessage/>
        </div>
        </form>
    );
}

export default CreditForm;
