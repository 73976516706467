import moment from "moment";
import { mapping } from "../../../../Utils/Map";

const AddedItems = (props) => {
    let {items} = props;

    if(items === undefined){
        return <></>
    }
    const deleteHandler = (index) => {
        console.log(index);
        let arr = items;
        arr.splice(index, 1);
        console.log(arr);
        props.change(props.feildname, arr);
    }
    return (
        <div className="table-responsive mt-3">
            { items[0] && <h6>View Table</h6>}
            <table className="table table-bordered" style={{fontStyle : 'italic'}} >
                <thead>
                    <tr>
                       { items[0] && Object.entries(items[0]).map(([key, value], index) => <td key={index} style={{fontWeight : 'bold', textTransform : 'capitalize'}}>{ mapping[key] || key}</td> )}
                       { items[0] && <td style={{fontWeight : 'bold'}}>Actions</td>}
                    </tr>
                </thead>
                <tbody>
                    {
                        items.map((item, index) => 
                            <tr>
                                {
                                    Object.entries(item).map(([key, value], index) => <td style={{textTransform : 'capitalize'}} key={index}>{value === '' ? '-' : key!='date' ? key!='village' ? value : value.label : moment(value).format('DD-MM-YYYY')}</td>)
                                }
                                {
                                   items[0] && <td><button className="btn-sm btn-danger" type="button" onClick={() => deleteHandler(index)}>Delete</button></td> 
                                }
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    );
}

export default AddedItems;