import { downloadFile } from "../../Utils/commonFunctions";

const Documents = ({aadhar, pan, bank}) => {
   
    return (
        <div className="card" style={{fontStyle : 'italic', textTransform : 'capitalize'}}>
            <div className="row">
                <div className="col-md-12 p-4">
                    <ol>
                    {
                        aadhar ? 
                        <li>
                            <h5>Aadhar Card</h5>
                            
                            <a href={aadhar.frontImageUri} target="_blank">View Front Side <i className="typcn typcn-export"></i></a>
                            <button className="btn btn-sm btn-primary" onClick={() => downloadFile(aadhar.frontImageUri)}>Download <i className="typcn typcn-download"></i></button>
                            <br/><br/>
                            <a href={aadhar.backImageUri} target="_blank">View Back Side <i className="typcn typcn-export"></i></a>
                            <button className="btn btn-sm btn-primary" onClick={() => downloadFile(aadhar.backImageUri)}>Download <i className="typcn typcn-download"></i></button>
                        </li> : 
                        <span className="text-danger"><i className="typcn typcn-warning"></i> Aadhar Card is pending</span>
                    }
                    <hr/>
                    {
                        pan ? 
                        <li>
                            <h5>Pan Card</h5>
                            <a href={pan.uri} target="_blank">View Document <i className="typcn typcn-export"></i></a>
                            <button className="btn btn-sm btn-primary" onClick={() => downloadFile(pan.uri)}>Download <i className="typcn typcn-download"></i></button>
                        </li> : <span className="text-danger"><i className="typcn typcn-warning"></i> PAN Card is pending</span>
                    }
                    <hr/>
                    {
                        bank ? 
                        <li>
                            <h5>Bank Passbook</h5>
                            <a href={bank.uri} target="_blank">View Document <i className="typcn typcn-export"></i></a>
                            <button className="btn btn-sm btn-primary" onClick={() => downloadFile(bank.uri)}>Download <i className="typcn typcn-download"></i></button>
                        </li> : <span className="text-danger"><i className="typcn typcn-warning"></i> Bank Details are pending</span>
                    }
                    </ol>
                </div>
            </div>
        </div>
    )
}

export default Documents;