const validate = (values) => {
   
    let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
    const errors = {};
    console.log(values)
    if(values.firstname === ""){
        errors.firstname = "First Name is required";
    }
    if(values.lastname === ""){
        errors.lastname = "Last name is required";
    }
    if(values.type === ""){
        errors.type = "Type is required";
    }
    if(values.village === ""){
        errors.village = "village is required";
    }
    if(values.email === ""){
        errors.email = "Email is required";
    }
    else if(regex.test(values.email)===false){
        errors.email = "Invalid Email";
    }
    else{
        if(!values.email.includes('@bharatrohan.in')){
            errors.email = "This is not our organization email id";
        }
    }
    if(values.dob === ""){
        errors.dob = "Date of birth is required";
    }
    return errors;
}

export default validate;