
import { useState } from "react";
import DatamapsIndia from "react-datamaps-india";
// In this states are shown with different color whose farmers are enrolled with us.
const StatesCoveredMap = ({data}) => {
    const states = data.states.map(item => item.state);
    console.log(states)
    let statesInfo = {
        "Andaman & Nicobar Island": {
            value : 10                    
        },
        "Andhra Pradesh": {
            value : 10                    
        },
        "Arunachal Pradesh": {
            value : 10                   
        },
        Assam: {
            value : 10                   
        },
        Bihar: {
            value : 10                    
        },
        Chandigarh: {
            value : 10                   
        },
        Chhattisgarh: {
            value : 10
        },
        Delhi: {
            value : 10
        },
        Goa: {
            value : 10                    
        },
        Gujarat: {
            value : 10                    
        },
        Haryana: {
            value : 10
        },
        "Himachal Pradesh": {
            value : 10                    
        },
        "Jammu & Kashmir": {
            value : 10                    
        },
        Jharkhand: {
            value : 10                    
        },
        Karnataka: {
            value : 10
        },
        Kerala: {
            value : 10                    
        },
        Lakshadweep: {
            value : 10                   
        },
        "Madhya Pradesh": {
            value : 10
        },
        Maharashtra: {
            value : 10                    
        },
        Manipur: {
            value : 10                    
        },
        Meghalaya: {
            value : 10                    
        },
        Mizoram: {
            value : 10                    
        },
        Nagaland: {
            value : 10                    
        },
        Odisha: {
            value : 10                    
        },
        Puducherry: {
            value : 10                    
        },
        Punjab: {
            value : 10                    
        },
        Rajasthan: {
            value : 10
        },
        Sikkim: {
            value : 10                    
        },
        "Tamil Nadu": {
            value : 10
        },
        Telangana: {
            value : 10                    
        },
        Tripura: {
            value : 10                    
        },
        "Uttar Pradesh": {
            value : 10
        },
        Uttarakhand: {
            value : 10
        },
        "West Bengal": {
            value : 10
        }
    }
    const [regionData, setRegionData] = useState(statesInfo);
    useState(() => {

        for(let state of states){
            let arr = state.split(' ');
            let str = '';
            for(let item of arr){
                item = item[0].toUpperCase() + item.slice(1);
                str = `${str} ${item}`;
            }
            str = str.trim();
            statesInfo[str] = 100;
        }
        setRegionData(statesInfo);
    },[]);
    return (
        <>
         <div className='dashboard-heading'>
                  <h5 className="m-0 text-white">States whose farmers has been covered</h5>
              </div>
       
        <div className="dashboard-maps" style={{cursor : 'pointer'}}>
            <div style={{ position: "relative" }}>
                <p className="pt-3 pt-3 text-center" style={{color : "grey", fontSize : '14px', fontWeight : 'bolder'}}>Light Green color shows the states whose farmers has been covered</p>
                <DatamapsIndia
                    style={{ postion: "relative !important", marginTop : 0}}
                    regionData={regionData}
                    hoverComponent={({ value }) => {
                    return (
                        <div>
                        <div>
                            {value.name.toUpperCase()}
                        </div>
                        </div>
                    );
                    }}
                
                    mapLayout={{
                    
                    startColor: "#193C34", //green
                    endColor: "#769f34", //red
                    hoverTitle: "Count",
                    noDataColor: "#f5f5f5",
                    borderColor: "#8D8D8D",
                    hoverColor: "#ffb84d", //yellow
                    hoverBorderColor: "white",
                    height: 8,
                    weight: 20
                    }}
                />
                </div>
        </div>
        </>
    );
}

export default StatesCoveredMap;