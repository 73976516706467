import {Document, Page, View, Text, Font, StyleSheet, Image } from '@react-pdf/renderer';
import src from './NotoSans-Regular.ttf';
import src1 from './CedarvilleCursive-Regular.ttf';
import { useEffect, useState } from 'react';
import moment from 'moment';
Font.register({
    family: 'Noto Sans Hindi',
    fonts: [
      {
        src : src
      }
    ],
  })
  
  Font.register({
    family : 'Cedarville Cursive',
    fonts : [
      {
        src : src1
      }
    ],
    fontStyle : 'normal',
    fontWeight : 500
  })

const styles = StyleSheet.create({
kalavidhi : {
    margin : '10px',
    padding: '20px',
    backgroundColor : 'rgb(221, 221, 221)'
},
view : { margin : '10px' },
document : { margin: 10, fontFamily : 'Noto Sans Hindi' },
page : { fontSize : '12px', padding : '15px'},
pageEng : { fontSize : '10px', padding : '15px'},
mainHeading : { fontSize : '19px', textDecoration : 'underline', textAlign : 'center'},
textHeading : { marginTop : '10px', fontSize : '14px'},
textHeadingEng : { marginTop : '10px', fontSize : '12px' },
signature : {fontFamily : 'Cedarville Cursive'},
dynamicText : {textDecoration : 'underline',  textTransform : 'capitalize'}
});

const PDFContent = (props) => {
 if(props.leasedAgreement.khasraNumbers === undefined){
    return <p>Loading...</p>
 }
  return (
    <Document style={styles.document}>
        {/* Hindi */}
        <Page size="A4" style={styles.page}>
            <View style={styles.view}>
                <Text style={styles.mainHeading}>
                Lease Agreement
                </Text>
                <Text style={{ marginTop: '20px' }}>
                  यह प्रमाणित किया जाता है कि मैं,{' '}
                  <Text style={styles.dynamicText}>{props.leasedAgreement.ownerName}</Text>{' '}
                  {props.leasedAgreement.ownerRelation === 'son' ? 'पुत्र' : props.leasedAgreement.ownerRelation === 'daughter' ? 'पुत्री' : 'पत्नी'} <Text style={styles.dynamicText}>{props.leasedAgreement.ownerGuardian}</Text>,{' '}
                  गाँव <Text style={styles.dynamicText}>{props.leasedAgreement.ownerVillage}</Text>, ब्लॉक{' '}
                  <Text style={styles.dynamicText}>{props.leasedAgreement.ownerBlock}</Text>, जिला{' '}
                  <Text style={styles.dynamicText}>{props.leasedAgreement.ownerDistrict}</Text>, को अनुमति दे {props.leasedAgreement.ownerRelation === 'son' ? 'रहा' : 'रही'} हूँ कि निवासी{' '}
                  <Text style={styles.dynamicText}>{props.leasedAgreement.farmerName}</Text>, {props.leasedAgreement.farmerRelation === 'son' ? 'पुत्र' : props.leasedAgreement.farmerRelation === 'daughter' ? 'पुत्री' : 'पत्नी'}{' '}
                  <Text style={styles.dynamicText}>{props.leasedAgreement.farmerGuardian}</Text>, गाँव{' '}
                  <Text style={styles.dynamicText}>{props.leasedAgreement.farmerVillage}</Text>, ब्लॉक{' '}
                  <Text style={styles.dynamicText}>{props.leasedAgreement.farmerBlock}</Text>, जिला{' '}
                  <Text style={styles.dynamicText}>{props.leasedAgreement.farmerDistrict}</Text>, को वर्ष{' '}
                  <Text style={styles.dynamicText}>{props.leasedAgreement.fromYear}</Text> में निम्नलिखित उन्नत कृषि पद्धतियों के लिए पट्टा/खसरा संख्या{' '}
                  <Text style={styles.dynamicText}>{props.leasedAgreement.khasraNumbers?.join(', ')}</Text>{' '}
                  के क्षेत्रफल (एकड़ में) <Text style={styles.dynamicText}>{props.leasedAgreement.area}</Text>  वाली मेरी भूमि पर कृषि कार्य करने की अनुमति प्रदान {props.leasedAgreement.ownerRelation === 'son' ? 'करता' : 'करती'} हूं। 
                  यह समझौता इस बात की पुष्टि करता है कि इस नामांकित भूमि से उत्पन्न सभी कार्बन क्रेडिट भारतरोहण के नाम से पंजीकृत किए जाएंगे 
                  ताकि उन्हें किसी तीसरे पक्ष के खरीदार को बेचा जा सके। कार्बन क्रेडिट के निर्माण के बाद से ही उन पर सभी अधिकार, 
                  शीर्षक और हित भारतरोहण के पास निहित हो जाएंगे।
                </Text>
                <Text style={{ marginTop: '20px' }}></Text>
                {props.leasedAgreement.ownerSign!="" ? <Image src={props.leasedAgreement.ownerSign}  style={{height : 50, width : 50, textAlign : 'right'}} /> : <Text style={styles.signature}>{props.leasedAgreement.ownerName}</Text>}
                <Text>भूस्वामी के हस्ताक्षर</Text>
                <Text>दिनांक : {moment(props.leasedAgreement.ownerDate).format('DD-MM-YYYY')}</Text>
                
                <Text style={{ marginTop: '20px' }}>स्वीकृतकर्ता,</Text>
                {props.leasedAgreement.farmerSign!="" ? <Image src={props.leasedAgreement.farmerSign}  style={{height : 50, width : 50, textAlign : 'right'}} /> : <Text style={styles.signature}>{props.leasedAgreement.farmerName}</Text>}
                
                <Text>खेती करने वाले किसान के हस्ताक्षर</Text>
                <Text>दिनांक : {moment(props.leasedAgreement.farmerDate).format('DD-MM-YYYY')}</Text>
            </View>
        </Page>

        {/* English */}


        <Page size="A4" style={styles.page}>
            <View style={styles.view}>
            <Text style={styles.mainHeading}>Lease Agreement</Text>
          
                     
                <Text style={{marginTop : '20px'}}>
                This is to certify that I,{' '}
                  <Text style={styles.dynamicText}>{props.leasedAgreement.ownerName}</Text>{' '}
                  {props.leasedAgreement.ownerRelation} of{' '}
                  <Text style={styles.dynamicText}>{props.leasedAgreement.ownerGuardian}</Text>, village{' '}
                  <Text style={styles.dynamicText}>{props.leasedAgreement.ownerVillage}</Text>, block{' '}
                  <Text style={styles.dynamicText}>{props.leasedAgreement.ownerBlock}</Text>, district{' '}
                  <Text style={styles.dynamicText}>{props.leasedAgreement.ownerDistrict}</Text>, is allowing{' '}
                  <Text style={styles.dynamicText}>{props.leasedAgreement.farmerName}</Text>, {props.leasedAgreement.farmerRelation} of{' '}
                  <Text style={styles.dynamicText}>{props.leasedAgreement.farmerGuardian}</Text>, village{' '}
                  <Text style={styles.dynamicText}>{props.leasedAgreement.farmerVillage}</Text>, block{' '}
                  <Text style={styles.dynamicText}>{props.leasedAgreement.farmerBlock}</Text>, district{' '}
                  <Text style={styles.dynamicText}>{props.leasedAgreement.farmerDistrict}</Text>, in the year{' '}
                  <Text style={styles.dynamicText}>{props.leasedAgreement.fromYear}</Text> to do cultivation by lease at my land with Patta/ Khasra No.{' '}
                  <Text style={styles.dynamicText}>{props.leasedAgreement.khasraNumbers.join(', ')}</Text> with an area (in acre) of{' '}
                  <Text style={styles.dynamicText}>{props.leasedAgreement.area}</Text>. With this agreement, it has been agreed that all carbon credits generated from this enrolled land shall be registered in the name of BharatRohan to sell to the third-party buyer. BharatRohan shall have all the rights, title, and interest to such carbon credits upon generation.
                </Text>
                 
                 <Text style={{marginTop : '20px'}}></Text>
                 {props.leasedAgreement.ownerSign!="" ? <Image src={props.leasedAgreement.ownerSign}  style={{height : 50, width : 50, textAlign : 'right'}} /> : <Text style={styles.signature}>{props.leasedAgreement.ownerName}</Text>}
                
                <Text>Signature of Landowner</Text>
                <Text>Date: {moment(props.leasedAgreement.ownerDate).format('DD-MM-YYYY')}</Text>
                
                
                <Text style={{marginTop : '20px'}}>Accepted by,</Text>
                {props.leasedAgreement.farmerSign!="" ? <Image src={props.leasedAgreement.farmerSign}  style={{height : 50, width : 50, textAlign : 'right'}} /> : <Text style={styles.signature}>{props.leasedAgreement.farmerName}</Text>}
                <Text>Signature of Cultivating Farmer</Text>
                <Text>Date : {moment(props.leasedAgreement.farmerDate).format('DD-MM-YYYY')}</Text>
            </View>
        </Page>
        
    </Document>
  )
}

export default PDFContent;