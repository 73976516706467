
import Layout from "../../../Layout";
import ManageLocationsTable from "./ManageLocationsTable/ManageLocationsTable";

const ManageLocations = () => {
    

    return (
      
                <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row" style={{paddingTop : '80px'}}>
                                <div className="col-12 grid-margin">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4>Location Table</h4>
                                            <ManageLocationsTable/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>  
    );
}

export default ManageLocations;