import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isTokenValid } from "../APIs/apiCalls";
import { NotificationManager } from "react-notifications";

const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
       
        let token = localStorage.getItem('token');
        
        if(token !== undefined && token !== null){
            isTokenValid({token}).then((response) => {
                if(response.error){
                    localStorage.removeItem('token');
                    NotificationManager.error('Your session has expired. Please log in again.');
                    navigate('/');
                    setIsAuthenticated(false);
                }else{
                    // Optionally set user type or other auth-related state here
                    // localStorage.setItem('type' , response.data.type);
                    setIsAuthenticated(true);
                }
            });
        } else {
            NotificationManager.error('Please log in to view the dashboard.');
            navigate('/');
            setIsAuthenticated(false);
        }
    }, [navigate]);

    // If authentication check hasn't completed, you can return null or a loading spinner
    if(isAuthenticated === null){
        return <div>Loading...</div>;
    }

    return isAuthenticated ? children : null;
}

export default ProtectedRoute;
