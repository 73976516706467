
// Paddy, potato, mentha, Wheat, cumin, coriander, turmeric, fenugreek, sugarcane,  fennel, cotton, mustard, banana, moong, isabgol, fennel	 	 	 	 	 	 	required
export const cropsList = [
    'Paddy',
    'Potato',
    'Mentha',
    'Wheat',
    'Cumin',
    'Coriander',
    'Turmeric',
    'Fenugreek',
    'Sugarcane',
    'Fennel', 
    'Cotton',
    'Mustard', 
    'Banana', 
    'Moong', 
    'Isabgol',
    'Other'
];

export const majorIssues = [
    {label : 'Disease outbreak', value : 'disease outbreak'},
    {label : 'High cost of cultivation', value : 'high cost of cultivation'},
    {label : 'High cost of labour', value : 'high cost of labour'},
    {label : 'High water requirement', value : 'high  water requirement'},
    {label : 'Lack of market linkage', value : 'lack of market linkage'},
    {label : 'Low price, pest attack', value : 'low price, pest attack'},
    {label : 'Nutrient deficiency', value : 'nutrient deficiency'},
    {label : 'Weed infestations', value : 'weed infestations'},
    {label : 'Others', value : 'others'},
     
]

export const organicManures = ['Cow dung', 'Neem Cake', 'FYM'];

export const fertilizers = [
    { name : 'DAP 18:46:0'},
    { name : 'NPK 12:32:16'},
    {name : 'NPK 10:26:26'},
    {name : 'Urea-Nitrogen 46%' },
    {name : 'MOP 60% K2O'},
    {name : 'Humeshakti'},
    {name : 'Jadshakti'},
    {name : 'other'}
]

export const pesticides = [
    'Chlorantraniliprole 18.5 % SC',
    'Emamectin Benzoate 5% SG',
    'Fipronil 0.3% GR',
    'Thiamethoxam 25% WG',
    'Chlorpyriphos 50% + Cypermethrin 5% EC',
    'Imidacloprid 17.8% SL',
    'Acitamiprid 20% SP',
    'Cartap hydrochloride 50% SP',
    'Profenofos 40% + Cypermethrin 4% EC',
    'Ametoctradin 27% + Dimethomorph 20.27% SC',
    'Emamectin Benzoate 0.9% SC + Novaluron 5.25% SC',
    'Carbofuran 3% CG',
    'Diafenthiuron 50% WP'
];

export const fungicides = [
    'Carbendazim 12% + Mancozeb 63% WP',
    'Copper oxychloride 50% WP',
    'Propineb 70% WP',
    'Azoxystrobin 8.3 % + Mancozeb 66.7% WG',
    'Carbendazim 50% WP',
    'Capton 70% + Hexaconazole 5% WP',
    'Sulphur 80% WDG',
    'Propiconazole 25% EC',
    'Mancozeb 75% WP',
    'Azoxystrobin 11% + Tebuconazole 18.3 % SC',
    'Tebuconazole 10% + Sulphur 65% WG',
    'Azoxystrobin 2.5% + Thiophanate methyl 11.25% + Thiamethoxam 25% FS',
    'Penflufen 240 FS',
    'Silvershakti',
]

export const irrigationTypes = ['Flood Irrigation', 'Drip Irrigation', 'Sprinkler', 'Furrow Irrigation']

export const irrigationSources = ['Canal', 'Tube well','Others'];

export const creditTypes = ['None', 'Agri input credit from retailor', 'Kisan Credit Card', 'Loan from MFI', 'Loan from local individual', 'Loan from family', 'Other']

export const season = ['Kharif Season', 'Rabi Season', "Zaid Season"];

const currentYear = new Date().getFullYear();

export const yearsArray = Array.from(['2024', '2023']);

export const units = ['kg/acre', 'g/acre', 'ml/acre']