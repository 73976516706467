import { useFormik } from "formik";
import styles from './../AddFeildInformation.module.css';
import { units } from "./dropdownOptions";
import { getTodaysDate } from "../../../../Utils/commonFunctions";
import WarningMessage from "../../../../Components/WarningMessage/WarningMessage";

const validate = (values) => {
    const errors = {};

    if(values.majorDiseases === '' && values.otherDisease === ""){
        errors.majorDiseases = "Major Diseases is required";
    }
    if(values.majorDiseases === "other" && values.otherDisease === '' ){
        errors.otherDisease = "Disease is required";
    }
    if(values.bactericideOrFungicideUsed === ''){
        errors.bactericideOrFungicideUsed = "This Feild is required";
    }
    if(values.bactericideOrFungicideUsed === 'other' && values.otherBactericideOrFungicideUsed === ''){
        errors.otherBactericideOrFungicideUsed = "This Feild is required";
    }
    if(values.dosage === ''){
        errors.dosage = "Dosage is required";
    }
    if(values.unit === ''){
        errors.unit = "Unit is required";
    }
    if(values.dosage < 0){
        errors.dosage = "Dosage can't be negative"
    }
    if(values.date === ''){
        errors.date = "Date is required"
    }
    // console.log(errors);
    return errors;
}



const MajorDiseasesForm = ({diseases, crop, fungicides, change}) =>{
    const todaysDate = getTodaysDate();
    crop = crop.toLowerCase();
    const formik = useFormik({
        initialValues : {
            majorDiseases : '',
            otherDisease : '',
            bactericideOrFungicideUsed : '',
            otherBactericideOrFungicideUsed : '',
            unit : '',
            dosage : '',
            date : ''
        },
        validate,
        onSubmit : (values) => {
            change('majorDiseases', values);
            formik.resetForm({});
        }
    })
    const handleChange = (event)  => {
        let {value} = event.target;
        formik.setFieldValue('bactericideOrFungicideUsed', value);
        if(value!='other'){
            formik.setFieldValue('otherBactericideOrFungicideUsed', '');
            let idx = fungicides.findIndex(item => item.name === value);
            formik.setFieldValue('unit', fungicides[idx].unit);
        }else{
            formik.setFieldValue('unit', '');
        }
    }
    return (
        <div>
            <form>
                <div className="row">
                    {
                        crop != 'other' ? 
                        <div className="col-md-3">
                            <label className="col-form-label">Major Crop Diseases </label>   
                            <select className={`form-control ${ formik.submitCount>0 && formik.errors.majorDiseases ? styles.formInputError : ""}`} 
                                id='majorDiseases' placeholder="Major Diseases" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.majorDiseases}>
                                    <option value="">Select</option>
                                    {
                                        diseases?.map((item,index) => <option key={index}>{item}</option>)
                                    }
                                    <option value="other">Other</option>
                            </select> 
                            {formik.submitCount>0 && formik.errors.majorDiseases ? <div className={styles.error}>{formik.errors.majorDiseases}</div> : ""}
                        </div> : ''
                    }
                    

                    {
                        formik.values.majorDiseases === 'other' || crop === 'other' ? 
                        <div className="col-md-3">
                            <label className="col-form-label">Mention Name of Crop Disease</label>   
                            <input type="text" 
                            className={`form-control ${ formik.submitCount>0 && formik.errors.otherDisease ? styles.formInputError : ""}`} 
                            id='otherDisease' placeholder="Other Diseases" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.otherDisease}/>      
                             {formik.submitCount>0 && formik.errors.otherDisease ? <div className={styles.error}>{formik.errors.otherDisease}</div> : ""}
                        </div> : ''
                    }
                    
                    <div className="col-md-3">
                        <label className="col-form-label">Name of the Fungicide used</label>   
                        <select 
                        className={`form-control ${ formik.submitCount>0 && formik.errors.bactericideOrFungicideUsed ? styles.formInputError : ""}`} 
                        id='bactericideOrFungicideUsed' placeholder="Fungicide/bacteriacide used" onChange={(event) => handleChange(event)} onBlur={formik.handleBlur} value={formik.values.bactericideOrFungicideUsed}>
                        <option value=''>Select</option>
                        {
                            fungicides?.map((item, index) => <option key={index} value={item.name}>{item.name}</option> )
                        }
                        <option value='other'>Other</option>
                        </select> 
                        {formik.submitCount>0 && formik.errors.bactericideOrFungicideUsed ? <div className={styles.error}>{formik.errors.bactericideOrFungicideUsed}</div> : ""}
                    </div>
                    {
                        formik.values.bactericideOrFungicideUsed === 'other' ? 
                        <div className="col-md-3">
                            <label className="col-form-label">Mention name of Fungicide used</label>   
                            <input type="text" 
                            className={`form-control ${ formik.submitCount>0 && formik.errors.otherBactericideOrFungicideUsed ? styles.formInputError : ""}`} 
                            id='otherBactericideOrFungicideUsed' placeholder="Other Fungicide used" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.otherBactericideOrFungicideUsed}/>      
                            {formik.submitCount>0 && formik.errors.otherBactericideOrFungicideUsed ? <div className={styles.error}>{formik.errors.otherBactericideOrFungicideUsed}</div> : ""}
                        </div> : ''
                    }
                    {
                        formik.values.bactericideOrFungicideUsed != 'other' ? 
                        <div className="col-md-3">
                                <label className="col-form-label">Unit</label>   
                                <input type="text" 
                                placeholder="unit"
                                className={`form-control form-unit ${ formik.submitCount>0 && formik.errors.unit ? styles.formInputError : ""}`} 
                                disabled={true}
                                id='unit' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.unit}/>     
                                 {formik.submitCount>0 && formik.errors.unit ? <div className={styles.error}>{formik.errors.unit}</div> : ""} 
                        </div> : 
                        <div className="col-md-3">
                            <label className="col-form-label">Unit</label>   
                            <select type="text" 
                            className={`form-control form-unit ${ formik.submitCount>0 && formik.errors.unit ? styles.formInputError : ""}`} 
                            id='unit' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.unit}>
                                <option value=''>Select Unit</option>
                                {
                                    units.map((item) => <option value={item}>{item}</option>)
                                }
                            </select>  
                            {formik.submitCount>0 && formik.errors.unit ? <div className={styles.error}>{formik.errors.unit}</div> : ""}    
                        </div>
                    }
                    <div className="col-md-3">
                        <label className="col-form-label">Dosage</label>   
                        <input type="number" 
                        className={`form-control ${ formik.values.dosage <0 || (formik.submitCount>0 && formik.errors.dosage) ? styles.formInputError : ""}`} 
                        id='dosage' placeholder="Dosage" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.dosage}/>
                        {formik.values.dosage < 0 || (formik.submitCount>0 && formik.errors.dosage) ? <div className={styles.error}>{formik.errors.dosage}</div> : ""}
                    </div>
                    <div className="col-md-3">
                        <label className="col-form-label">Date of Application</label>   
                        <input max={todaysDate}
                        className={`form-control ${ formik.values.date <0 || (formik.submitCount>0 && formik.errors.date) ? styles.formInputError : ""}`} 
                        type="date" id='date' placeholder="Date" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.date}/>
                         {formik.values.date < 0 || (formik.submitCount>0 && formik.errors.date) ? <div className={styles.error}>{formik.errors.date}</div> : ""}
                    </div>
                    
                </div>
                <div className="row col-md-4">
                    <button className="btn btn-secondary mt-2" type="button" onClick={formik.handleSubmit}>Add</button>
                </div>
                <WarningMessage/>
            </form>
        </div>
    ); 
}

export default MajorDiseasesForm;