import { useEffect, useState } from "react";
import { deleteLandRecords, getLandRecords } from "../../../../APIs/apiCalls";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { downloadFile } from "../../../../Utils/commonFunctions";
import AlertConfirm from "react-alert-confirm";

const LandDocumentsList = ({changeRecords, data, edit}) => {
    console.log(data.farmerId);
    
    // const removeHandler = (id) => {
    //     AlertConfirm({
    //         title: 'Are you sure?',
    //         desc: 'This cannot be undone.',
    //         onOk: () => {
    //             deleteLandRecords(id, data.farmerId).then(async (response) => {
    //                 console.log(response);
    //                 if(response.result === 'success'){
    //                     changeRecords(response.data.land);
    //                     NotificationManager.success('Removed the record successfully', 'Success', 1000);
    //                 }
    //             })
    //         },
    //         onCancel: () => {
    //           // console.log('cancel');
    //         }
    //     });
        
        
    // }
    return (
        <div >
            <div className="row justify-content-between">
                <h4 >Land Documents</h4>
                {
                    data && data != null &&
                    <div>
                        <button className="btn btn-sm btn-success" onClick={() => edit()} >Edit</button> &nbsp;
                        {/* <button className="btn btn-sm btn-danger" onClick={() => removeHandler(data._id)}>Delete</button> */}
                    </div> 
                }
                
            </div>
            {
                data && data != null ? 
                <div>
                    
                    <div className="mt-3 mb-3">
                        <h6 style={{textDecoration : 'underline'}}>
                            Leased Documents
                        </h6>
                        <p>Leased Land Area : <u>{data.leasedLandArea} Acres</u></p>
                        <p>Leased Land Khasra Numbers :<u> {data.leasedLandKhasraNumbers.join(', ')}</u></p>
                        <div>
                            {
                                data.leasedDocuments.map((url, index) => 
                                    <div  className="my-1" key={index}>
                                        <a className="btn btn-sm btn-info" target="_blank" href={url}>View</a> &nbsp;
                                        <button className="btn btn-sm btn-info" onClick={() => downloadFile(url)}>Download</button>
                                    </div>
                                )
                            }
                        </div>
                        <hr/>
                        <h6 style={{textDecoration : 'underline'}}>Owned Documents</h6>
                        <p>Owned Land Area : <u>{data.ownedLandArea} Acres</u></p>
                        <p>Owned Land Khasra Numbers : <u>{data.ownedLandKhasraNumbers.join(', ')}</u></p>
                        <div>
                            {
                                data.ownedDocuments.map((url, index) => 
                                <div  className="my-1" key={index}>
                                    <a className="btn btn-sm btn-info" target="_blank" href={url}>View</a> &nbsp;
                                    <button className="btn btn-sm btn-info" onClick={() => downloadFile(url)}>Download</button>
                                </div>
                                )
                            }
                        </div>
                    </div>
                </div> : 'No Entries are created.'
            }
            <NotificationContainer/>
        </div>
    );
}

export default LandDocumentsList;