
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import validate from '../validate';
import styles from '../AddOnboardingInformation.module.css';
import { useParams } from 'react-router-dom';
import { addFarmer, getLocations, getfarmerById, updateFarmer } from '../../../../APIs/apiCalls';
import {  getTodaysDate } from '../../../../Utils/commonFunctions';
import Select,  { createFilter } from 'react-select';
import { NotificationManager } from 'react-notifications';
import Spinners1 from '../../../../Components/Spinners/Spinners1';

const projectList = ['Alternate Wetting and Drying (AWD)'];

const FarmerRegistration = (props) => {
    const {id} = useParams();
    
    const todaysDate = getTodaysDate();
    const filterConfig = {
        ignoreCase : true,
        matchFrom: 'start',
    };

    
    const [disabled, setDisabled] = useState(false);
    //locations hooks.
    const [location, setLocation] = useState([]);
    const [options, setOptions] = useState([]);
      const initialValues = {
        firstname : '',
        middlename : '',
        lastname : '',
        alternatename : '',
        gender : 'male',
        dob : '',
        guardian : '',
        mobileAccess : 'ownnumber',
        mobileNumber : '',
        organization : 'selfemployed',
        aadharNumber : '',
        panNumber : '',
        state : '',
        district : '',
        block : '',
        tehsil : '',
        village : '',
        pincode : '',
        isAWDFarmer : true,
        projectName : projectList[0],
        
    };
    const formik = useFormik({
        initialValues : initialValues,
        validate,
        onSubmit : async (values) => {
            setDisabled(true);
            await saveData(values);
        }
    })
    

    const saveData = async(entry) => {
        //send data to the server.
        console.log(entry);
        
        const response = id===undefined ?  await addFarmer(entry) : await updateFarmer(id, entry);
        setDisabled(false);

        if(response.result === 'success'){
         
          if(id===undefined){
            formik.resetForm({})
            formik.setValues(initialValues);
          }
          NotificationManager.success('Thank you', `${id!=undefined ? 'Updated' : 'Added'} the farmer Successfully`, 2000);
        }else{
            NotificationManager.error('Error', response.error, 2000);
        }
    }

    const projectChangeHandler = (value) => {
      formik.setFieldValue('projectName', value);
      formik.setFieldValue('isAWdFarmer', value === projectList[0] ? true : false);
    }

    

    const createLabelsAndValues = (value, locationArray) => {
      formik.setFieldValue('block', value)
      let arr = Array.from(new Set(locationArray.filter(item=> item.block === value))).sort((a,b) => a.village.localeCompare(b.village)).map((obj) => obj['village']);
      let optns = [];
      for(let item of arr){
        let obj = { value : item, label : item.toUpperCase() };
        optns.push(obj);
      }
      setOptions(optns);
      return optns;
    }
    const resetHandler = () => {
      formik.resetForm({})
      formik.setValues(initialValues);
    }
    useEffect(()=>{

        // getLocations().then((response) => setLocation(response.data));
        (async() => {
          const locationArray = (await getLocations()).data;
          setLocation(locationArray);
          if(id){
            const farmer = (await getfarmerById(id)).data;
            const {block} = farmer;
            console.log(farmer);
            let optns = createLabelsAndValues(block, locationArray);
            setOptions(optns);
            formik.setValues(farmer);
            projectChangeHandler(projectList[0]);
            formik.setFieldValue('village', {label : farmer.village.toUpperCase(), value : farmer.village});
          }else{
            formik.resetForm({})
            formik.setValues(initialValues);
          }
        })();
    },[id]);
    if(location.length===0){
        return <Spinners1/>
    }
    return (
        <>
        <div style={{marginBottom : '20px'}}>
          <h4>Farmer Registration Form</h4>
        </div>
        <form className="form-sample" onSubmit={formik.handleSubmit} onReset={resetHandler}>
          <h5 className="card-title list-arrow">Personal Details</h5>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">First Name*</label>
                <div className="col-sm-9">
                  <input type="text" className={`form-control ${ formik.touched.firstname===true && formik.errors.firstname ? styles.formInputError : "" }`} placeholder="Enter Farmer's First Name" id='firstname' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.firstname} /> {formik.touched.firstname === true && formik.errors.firstname ? <div className={styles.error}>{formik.errors.firstname}</div> : ""}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Middle Name</label>
                <div className="col-sm-9">
                  <input type="text" className={`form-control `} placeholder="Middle Name" id='middlename' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.middlename} />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Last Name</label>
                <div className="col-sm-9">
                  <input type="text" className={`form-control ${ formik.touched.lastname===true && formik.errors.lastname ? styles.formInputError : "" }`} placeholder="Enter Farmer's Last Name" id='lastname' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.lastname} /> 
                  {formik.touched.lastname === true && formik.errors.lastname ? <div className={styles.error}>{formik.errors.lastname}</div> : ""}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Alternate Name</label>
                <div className="col-sm-9">
                  <input type="text" className={`form-control ${ formik.touched.alternatename===true && formik.errors.alternatename ? styles.formInputError : "" }`} placeholder="Enter Farmer's Alternate Name" id='alternatename' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.alternatename} />
                  <div className={styles.warningMessage}>
                    <i className='typcn typcn-warning'></i> Alternate Name Examples : Chotu, Rajju etc.
                  </div> {formik.touched.alternatename === true && formik.errors.alternatename ? <div className={styles.error}>{formik.errors.alternatename}</div> : ""}
                </div>
              </div>
            </div>
          </div>
          
          <div className="row">
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Gender*</label>
                <div className="col-sm-9">
                  <select className={`form-control ${ formik.touched.gender===true && formik.errors.gender ? styles.formInputError : "" }`} id='gender' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.gender}>
                    <option value="">Select</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="others">Others</option>
                  </select> {formik.touched.gender === true && formik.errors.gender ? <div className={styles.error}>{formik.errors.gender}</div> : ""}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Date of Birth</label>
                <div className="col-sm-9">
                  <input type='date' className={`form-control ${ formik.touched.dob===true && formik.errors.dob ? styles.formInputError : "" }`} placeholder='Date of Birth' id='dob' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.dob} 
                  max={todaysDate}/> {formik.touched.dob === true && formik.errors.dob ? <div className={styles.error}>{formik.errors.dob}</div> : ""}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Guardian*</label>
                <div className="col-sm-9">
                  <input type='text' className={`form-control ${ formik.touched.guardian===true && formik.errors.guardian ? styles.formInputError : "" }`} placeholder="Enter Guardian Name" id='guardian' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.guardian} /> {formik.touched.guardian === true && formik.errors.guardian ? <div className={styles.error}>{formik.errors.guardian}</div> : ""}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Mobile Access*</label>
                <div className="col-sm-9">
                  <select className={`form-control ${ formik.touched.mobileAccess===true && formik.errors.mobileAccess ? styles.formInputError : "" }`} id='mobileAccess' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.mobileAccess}>
                    <option value="">Select</option>
                    <option value="ownnumber">Own Number</option>
                    <option value="relative">Relative</option>
                  </select> {formik.touched.mobileAccess === true && formik.errors.mobileAccess ? <div className={styles.error}>{formik.errors.mobileAccess}</div> : ""}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Mobile Number*</label>
                <div className="col-sm-9">
                  <input type="text" className={`form-control ${ formik.touched.mobileNumber===true && formik.errors.mobileNumber ? styles.formInputError : "" }`} placeholder="Recipient's mobile number" aria-label="Recipient's mobile number" id='mobileNumber' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.mobileNumber} /> {formik.touched.mobileNumber === true && formik.errors.mobileNumber ? <div className={styles.error}>{formik.errors.mobileNumber}</div> : ""}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Organization*</label>
                <div className="col-sm-9">
                  <select className={`form-control ${ formik.touched.organization===true && formik.errors.organization ? styles.formInputError : "" }`} id='organization' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.organization}>
                    <option value="">Select</option>
                    <option value="selfemployed">Self Employed(Farmer)</option>
                    <option value="fpo">FPO</option>
                  </select> {formik.touched.organization === true && formik.errors.organization ? <div className={styles.error}>{formik.errors.organization}</div> : ""}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Aadhar Number</label>
                <div className="col-sm-9">
                  <input placeholder="Enter Aadhar Number" className={`form-control ${ formik.touched.aadharNumber===true && formik.errors.aadharNumber ? styles.formInputError : "" }`} id='aadharNumber' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.aadharNumber} />
                  <div className={styles.warningMessage}>
                    <i className='typcn typcn-warning'></i> Aadhar Number is not neccessary for now but ensure to upload it later.
                  </div> {formik.touched.aadharNumber === true && formik.errors.aadharNumber ? <div className={styles.error}>{formik.errors.aadharNumber}</div> : ""}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">PAN Number</label>
                <div className="col-sm-9">
                  <input placeholder="Enter PAN Number" className={`form-control ${formik.touched.panNumber===true && formik.errors.panNumber ? styles.formInputError : "" }`} id='panNumber' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.panNumber} />
                  <div className={styles.warningMessage}>
                    <i className='typcn typcn-warning'></i> PAN Number is not neccessary for now but ensure to upload it later.
                  </div> {formik.touched.panNumber === true && formik.errors.panNumber ? <div className={styles.error}>{formik.errors.panNumber}</div> : ""}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Project Name</label>
                <div className="col-sm-9">
                  <select className={`form-control ${formik.touched.projectName===true && formik.errors.projectName ? styles.formInputError : "" }`} id='projectName' onChange={(event) => projectChangeHandler(event.target.value)} onBlur={formik.handleBlur} value={formik.values.projectName} >
                    <option value="" >Select</option>
                    {
                      projectList.map((projectName, index) => <option key={index} value={projectName}>{projectName}</option>)
                    }
                  </select>
                   {formik.touched.projectName === true && formik.errors.projectName ? <div className={styles.error}>{formik.errors.projectName}</div> : ""}
                </div>
              </div>
            </div>
            
          </div>
          <hr style={{marginBottom : '35px'}} />
          
          <h5 className="card-title">Location Details</h5>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">State*</label>
                <div className="col-sm-9">
                  <select className={`form-control ${ formik.touched.state===true && formik.errors.state ? styles.formInputError : "" }`} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.state} id='state'>
                    <option value="">Select State</option> { Array.from(new Set(location.map(item => item.state))).map((item, index) => <option key={index} value={item}>{item.toUpperCase()}</option>) }
                  </select> {formik.touched.state === true && formik.errors.state ? <div className={styles.error}>{formik.errors.state}</div> : ""}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">District*</label>
                <div className="col-sm-9">
                  <select className={`form-control ${ formik.touched.district===true && formik.errors.district ? styles.formInputError : "" }`} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.district} id='district'>
                    <option value="">Select District</option> { Array.from(new Set(location.filter(item => item.state === formik.values.state).map(item => item.district))).map((item, index) => <option value={item} key={index}>{item.toUpperCase()}</option>) }
                  </select> {formik.touched.district === true && formik.errors.district ? <div className={styles.error}>{formik.errors.district}</div> : ""}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Tehsil*</label>
                <div className="col-sm-9">
                  <select className={`form-control ${ formik.touched.tehsil===true && formik.errors.tehsil ? styles.formInputError : "" }`} id='tehsil' placeholder="Tehsil" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.tehsil}>
                    <option value=''>Select a Tehsil</option> { Array.from(new Set(location.filter(item => item.district === formik.values.district).map(item => item.tehsil))).map((item, index) => <option value={item} key={index}>{item.toUpperCase()}</option>) }
                  </select> {formik.touched.tehsil === true && formik.errors.tehsil ? <div className={styles.error}>{formik.errors.tehsil}</div> : ""}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Block*</label>
                <div className="col-sm-9">
                  <select className={`form-control ${ formik.touched.block===true && formik.errors.block ? styles.formInputError : "" }`} placeholder="Block" id='block' onChange={(event) => createLabelsAndValues(event.target.value, location)} onBlur={formik.handleBlur} value={formik.values.block}>
                    <option value=''>Select a Block</option> { Array.from(new Set(location.filter(item => item.tehsil === formik.values.tehsil).map(item => item.block))).map((item, index) => <option value={item} key={index}>{item.toUpperCase()}</option>) }
                  </select> {formik.touched.block === true && formik.errors.block ? <div className={styles.error}>{formik.errors.block}</div> : ""}
                </div>
              </div>
            </div>
          </div>
          <div className="row"> 
           <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Village*</label>
                <div className="col-sm-9">
                  <Select className={ formik.touched.village===true && formik.errors.village ? styles.formInputError : "" } 
                  id='village' 
                  value={formik.values.village}
                  options={options} isClearable={true} 
                  isSearchable={true} onBlur={formik.handleBlur} 
                  placeholder="Search." 
                  onChange={(event) => formik.setFieldValue('village', event)} 
                  filterOption={createFilter(filterConfig)} /> 
                  {formik.touched.village === true && formik.errors.village ? <div className={styles.error}>{formik.errors.village}</div> : ""}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Pincode</label>
                <div className="col-sm-9">
                  <input placeholder="Enter Pincode" className={`form-control ${ (formik.submitCount>0 || formik.touched.pincode===true) && formik.errors.pincode ? styles.formInputError : "" }`} 
                  id='pincode' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.pincode} />
                  {(formik.submitCount>0 || formik.touched.pincode===true) && formik.errors.pincode ? <div className={styles.error}>{formik.errors.pincode}</div> : ""}
                </div>
              </div>
            </div>
          </div>
          
          {/* <hr style={{marginBottom : '35px'}} /> */}
          {/* <h5 className="card-title">Land Details-</h5>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-4 col-form-label">Owned Land Area(Acres)*</label>
                <div className="col-sm-8">
                  <input type="number" min={0} className={`form-control ${ formik.touched.ownedLandArea===true && formik.errors.ownedLandArea ? styles.formInputError : "" }`} placeholder="Owned Land Area in Acres" id='ownedLandArea' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.ownedLandArea}  step="any"/> {formik.touched.ownedLandArea === true && formik.errors.ownedLandArea ? <div className={styles.error}>{formik.errors.ownedLandArea}</div> : ""}
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-4 col-form-label">Khasra Numbers</label>
                <div className="col-sm-8"> { formik.values.ownedLandKhasraNumbers?.map((_, index) => <div className="input-group mb-2" key={index}>
                    <input type="text" className="form-control" placeholder="Owned Land Khasra Number" aria-label="Owned Land Khasra Number" value={formik.values.ownedLandKhasraNumbers[index]} onChange={(e)=> ownedLandChangeHandler(e, index)} /> <div className="input-group-append ml-2">
                      <button id='btn-index' type="button" className="btn btn-sm btn-social-icon btn-outline-facebook" onClick={()=>ownedLandRemoveHandler(index)}> <i className="typcn typcn-minus"></i>
                      </button>
                    </div>
                  </div> ) } 
                  {
                    formik.errors.ownedLandKhasraNumbers ? <div className={styles.error}>{formik.errors.ownedLandKhasraNumbers}</div> : "" 
                  }
                  <button type='button' className='btn btn-primary' onClick={addOwnedLandKhasraNumber} disabled={formik.errors.ownedLandKhasraNumbers}>Add</button>
                  <div className={styles.warningMessage}>
                    <i className='typcn typcn-warning'></i> Khasra Number is not neccessary for now but ensure to enter it later.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-4 col-form-label">Leased Land Area(Acres)*</label>
                <div className="col-sm-8">
                  <input type="number" min={0} className={`form-control ${ formik.touched.leasedLandArea===true && formik.errors.leasedLandArea ? styles.formInputError : "" }`} placeholder="Leased Land Area in Acres" id='leasedLandArea' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.leasedLandArea} step="any"/> {formik.touched.leasedLandArea === true && formik.errors.leasedLandArea ? <div className={styles.error}>{formik.errors.leasedLandArea}</div> : ""}
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-4 col-form-label">Khasra Numbers</label>
                <div className="col-sm-8"> { formik.values.leasedLandKhasraNumbers?.map((_, index) => <div className="input-group mb-2" key={index}>
                    <input type="text" className="form-control" placeholder="Leased Land Khasra Number" aria-label="Leased Land Khasra Number" value={formik.values.leasedLandKhasraNumbers[index]} onChange={(e)=> leasedLandChangeHandler(e, index)} /> <div className="input-group-append ml-2">
                      <button id='btn-index' type="button" className="btn btn-sm btn-social-icon btn-outline-facebook" onClick={()=>leasedLandRemoveHandler(index)}> <i className="typcn typcn-minus"></i>
                      </button>
                    </div>
                  </div> ) } 
                  {
                    formik.errors.leasedLandKhasraNumbers ? <div className={styles.error}>{formik.errors.leasedLandKhasraNumbers}</div> : "" 
                  }
                  <button type='button' className='btn btn-primary' disabled={formik.errors.leasedLandKhasraNumbers} onClick={addLeasedLandKhasraNumber}>Add</button>
                  <div className={styles.warningMessage}>
                    <i className='typcn typcn-warning'></i> Khasra Number is not neccessary for now but ensure to enter it later.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-4 col-form-label">Total Land Area</label>
                <div className="col-sm-8">
                  <input className={`form-control ${ formik.errors.totalLandArea ? styles.formInputError : "" }`} type='number' id='totalLandArea' disabled={true} onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.leasedLandArea + formik.values.ownedLandArea} /> {formik.errors.totalLandArea ? <div className={styles.error}>{formik.errors.totalLandArea}</div> : ""}
                </div>
              </div>
            </div>
          </div> */}
          <div style={{display : 'flex', justifyContent : 'center'}}>
            <button type="submit" className="btn btn-bluish-green btn-icon-text" style={{marginRight : '5px'}} disabled={disabled}>
              <i className="typcn typcn-document btn-icon-prepend"></i> Save </button>
            <button type="reset" className="btn btn-danger btn-icon-text">
              <i className="typcn typcn-warning btn-icon-prepend"></i> Reset </button>
          </div>
        </form>
      </>
    );
}

export default FarmerRegistration;

