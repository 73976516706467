import MaterialReactTable from "material-react-table";
import { useEffect, useState } from "react";
import { deleteDocument, exportCSV, getTableData, uploadDocumentsByCSV } from "../../APIs/apiCalls";
import Spinners1 from "../../Components/Spinners/Spinners1";
import { NotificationManager } from "react-notifications";
import CSVErrorMessages from "../../Components/CSVErrorMessages/CSVErrorMessages";
import { downloadFile, handleDownloadZip } from "../../Utils/commonFunctions";
import AlertConfirm from "react-alert-confirm";
import { fetchUrl } from "../../APIs/server";
const url_ = fetchUrl;

const DocumentTable = () => {
    const userType = localStorage.getItem('type');
    const [flag, setFlag] = useState(false);
    const [fileUploadingErrors, setFileUploadingErrors] = useState(false);
    const [data, setData] = useState('');
    const [disabledIds, setDisabledIds] = useState([]);

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
   
    const handleDeletion = (id) => {
        AlertConfirm({
            title: 'Are you sure?',
            desc: 'This cannot be undone.',
            onOk: () => {
              setDisabledIds(prevIds => [...prevIds, id]);
              deleteDocument(id).then(response => {
               
                if(response.result === 'success'){
                    setFlag(!flag);
                    NotificationManager.success('Document has been removed successfully');
                }
              })
            },
            onCancel: () => {
              
            }
        });
    }   
    const columns = [
        {
            accessorKey : 'farmername',
            header : 'Farmer Name',
            Cell : (data) => {
                const {_id, farmername} = data.row.original;
                
                return (
                    <div style={{color : "blue", textDecoration : 'underline', cursor : 'pointer', textTransform : 'uppercase'}} >
                        <a href={`/#/farmer-profile/${_id}`} target="_blank">
                            {farmername}
                        </a>
                    </div>
                )
            }
        },
        {
            accessorKey: 'isAWDFarmer',
            header: 'AWD Farmer',
            Cell : ({cell}) => {
                return <>{cell.getValue() === true ? 'TRUE' : 'FALSE'}</>
            }
        },
        {
            accessorKey: 'guardian',
            header: 'Guardian Name',
            Cell : ({cell}) => {
                const guardian =  cell.getValue();
                return <>{guardian.toUpperCase()}</>
            }
        },
        {
            accessorKey : 'isAadharUploaded',
            header : 'Aadhar Status',
            Cell : (data) => {
                const {isAadharUploaded} = data.row.original;
                
                return (
                    <div><span className={`text-white badge bg-${isAadharUploaded === 'pending' ? 'warning' : 'success'}`}  > {isAadharUploaded.toUpperCase()} <i className={`typcn typcn-${isAadharUploaded === 'pending' ? 'warning' : 'input-checked'}`}></i> </span></div>
                )
            }
        },
        {
            header : 'Aadhar Image',
            Cell : (data) => {
                const {aadhar} = data.row.original;
                
                return (

                    <div>
                        {
                            aadhar != undefined ? <>
                                <a  href={aadhar.frontImageUri} target="_blank">Front View<i className="typcn typcn-export"></i> </a>  
                                <button className="mt-1 btn btn-xs btn-primary" onClick={() => downloadFile(aadhar.frontImageUri)}><i className="typcn typcn-download"></i></button>
                                <br/>
                                <a className="mt-1" href={aadhar.backImageUri} target="_blank">Back View<i className="typcn typcn-export"></i></a>
                                <button className="mt-1 btn btn-xs btn-primary" onClick={() => downloadFile(aadhar.backImageUri)}><i className="typcn typcn-download"></i></button>
                                <br/>
                                {/* <button className="mt-1 btn btn-xs btn-info" onClick={() =>{ setCreateModalOpen(true); setType('aadhar card'); setPassedData(data.row.original);}}> <i className="typcn typcn-edit"></i></button> &nbsp; */}
                                <hr/>
                                 
                                {
                                    userType!='viewer' ? <button className="mt-1 btn btn-xs btn-danger" onClick={() => handleDeletion(aadhar._id)} disabled={disabledIds.includes(aadhar._id) === true}>  {disabledIds.includes(aadhar._id) === false ? <> <i className="typcn typcn-trash btn-icon-append"></i></> : <>Deleting...</>} </button> : null
                                }
                                
                            </> : 'NA'
                        }
                        
                    </div>
                )
            }
        },

        {
            accessorKey : 'isPanUploaded',
            header : 'PAN Status',
            Cell : (data) => {
                const {isPanUploaded} = data.row.original;
                
                return (
                    <div><span className={`text-white fw-bold badge bg-${isPanUploaded === 'pending' ? 'warning' : 'success'}`}  > {isPanUploaded.toUpperCase()} <i className={`typcn typcn-${isPanUploaded === 'pending' ? 'warning' : 'input-checked'}`}></i> </span></div>
                )
            }
        },
        {
            header : 'Pan Card Image',
            Cell : (data) => {
                const { pan } = data.row.original;
                
                return (

                    <div>
                        {
                            pan != undefined ? <>
                                <a  href={pan.uri} target="_blank">View <i className="typcn typcn-export"></i></a> <br/>                              
                                <hr/>
                                <button className="mt-1 btn btn-sm btn-primary" onClick={() => downloadFile(pan.uri)}><i className="typcn typcn-download"></i></button> &nbsp;
                                
                                {
                                    userType!='viewer' ? <><button className="mt-1 btn btn-sm btn-danger" onClick={() => handleDeletion(pan._id)} disabled={disabledIds.includes(pan._id) === true}> {disabledIds.includes(pan._id) === false ? <> <i className="typcn typcn-trash btn-icon-append"></i></> : <>Deleting...</>} </button></> : null
                                }
                                
                            </> : 'NA'
                        }
                        
                    </div>
                )
            }
        },
        {
            accessorKey : 'isAgreementSigned',
            header : 'Agreement Status',
            Cell : (data) => {
                const {isAgreementSigned} = data.row.original;
                
                return (
                    <div><span className={`text-white fw-bold badge bg-${isAgreementSigned === 'pending' ? 'warning' : 'success'}`}  > {isAgreementSigned.toUpperCase()} <i className={`typcn typcn-${isAgreementSigned === 'pending' ? 'warning' : 'input-checked'}`}></i> </span></div>
                )
            }
        },
        {
            accessorKey : 'isBankPassbookUploaded',
            header : 'Bank Details Status',
            Cell : (data) => {
                const {isBankPassbookUploaded} = data.row.original;
                
                return (
                    <div>
                        <span className={`text-white fw-bold badge bg-${isBankPassbookUploaded === 'pending' ? 'warning' : 'success'}`}  > {isBankPassbookUploaded.toUpperCase()} <i className={`typcn typcn-${isBankPassbookUploaded === 'pending' ? 'warning' : 'input-checked'}`}></i> </span></div>
                )
            }
        },
        {
            header : 'Bank Image',
            Cell : (data) => {
                const { bank } = data.row.original;
                
                return (

                    <div>
                        {
                            bank != undefined ? <>
                                <a  href={bank.uri} target="_blank">View Passbook <i className="typcn typcn-export"></i></a><br/> 
                                <hr/>                             
                                <button className="mt-1 btn btn-sm btn-primary" onClick={() => downloadFile(bank.uri)}>  <i className="typcn typcn-download"></i></button> &nbsp;
                                {
                                    userType!='viewer' ? <>
                                    <button className="mt-1 btn btn-sm btn-danger" disabled={disabledIds.includes(bank._id) === true} onClick={() => handleDeletion(bank._id)}> {disabledIds.includes(bank._id) === false ? <> <i className="typcn typcn-trash btn-icon-append"></i></> : <>Deleting...</>}</button>
                                    </> : ''
                                }
                            </> : 'NA'
                        }
                        
                    </div>
                )
            }
        },
        {
            accessorKey : 'block',
            header : 'Block',
            Cell : (data) => {
                const {block} = data.row.original;
                
                return (
                    <div>{block.toUpperCase()}</div>
                )
            }
        },

        {
            accessorKey : 'tehsil',
            header : 'Tehsil',
            Cell : (data) => {
                const {tehsil} = data.row.original;
                
                return (
                    <div>{tehsil.toUpperCase()}</div>
                )
            }
        },
        {
            accessorKey : 'village',
            header : 'Village',
            Cell : (data) => {
                const {village} = data.row.original;
                
                return (
                    <div>{village.toUpperCase()}</div>
                )
            }
        },
        {
            accessorKey : 'district',
            header : 'District',
            Cell : (data) => {
                const {district} = data.row.original;
                
                return (
                    <div>{district.toUpperCase()}</div>
                )
            }
        },

       
    ];
    // const csvUploadHandler = (event) => {
    //     setFileUploadingErrors([]);
    //     const files = event.target.files[0];
    //     if(files){
    //         if(isFileCSV(files) === false){
    //             return;
    //         }
    //         let formData = new FormData();
    //         formData.append('file', files);
    //         setDisabled(true);
    //         uploadDocumentsByCSV(formData).then((response) => {
                
    //             setFlag(!flag);
    //             setDisabled(false);
    //             if(response.result ==='success'){
    //                 NotificationManager.success("CSV File uploaded successfully.");
    //                 setFileUploadingErrors(response.errors);
    //             }
    //         }).catch((error) => {
    //             setDisabled(false);
    //             NotificationManager.error("Unable to Process CSV file. Please check the headers.");
    //         })
            
    //         event.target.value = "";
    //     }
        
    // }

    const handleExportZip = async (rows) => {
       
        let files = [];
        for(const row of data){
            const {farmername, block, tehsil, village, district, aadhar, pan, bank } = row;
        
            let folderName = (`${farmername}_${block}_${tehsil}_${village}_${district}`).toUpperCase();

            if(aadhar){
                files.push({
                    url : aadhar.frontImageUri,
                    folder : `${folderName}/AADHAR`,
                    filename : `AADHAR_FRONT_IMG.jpg`
                });
                files.push({
                    url : aadhar.backImageUri,
                    folder : `${folderName}/AADHAR`,
                    filename : `AADHAR_BACK_IMG.jpg`
                })
            }

            if(pan){
                files.push({
                    url : pan.uri,
                    folder : `${folderName}/PAN`,
                    filename : `PAN_CARD.jpg`
                });
            }

            if(bank){
                files.push({
                    url : bank.uri,
                    folder : `${folderName}/BANK`,
                    filename : `BANK_PASSBOOK.jpg`
                });
            }

        }
        await handleDownloadZip(files, 'Documents');
    }

    const handleExportData = async() => {
        try {
            const export_url = new URL(`${url_}/export-document`);
            for(let item of columnFilters){
                export_url.searchParams.set(item.id, item.value)
            }
            const blob = await exportCSV(export_url.href);
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Documents.csv');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
        console.error('Error downloading CSV:', error);
        }
    };

    useEffect(() => {
        (async() => {
            const url = new URL(`${url_}/manage-document`);
            url.searchParams.set('page', pagination.pageIndex + 1);
            url.searchParams.set('limit', pagination.pageSize);

            for(let item of columnFilters){
                url.searchParams.set(item.id, item.value)
            }
            if(globalFilter.length > 0) {
                url.searchParams.set('farmername', globalFilter)
            }
            setIsRefetching(true);
            let response = await getTableData(url.href);
            setIsRefetching(false);
            let data = response.data;
            setData(data);
            setRowCount(response.recordCount);
        })();
        
        
    },[columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        flag 
    ]);

    if(data===undefined){
        return <Spinners1/>
    }
    
    return (
        <div>
            <CSVErrorMessages errors={fileUploadingErrors}/>
            
            <MaterialReactTable
                columns={columns}
                data={data}
                renderTopToolbarCustomActions={({rows}) => 
                    (
                        <div>

                            {/* <button className='btn btn-sm btn-primary btn-icon-text'
                                onClick={handleExportData}><i className='typcn typcn-download btn-icon-prepend'></i> <span>Export CSV</span>  
                            </button> &nbsp; */}
                            {/* Custom bootstrap upload file */}
                            {/* <button className='btn btn-sm btn-primary btn-icon-text'
                                disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}>
                                <i className='typcn typcn-download btn-icon-prepend'></i> <span>Export selected rows</span>  
                            </button> &nbsp; */}
                            {/* {
                                data.length !=0 ? 
                                <><button
                                    className='btn btn-sm btn-primary btn-icon-prepend btn-icon-text'
                                    onClick={() => handleExportZip()}
                                    >
                                    Export ZIP FILE <i className='typcn typcn-download'></i>
                                </button> &nbsp;
                                
                                </> : ''
                            } */}
                        </div>
                    )
                }
                manualPagination={true}
                onPaginationChange={setPagination}
                manualFiltering = {true}
                onColumnFiltersChange={setColumnFilters}
                
                state={{
                    pagination,
                    showProgressBars: isRefetching,
                }}
                rowCount={rowCount}
            />

            
        </div>
    );
}

export default DocumentTable;