import { useEffect, useState } from "react";
import { LayersControl, MapContainer, TileLayer,  Marker, Popup, Polygon } from "react-leaflet";
import mapicon from '../../../assets/img/mapicon2.svg';
import L from 'leaflet';
import { useParams } from "react-router-dom";
import { getPolygonById } from "../../../APIs/apiCalls";
import { NotificationManager } from "react-notifications";
const customIcon = new L.Icon({
    // https://i.pinimg.com/564x/a9/84/aa/a984aa49d768a03df577acd8094d6b63.jpg
    iconUrl: mapicon, // Replace with the path to your custom icon image
    iconSize: [52, 52], // Set the size of the icon
    iconAnchor: [16, 32], // Set the anchor point for the icon
  });
const polygons = 
    [
        [29.62250160118397, 75.80990214966735], [29.6219486189872, 75.8099119409152], [29.62141119577472, 75.8099141620118], [29.62142308836304, 75.81040083843], [29.62139439846284, 75.81158602331503 ],
        [29.62138866988784, 75.81268647482884], [29.62139274360502, 75.81334319962724], [29.62194417849476, 75.81334118603756], [29.62248269840704, 75.81335628021004], [29.62250443051624, 75.81107134204967],
        [29.62251558374347, 75.81061408736868],  [29.62250160118397, 75.80990214966735]
    ];

const Map = () => {
    const {id, index} = useParams();
    const [location, setLocation] = useState({});
    const [data, setData] = useState(null);
    const [pointerLocation, setPointerLocation] = useState([]);
    
    const fetchData = async() => {
        getPolygonById(id).then((response) => {
            // console.log(response);
            setData(response.data);
            // console.log(response.data.polygon[index].polygon);
            if(response.data.polygon && response.data.polygon[index].points.length>0){
                setPointerLocation(response.data.polygon[index].points[0][0])
            }else{
                NotificationManager.error("Your KML File is empty")
            }
        })
    }
    useEffect(() => {
        fetchData();
            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    // console.log('====================================');
                    // console.log(latitude, longitude);
                    // console.log('====================================');
                    setLocation({ 
                        lat : latitude, 
                        lng : longitude 
                    });
                },
                (error) => {
                    NotificationManager.error('User Denied Location Access', 'Error');
                    console.error('Error getting location:', error);
                }
                );
            } else {
                NotificationManager.error('Geolocation is not available in this browser', 'Error');
                console.error('Geolocation is not available in this browser.');
            }
    },[]);

    if(typeof location === null){
        return <>Loading...</>
    }
    return (
        <div>
            {/* {
                data.polygon[index]?.polygon.map((item, index_) => <>{index_ + 1}. {item.area} acres.</>)
            } */}
            {
                location!=null && data!=null && pointerLocation?.length>0 ? 
                <>
                
                    <MapContainer
                    zoom={16}
                    center={pointerLocation}
                    style={{ width: '100%', height: '100vh' }}
                    >

                            <LayersControl position="topright">
                                <LayersControl.BaseLayer name="Street Map" >
                                    <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                </LayersControl.BaseLayer>
                                <LayersControl.BaseLayer name="Satellite Map" checked>
                                    <TileLayer
                                        attribution='&copy; <a href="http://mapbox.com/copyright">Mapbox</a>'
                                        url='http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}'
                                        subdomains={['mt1','mt2','mt3']}
                                    />
                                </LayersControl.BaseLayer>
                            </LayersControl>
                                    {
                                        data.polygon[index]?.polygon.map((item, index_) => 
                                            <Polygon key={index_} positions={item.points} pathOptions={{ color: 'green', fillColor: 'yellow', fillOpacity: 0.8, weight: 1.5 }}>
                                                <Popup>
                                                    <p><b>Farmer ID </b>: {data.farmer_ID.toUpperCase()}</p>
                                                    <p><b>Farmer Name </b>: {data.farmername.toUpperCase()}</p>
                                                    <p><b>Village</b> : {data.village.toUpperCase()}</p>
                                                    <p><b>Tehsil</b> : {data.tehsil.toUpperCase()}</p>
                                                    <p><b>Area</b> : {(item.area * 0.00024711).toFixed(2)} Acres</p>
                                                </Popup>
                                            </Polygon>
                                        )
                                    }
                            
                              
                    </MapContainer> 
                </> : ''
            }
        </div>
    );
}

export default Map;