import MaterialReactTable from "material-react-table";
import { useEffect } from "react";
import { useState } from "react";
import { getFeildTeamRecords, getLogRecords, getTableData } from "../../APIs/apiCalls";
import Spinners1 from "../../Components/Spinners/Spinners1";
import { fetchUrl } from "../../APIs/server";

const url_ = fetchUrl;

const TrackingTable = () => {
    const [data, setData] = useState(undefined);
    const [user, setUser] = useState([]);
    const sections = ["Polygon Mapping", "Land Records", "Farmer", "Crop", "Aeration"];
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
   
    //table state
    const [columnFilters, setColumnFilters] = useState([]);

    const columns = [
        {
            accessorKey: 'name',
            header: 'Name',
            filterVariant : 'select',
            filterSelectOptions : user,
            Cell : ({cell}) => {
                const {original} = cell.row;
                return (
                    <>{original.name.toUpperCase()} ({original.type})</>
                );
            }
        },
        {
            accessorKey : 'operation',
            header  : 'Operation',
            Cell : ({cell}) => {
                return <>{cell.getValue().toUpperCase()}</>
            },
            filterVariant : 'select',
            filterSelectOptions : ['add', 'view', 'update', 'delete']
        },
        {
            accessorKey : 'section',
            header  : 'Section',
            Cell : ({cell}) => {
                return <>{cell.getValue().toUpperCase()}</>
            },
            filterVariant : 'select',
            filterSelectOptions : sections
        },
        {
            header: 'Description',
            Cell : ({cell}) => {
                const {original} = cell.row;
                return (
                    <>{ original.comments != undefined ? original.comments : `${ original.name[0].toUpperCase()+original.name.slice(1) } had ${original.operation}ed the ${original.section} section.`}</>
                );
            },
        },
        {
            header: 'Date',
            Cell : ({cell}) => {
                const {original} = cell.row;
                return (
                    <>{new Date(original.createdAt).toDateString()}</>
                );
            }
        },
        {
            header: 'Time',
            Cell : ({cell}) => {
                const {original} = cell.row;
                return (
                    <>{new Date(original.createdAt).toLocaleTimeString('en-US')}</>
                );
            }
        },
        {
            accessorKey : 'farmername',
            header  : 'Farmer Name',
            Cell : ({cell}) => {
                const {original} = cell.row;
                return <><a  style={{color : "blue", textDecoration : 'underline'}} target='_blank' href={`/#/farmer-profile/${original?.farmer?._id}`}>{original.farmer?.farmername.toUpperCase()}</a></>
            },
        },
        {
            accessorKey : 'guardian',
            header  : 'Father Name',
            Cell : ({cell}) => {
                const {original} = cell.row;
                return <>{original.farmer?.guardian.toUpperCase()}</>
            },
        },
        {
            accessorKey : 'village',
            header  : 'Village',
            Cell : ({cell}) => {
                const {original} = cell.row;
                return <>{original.farmer?.village.toUpperCase()}</>
            },
        }
    ];

    useEffect(() => {
        (async ()=>{
            const url = new URL(`${url_}/logs`)
            url.searchParams.set('page', pagination.pageIndex + 1);
            url.searchParams.set('limit', pagination.pageSize);
            
            for(let item of columnFilters){
                url.searchParams.set(item.id, item.value)
            }
    
           
            setIsRefetching(true)
            let response = await getTableData(url);
            console.log(response)
            setIsRefetching(false)
            let data = response.data[0].data;

            let metadata = response.data[0].metadata;
            if(metadata[0]){
                setRowCount(metadata[0].total);
            }else{
                setRowCount(0);
            }
            
            setData(data);

            const fieldTeamRecords = (await getFeildTeamRecords()).data;
            let arr = [];
            for(let record of fieldTeamRecords){
                let str = record.firstname.trim() + ' ' +  record.lastname.trim();
                arr.push(str);
            }
            arr.push('admin admin');
            setUser(arr);
        })();
    },[
        columnFilters,
        pagination.pageIndex,
        pagination.pageSize
    ]);
    if(data === undefined){
        return <Spinners1/>
    }
    return(
    <div>
        <MaterialReactTable
            columns={columns}
            data = {data}
            enableGlobalFilter={false}
            muiTableProps={{
                sx: {
                    border: '1px solid rgba(81, 81, 81, 1)',
                },
            }}
            muiTableHeadCellProps={{
                sx: {
                    border: '1px solid rgba(81, 81, 81, 1)',
                },
            }}
            muiTableBodyCellProps={{
                sx: {
                    border: '1px solid rgba(81, 81, 81, 1)',
                },
            }}

            manualPagination={true}
            onPaginationChange={setPagination}
            manualFiltering = {true}
            onColumnFiltersChange={setColumnFilters}
            
            state={{
                pagination,
                showProgressBars: isRefetching,
            }}
            rowCount={rowCount}
            />   
    </div>
    )
}

export default TrackingTable;