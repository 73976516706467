import styles from '../Documents.module.css';
import SignaturePad from 'react-signature-canvas';
const ESignature = (props) => {
    
    let sigPad = {};
    const clear = () => {
        sigPad.clear();
        props.signatureHandler('');
    }
    const trim = () => {
        const uri = sigPad.getTrimmedCanvas().toDataURL('image/png');
        props.signatureHandler(uri);
    }
    return (
        <div id='esignature'>
            <h6>Please Sign below to accept the Agreement</h6>
            <div className={styles.signatureArea}>
                <SignaturePad canvasProps={{className: styles.sigPad}}
                        ref={(ref) => { sigPad = ref }} />
            </div>
            <div>
                <button className='btn-xs btn-secondary' type='button' style={{width : '50%', borderRadius : '0px'}} onClick={trim}>Confirm</button>
                <button className='btn-xs btn-warning' type='button' style={{width : '50%',  borderRadius : '0px'}} onClick={clear}>Clear</button>
            </div>
        </div>
    );
}

export default ESignature;