import { useState } from 'react';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import styles from '../Documents.module.css';
import ESignature from './ESignature';
import { addDocument, updateFarmer } from '../../../APIs/apiCalls';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFContent from './PDFContent';
import moment from 'moment';
import { maskAadharNumber } from '../../../Utils/commonFunctions';


const FarmerAgreement = (props) => {
    console.log(props.farmerDetails);
    
    const [isChecked, setIsChecked] = useState(false);
    const [signatureURI , setSignatureURI] = useState('');
    const [error, setError] = useState('');
    const [disableDownload, setDisableDownload] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [method, setMethod] = useState('');
    const [date, setDate] = useState('');


    const saveFarmersInformation = (entry) => {
        setDisabled(true);

        updateFarmer(entry._id, entry).then(async (response)=>{
            // console.log(response);
            setDisabled(false);

            if(response.result === 'success'){
                NotificationManager.success('Thank you', 'Agreement Signed Successfully', 2000);
                props.reset();
                setDisableDownload(false);
            }else{
                NotificationManager.error('Error', response.error, 2000);
            }
        })
    }


    const registerHandler = () =>{
        // console.log(isChecked);
        if(isChecked === true || signatureURI!=''){
            setError('');
            const entry = {...props.farmerDetails, signature : signatureURI, agreementDate : date, isAgreementSigned : "success", agreementMethod : method, recentDocument : (new Date()).toISOString()};
            saveFarmersInformation(entry);
        }else{
            setError('Please accept the terms and conditions or sign manually.');
            NotificationManager.error('Please accept the terms and conditions or sign manually.');
        }
    }



    const signatureHandler = (value) => {
        if(value!=""){
            setError('');
        }
        setSignatureURI(value);
    }

    

    return (
        <div>
            <h5 className='mb-4'>Farmer Agreement</h5>
            <div className='row'>
                <div className="col-md-4 px-0">
                    <label className="col-form-label"><i className='typcn typcn-warning'></i> Select Date, for agreement generation.</label>
                    <input className='form-control' 
                    type='date' value={date} 
                    min="2024-01-01"
                    max="2024-05-31"
                    onChange={(event) => setDate(event.target.value)}/>
                </div>
                <div className="col-md-4 ml-2">
                    <label className="col-form-label"><i className='typcn typcn-warning'></i> Select Agreement Accept Method.</label>
                    <select className='form-control' onChange={(event) => { setMethod(event.target.value); setIsChecked(false); setSignatureURI('') }}>
                        <option value=''>Agreement Accept Method</option>
                        <option value='signature'>Use Signature PAD</option>
                        <option value='checkbox'>Check your name</option>
                    </select>
                </div>
            </div>
            
            <br/>
            {
                date!='' && method!='' ? 
                <div className="content-wrapper">
                
                <div className="row">
                    <br/>
                    <p>Note : Read this Agreement and sign in the dialog box.</p> <br/>
                        <div style={{marginBottom : '20px', padding : '20px 30px', border : '0.5px solid black'}}>
                            <div id='hindi-agreement'>
                                <div style={{textAlign : 'center'}}>
                                    <h5><u>कार्बन क्रेडिट उत्पादन और अधिकार संचार समझौता</u></h5>
                                </div>
                            <div>
                                <br/>
                                
                                <br/>
                                <p>
                                'कोशर कार्बन जनरेशन प्रोग्राम' में भाग लेने के लिए कोशर क्लाइमेट इंडिया प्राइवेट लिमिटेड ("कोशर"), भारतरोहण एयरबोर्न
                                इनोवेशंस प्राइवेट लिमिटेड ("भारतरोहण") और व्यक्ति या संगठन ("आप", "किसान" या "उगाने वाला") के बीच 'कोशर कार्बन
                                जनरेशन एंड ट् ांसफर ऑफ़ राइट्स अग्रीमेंट' ('समझौता') को सम्पन्न किया जाता है।
                                </p>
                                <p>
                                जबकि किसान कोशर के द्वारा निर्धारित पात्रता मानदंडों के अधीन अपनी स्वयं की / किराये की गई कृषि भूमि को ' कोशर कार्बन 
                                जनरेशन प्रोग्राम ' के तहत नामांकित कराने में रूचि रखता है । इस समझौते को सभी तीन पक्षों ( " प्रभावी तिथि " ) द्वारा समझौते पर 
                                हस्ताक्षर करने और स्वीकृति देने पर प्रभावी होगा । कोशर और भारतरोहण के बीच 13 फरवरी 2023 को हस्ताक्षरित मेमोरेंडम भी 
                                इस समझौते का हिस्सा माना जाएगा । यह समझौता केवल उन सभी पक्षों द्वारा हस्ताक्षर किए गए एक लिखित दस्तावेज द्वारा 
                                संशोधित या संशोधित किया जा सकता है ।
                                </p>
                            </div>
                            <br/>
                            <div>
                                <h6>व्यक्ति या एकाधिकार ( किसान या उत्पादक ): </h6>

                                <div style={{marginTop : '5px'}}>
                                    <p>नाम : <span style={{textTransform : 'capitalize', fontWeight : 'bold'}}>{props.farmerDetails.farmername}</span> </p> 
                                    <p>आधार कार्ड संख्या : <span  style={{textTransform : 'capitalize', fontWeight : 'bold'}}>{ props.farmerDetails.aadhar ? maskAadharNumber(props.farmerDetails.aadhar.aadharNumber) : 'Not Entered'}  </span></p> 
                                    <p>पता : <span style={{textTransform : 'capitalize', fontWeight : 'bold'}}>{props.farmerDetails.tehsil} </span></p> 
                                    <p>गाँव : <span style={{textTransform : 'capitalize', fontWeight : 'bold'}}> {props.farmerDetails.village}</span> </p>
                                    <p>कार्बन क्रेडिट प्रोजेक्ट डेवलपर : कोशर क्लाइमेट इंडिया प्रा . लि . ( " कोशेर " ) </p>
                                     
                                    <p>पिता का नाम : <span style={{textTransform : 'capitalize', fontWeight : 'bold'}}> {props.farmerDetails.guardian} </span></p>
                                    <p>फोन नंबर : <span style={{textTransform : 'capitalize', fontWeight : 'bold'}}>{props.farmerDetails.mobileNumber} </span></p> 
                                    <p>ब्लॉक : <span style={{textTransform : 'capitalize', fontWeight : 'bold'}}>{props.farmerDetails.block} </span> </p> 
                                    <p>कार्यान्वयन साथी या ग्राउंड एक्सटेंशन साथी : भारतरोहण एयरबोर्न इनोवेशंस प्राइवेट लिमिटेड ( " भारतरोहण " )  </p>
                                </div>
                            </div>
                            <br/>
                            <div>
                                <h6> कवर्ड एकड़ : </h6>
                                <p>कुल जमीन ( एकड़ ) जो किसान के सीधे नियंत्रण में है और कार्बन परियोजना कार्यक्रम के तहत पंजीकृत होने के योग्य है : </p> 
                                <p>स्वामित्व में ( एकड़ ) : <span  style={{textTransform : 'capitalize', fontWeight : 'bold'}}> { props.farmerDetails.land ? `${props.farmerDetails.land.ownedLandArea  } एकड़` : 'Not Entered'} </span></p>
                                <p>खसरा संख्या ( एँ ) : <span  style={{textTransform : 'capitalize', fontWeight : 'bold'}}> {props.farmerDetails.land ? props.farmerDetails.land.ownedLandKhasraNumbers.join(', ') : 'Not Entered'}</span></p> 
                                <p>पट्टे में भूमि ( एकड़ ) : <span style={{textTransform : 'capitalize', fontWeight : 'bold'}}>{ props.farmerDetails.land ? `${props.farmerDetails.land.leasedLandArea} एकड़` : 'Not Entered'}</span></p>
                                <p>खसरा संख्या ( एँ ) : <span  style={{textTransform : 'capitalize', fontWeight : 'bold'}}> {props.farmerDetails.land ? props.farmerDetails.land.leasedLandKhasraNumbers.join(', ') : 'Not Entered'}</span> </p>
                            </div>
                            
                            <br/>
                            
                            <div className={styles.kalavidhi}>
                                <h6>कालावधि</h6> 
                                <p>10 वर्ष , परियोजना की सफलता पर आधारित नवीनीकरण । </p>
                                <h6>दस्तावेज़ की सुरक्षा</h6> 
                                <p>इस अनुबंध की शर्तें भूमि के नियंत्रण में परिवर्तनों को भी जीतेंद्रिय करेंगी और अनुबंध की अवधि के लिए भविष्य में भूमि के नियंत्रण में रहने वाले व्यक्तियों के लिए लागू होगी । </p>
                                <h6>उन्नत कृषि विधियाँ </h6>
                                <ul typeof='disc'>
                                    <li> जल प्रबंधन - धान में लगातार भराई से अल्टरनेट वेटिंग एंड ड्राईइग ( एडब्ल्यूडी ) में बदलाव । </li>
                                    <li> नाइट्रोजन प्रबंधन - नाइट्रोजन उपयोग का अनुकूलन ।</li>
                                    <li> जीरो टिलेज (नो-टिलेज) - पारंपरिक या कम जुताई से जीरो टिलेज की ओर बदलाव। </li>
                                    <li> उन्नत जुताई - पारंपरिक से कम जुताई की ओर बदलाव।</li>
                                    <li> कवर क्रॉपिंग - बिना कवर क्रॉपिंग से कवर क्रॉपिंग की ओर बदलाव। </li>
                                </ul>
                                
                            </div>
                            <br/>
                            <div>
                            <h6>प्रारंभ दिनांक </h6>
                            परियोजना के तहत खेती अभ्यास की शुरुआत की तिथि जून 2023_ है । नीचे हस्ताक्षर करके , आप इस समझौते से 
                            बंधने के लिए सहमत होते हैं , भारतरोहण और कोशर की मदद से कार्बन उत्पादन कार्यक्रम में भाग लेने के लिए सहमत होते हैं ; 
                            और सहमत होते हैं कि आपकी नामांकित भूमि से उत्पन्न सभी कार्बन क्रेडिट को कोशर क्लाइमेट और भारतरोहण के नाम से 
                            पंजीकृत किया जाएगा , और कार्बन क्रेडिट उत्पन्न होते ही कोशर क्लाइमेट और भारतरोहण के पास सभी अधिकार , शीर्षक और 
                            हित होंगे ।
                            </div>
                            <br/>
                            <div >
                            <h6>परियोजना भूमि :</h6> 
                            आपको अपनी भूमि के विशिष्ट खेतों को भारतरोहण और कोशर के साथ नामांकित करने के लिए जिम्मेदार 
                            होना होगा । नामांकन भारतरोहण द्वारा उसकी विवेकाधीनता से स्थापित की जाने वाली पात्रता आवश्यकताओं के अधीन होगा । 
                            इस समझौते का अधिकार आपकी सभी पात्र और नामांकित भूमि पर लागू होता है , जिसमें भविष्य के नामांकन भी शामिल हैं । 
                            </div>
                            <br/>
                            <div >
                            <h6>कार्बन क्रेडिट : </h6>कार्बन क्रेडिट 1 मीट्रिक टन सीओ 2 समतुल्य ( सीओ 2 ई ) से सम्बंधित होते हैं , चाहे वे मिट्टी में संचयित हों या आपकी उन्नत कृषि व्यवस्थाओं ( जैसा कि ऊपर दिए गए हैं ) या अन्य ग्रीनहाउस गैस उत्सर्जनों ( “ जीएचजी " ) से संबंधित न हों जो न 
                            की वातावरण में उत्सर्जित किए गए हों , कोशर कार्बन जनरेशन प्रोग्राम के नियमों का पालन करने पर । 
                            
                            </div>
                            <br/>
                            <div >
                            <h6>कार्बन मानक : </h6>गोल्ड स्टँडर्ड या वेरा सत्यापित कार्बन मानक ( VCS ) या समान प्रतिष्ठित कार्बन मानक ।
                            </div>
                            <br/>
                            <div>
                            <h6>मैथोडोलॉजी :</h6> आपकी नामांकित जमीन से कार्बन क्रेडिट उत्पन्न करने की पद्धति एक मैथोडोलॉजी के अधीन होगी जो कोशेर के 
                            द्वारा एकमात्र विवेक से चुनी जाती है , जो कार्बन क्रेडिट योग्यता , मात्रा और सत्यापन आवश्यकताओं को स्पष्ट करती है और किसी 
                            भी मान्यता प्राप्त कार्बन मानक के तहत सूचीबद्ध होती है ।
                            </div>
                            <br/>
                                <div>
                                <h6>मूल्य निर्धारण और भुगतान : </h6> 
                                जब कार्बन क्रेडिट प्रोग्राम से उत्पन्न कार्बन क्रेडिट थर्ड - पार्टी खरीदार को बेचे जाएं और कोशर 
                                द्वारा भुगतान किया जाए , उस समय भारतरोहण को कार्बन मूल्य के एक अहम हिस्से की मिलेगी । भारतरोहण इस कार्बन फंड का 
                                उपयोग उन्नत किए गए किसानों के लाभार्थ करने के लिए करेगा , जो कि एग्री - परामर्श , मूल्य छूट , नकद भुगतान या इससे मिलती 
                                जुलती चीजों जैसे अतिरिक्त सेवाओं के रूप में हो सकती हैं , जैसे कि भारतरोहण और भागीदार किसान ( ओं ) के बीच सहमति 
                                होगी । 
                                </div>
                                <br/>
                            <div>   
                            आप सहमत होते हैं कि कोशर आपकी भूमि पर कार्बन क्रेडिट या कार्बन गुणवत्ताओं की कोई भी उत्पादन करने वाली एकमात्र 
                            संस्था होगी । यदि आप अपनी कोई भी भूमि का डबल - नामांकन करते हैं , तो कोशर अपने विवेकाधीन में , ( a ) कारण के लिए इस 
                            समझौते को समाप्त कर सकता है या ( b ) आपकी भागीदारी को डबल - नामांकन भूमि के संबंध में समाप्त कर सकता है । इन दोनों 
                            मामलों में , कोशेर आपके प्रति और किसी भी बकाया और / या अविवेकाधीन भुगतान के नुकसान के बिना आपके लिए जिम्मेदार 
                            नहीं होगी । 
                            </div>
                            <br/>
                            <div >   
                            <h6>डेटा : </h6>आपको अपने नामांकित जमीन पर सुधारित कृषि विधियों के रिकॉर्ड और अन्य डेटा , दस्तावेज और जानकारी कोशर के लिए प्रदान करने की आवश्यकता है जो कोशर कार्बन जनरेशन प्रोग्राम के तहत आपकी पात्रता को सत्यापित करने के लिए 
                            आवश्यक हो सकती है । आप कोशर , उसके प्रतिनिधि और तृतीय - पक्ष सत्यापकों को समय - समय पर डेटा इकट्ठा करने के लिए 
                            आपकी जमीन में प्रवेश और पहुँच की अधिकार देते हैं , जो विधि में उल्लिखित तरीकों में मृदा नमूने और अन्य ऐसी प्रोटोकॉल के 
                            माध्यम से हो सकता है । कृपया ध्यान दें , इस समझौते के तहत साझा किए गए सभी डेटा आपके नाम हैं और नामांकित जमीन के 
                            सभी स्वामित्व अधिकार आपके पास रहेंगे । 
                            </div>
                            <br/>
                            <p>समझौता प्रभावी तिथि के रूप में लागू किया जाता है और उस दिन से अंजाम दिया जाता है । </p>
                            <p>
                                मैं इस समझौते में उल्लिखित शर्तों को समझता हूं और मैं इन सभी शर्तों से पूरी तरह सहमत हूं । मुझे इस 
                                समझौते को हस्ताक्षर करते समय कोई बाध्य करने वाला नहीं था और न मेरे साथ कोई भ्रम दिया गया था 
                                और मैंने यह समझौता यहां उल्लिखित शर्तों के अर्थ को पूरी तरह समझकर हस्ताक्षर किया है ।
                                </p>
                                <div style={{textAlign : 'right'}}>
                                    <p>Date/ दिनांक : <u>{moment(date).format('DD-MM-YYYY')} </u></p>
                                    <h6>Signature : </h6>
                                    {
                                        method === 'signature'?  <>{signatureURI!="" ? <img src={signatureURI} height={100} /> : null}</>  : ''
                                    }
                                    {
                                        method === 'checkbox'  ? <p className={styles.cedarvilleCursiveRegular}>{props.farmerDetails.farmername}</p> : ''
                                    }   
                                </div>
                            </div>
                            <br/> <br/>
                            <hr/>
                            <br/> <br/>
                            
                            <div id='english-agreement'>
                                <div style={{textAlign : 'center'}}>
                                    <h5><u>Carbon Credit Generation and Transfer of Rights Agreement</u></h5>
                                </div>
                                <div>
                                    <br/><br/>
                                    <p>
                                    The Kosher Carbon Generation and Transfer of Rights Agreement (“Agreement”) is entered into between Kosher Climate India Pvt. Ltd. (“Kosher”), Bharatrohan Airborne Innovations Pvt. Ltd. (“Bharatrohan”) and the individual or entity (“You”, “Farmer”, or “Grower”) working with Kosher and Bharatrohan to participate in Kosher Carbon Generation Program.
                                    </p>
                                    <p>
                                    Whereas Grower is interested to enroll his/her owned/leased agricultural land(s) under the Kosher Carbon Generation Program, subject to eligibility criteria requirements set out by Kosher at their discretion. This agreement is effective as of when this is signed and accepted by all the three parties (“Effective Date”). The MoU between Kosher and Bharatrohan signed on February 13, 2023, shall be deemed to form part of this Agreement. This Agreement constitutes the entire agreement between the Parties and supersedes all prior agreements relating to the Carbon Credit Generation Agreement. This Agreement may be amended or modified only by a written instrument signed by all the Parties.
                                    </p>
                                </div>
                            <br/>
                            <div>
                                <h6>Individual or Entity (Farmer or Grower): </h6>

                                <div style={{marginTop : '5px'}}>
                                    <p>Name : <span style={{textTransform : 'capitalize', fontWeight : 'bold'}}>{props.farmerDetails.farmername} </span> </p> 
                                    <p>Aadhar Number :  <span style={{textTransform : 'capitalize', fontWeight : 'bold'}}>{ props.farmerDetails.aadhar ? maskAadharNumber(props.farmerDetails.aadhar.aadharNumber) : 'Not Entered'} </span></p> 
                                    <p>Address : <span style={{textTransform : 'capitalize', fontWeight : 'bold'}}>{props.farmerDetails.tehsil} </span></p> 
                                    <p> Village : <span style={{textTransform : 'capitalize', fontWeight : 'bold'}}> {props.farmerDetails.village}</span></p>
                                    <p>Carbon credit Project Developer:  Kosher Climate India Pvt. Ltd. (“Kosher”) </p>
                                    <p>Father's Name : <span style={{textTransform : 'capitalize', fontWeight : 'bold'}}> {props.farmerDetails.guardian} </span> </p>
                                    <p>Mobile No. :  <span style={{textTransform : 'capitalize', fontWeight : 'bold'}}>{props.farmerDetails.mobileNumber} </span> </p> 
                                    <p>Block : <span style={{textTransform : 'capitalize', fontWeight : 'bold'}}>{props.farmerDetails.block} </span> </p> 
                                    <p>Implementation partner or Ground Extension partner:  Bharatrohan Airborne Innovations Pvt. Ltd.
                            (“Bharatrohan”)  </p>
                                </div>
                            </div>
                            <br/>
                            <div>
                                <h6> Covered Acres: </h6>
                                <p>Total Land (acres) which is under direct control of farmer and eligible to be enrolled under the carbon project
                            program:</p> 
                                <p> Owned (acre) :  <span  style={{textTransform : 'capitalize', fontWeight : 'bold'}}>{ props.farmerDetails.land ? `${props.farmerDetails.land.ownedLandArea  } Acres` : 'Not Entered'}</span></p>
                                <p>Khasra Number(s) : <span  style={{textTransform : 'capitalize', fontWeight : 'bold'}}> {props.farmerDetails.land ? props.farmerDetails.land.ownedLandKhasraNumbers.join(', ') : 'Not Entered'}</span> </p> 
                                <p>Land under lease (acre) : <span  style={{textTransform : 'capitalize', fontWeight : 'bold'}}>{ props.farmerDetails.land ? `${props.farmerDetails.land.leasedLandArea} Acres` : 'Not Entered'}</span> </p>
                                <p>Khasra Number(s): <span  style={{textTransform : 'capitalize', fontWeight : 'bold'}}>{props.farmerDetails.land ? props.farmerDetails.land.leasedLandKhasraNumbers.join(', ') : 'Not Entered'}</span> </p>
                            </div>
                            
                            <br/>
                            
                            <div className={styles.kalavidhi}>
                                <h6>Term</h6> 
                                <p>10 years, renewable based on the success of the project.</p>
                                <h6> Survival of the Document</h6> 
                                <p> The terms and conditions of this contract will survive changes to control of the land(s) and will be applicable to those
                            who control the land(s) in future for the duration of the contract term.</p>
                                <h6> Improved Agricultural Practices </h6>
                                <ul typeof='disc'>
                                    <li>  Water Management  -  A shift from continues flooding to Alternate Wetting and Drying (AWD) in Paddy. </li>
                                    <li> Nitrogen Management – Optimization of nitrogen use.</li>
                                    <li> Zero Tillage (No-Till) – A shift from conventional or reduced tillage to zero tillage. </li>
                                    <li>  Improved Tillage – A shift from conventional to reduced tillage.</li>
                                    <li> Cover Cropping – A shift from no cover cropping to cover cropping.</li>
                                </ul>
                                
                            </div>
                            <br/>
                            <div>
                            <h6>Start Date </h6>
                            The start date of the cultivation practice under the project is _____June 2023_.By signing below, you agree to be
                            bound by this Agreement, to participate in the Carbon Generation Program with the help of Bharatrohan and
                            Kosher; and agree that all carbon credits generated from your enrolled land shall be in registered in the name of
                            Kosher Climate and Bharatrohan, and Kosher climate and Bharatrohan shall have all the rights, title and interest to
                            such carbon credits upon generation.
                            </div>
                            <br/>
                            <div >
                            <h6>Project Land: </h6> 
                            You are responsible for enrolling specific fields of Your land with Bharatrohan and Kosher.
                            Enrollment is subject to eligibility requirements as may be established by Bharatrohan in its discretion. This
                            Agreement applies to all Your eligible and enrolled land, including future enrollments.
                            </div>
                            <br/>
                            <div >
                            <h6>Carbon Credits:</h6> Carbon Credits equate to 1 metric ton of CO2 equivalent (CO2e) either sequestered in soil or not
                            emitted to the atmosphere as a result of Your implementation of the Improved Agricultural Practices (as listed
                            above) or other greenhouse gas emissions (“GHGs”) sequestered or avoided, subject to compliance with the rules
                            of Kosher Carbon Generation Program. 
                            
                            </div>
                            <br/>
                            <div >
                            <h6>Carbon Standard:</h6>Gold Standard or Verra Verified Carbon Standard (VCS) or similar recognized carbon standards.
                            </div>
                            <br/>
                            <div>
                            <h6>Methodology :</h6> he generation of Carbon Credits from your enrolled lands will be subject to a methodology listed
                            under any recognized carbon standard and which sets forth Carbon Credit qualification, quantification and
                            verification requirements (“Methodology”) and that is selected and applied by Kosher in its sole discretion.
                            </div>
                            <br/>
                                <div>
                                <h6> Pricing and Payment: </h6> 
                                Bharatrohan shall get a significant percentage of carbon value as and when the carbon
                            credits generated from the program are sold to a third-party buyer and payments are realized by Kosher.
                            Bharatrohan shall utilize this carbon fund for the upliftment of enrolled farmers by multiple means including
                            additional services like Agri-consultancy, price discounts, cash payouts or similar things, as to be agreed between
                            Bharatrohan and the participating farmer(s).
                                </div>
                                <br/>
                            <div>  
                                <h6> Exclusivity : </h6> 
                            You agree that Kosher will be the exclusive entity for the generation of any Carbon Credits or Carbon
                            Attributes on Your Land. In the event You double-enroll any portion of Your land, Kosher may, in its discretion,
                            terminate: (a) this Agreement for cause, or (b) Your participation with respect to any Land that is double-enrolled,
                            in either case, without further liability of Kosher to You, including the forfeiture of any outstanding and/or
                            unvested payments.
                            </div>
                            <br/>
                            <div >   
                            <h6>Data : </h6> You are required to provide Kosher with records of improved agricultural practices on your enrolled land as
                            well as other data, documents and information as required or requested by Kosher to verify Your eligibility under
                            the Kosher Carbon generation program. You grant Kosher, its representatives and third-party verifiers the right to
                            enter and access Your Land from time to time to collect Data, including, without limitation, through soil sampling
                            and other such protocols as mentioned in the methodology. Please note, all data shared under this agreement
                            belongs to you and all ownership rights to enrolled land shall remain with you. 
                            </div>
                            <br/>
                            <p>Agreement is executed and delivered as of the Effective Date:</p>
                            <div className='row'>
                                {/* <div className='col-md-6'>
                                    <b>For BharatRohan: </b>
                                    <p>Signature : </p>
                                    <p>Name : </p>
                                    <p>Title : </p>
                                    <p>Date : </p>

                                </div>
                                <div className='col-md-6'>
                                    <b> For Kosher Climate: </b>
                                    <p>Signature : </p>
                                    <p>Name : </p>
                                    <p>Title : </p>
                                    <p>Date : </p>
                                </div> */}
                            </div>
                            
                                    <div style={{textAlign : 'right'}}>
                                            <p>Date/ दिनांक : <u>{moment(date).format('DD-MM-YYYY')} </u></p>
                                            <h6>Signature : </h6>
                                            {
                                                method === 'signature'?  <>{signatureURI!="" ? <img src={signatureURI} height={100} /> : null}</>  : ''
                                            }
                                            {
                                                method === 'checkbox'  ? <p className={styles.cedarvilleCursiveRegular}>{props.farmerDetails.farmername}</p> : ''
                                            }
                                    </div>
                            </div>
                            
                            
                        </div>
                        
                                <br/>
                                <p className={styles.warningMessage}><u>Note </u> : In this, you can sign the agreement by selecting any one of the method given in the dropdown.</p>
                                <div className='col-md-12 p-0'>
                                    
                                </div>
                                {
                                    method === 'checkbox' ? <div>
                                        <p>Name/ नाम : <u>{props.farmerDetails.farmername} </u></p>
                                        <p>Date/ दिनांक : <u>{moment(date).format('DD-MM-YYYY')} </u></p>
                                        <p><input type='checkbox' onClick={() => setIsChecked(!isChecked)}/>मैं इस समझौते में उल्लिखित शर्तों को समझता हूं और मैं इन सभी शर्तों से पूरी तरह सहमत हूं । मुझे इस 
                                            समझौते को हस्ताक्षर करते समय कोई बाध्य करने वाला नहीं था और न मेरे साथ कोई भ्रम दिया गया था 
                                            और मैंने यह समझौता यहां उल्लिखित शर्तों के अर्थ को पूरी तरह समझकर हस्ताक्षर किया है ।</p>
                                    </div>  : ''
                                }

                                {
                                    method === 'signature' ?  <ESignature  signatureHandler = {signatureHandler}/> : ''
                                }
                                
                    <br/>
        </div>
            <p style={{color : 'red'}}>{ error!='' ? error : ''}</p>
            <div className='row mt-1'>
                <button type="reset" className="btn btn-bluish-green btn-icon-text" style={{marginRight : '5px'}} onClick={registerHandler} disabled={disabled}>
                    <i className="typcn typcn-warning btn-icon-prepend"></i>
                    Save
                </button>

                <PDFDownloadLink document={<PDFContent farmerDetails={props.farmerDetails} signatureURI = {signatureURI} />} fileName={`${props.farmerDetails.farmername}_${props.farmerDetails.village}`}>
                    {
                        ({ blob, url, loading, error }) => loading ? 'Loading document...' : <button type="reset" className="btn btn-secondary btn-icon-text" disabled={disableDownload}><i className="typcn typcn-download btn-icon-prepend"></i>Download Agreement</button>
                    }
                </PDFDownloadLink>
                
            </div>

            </div> : null
            }
            <NotificationContainer/>
        </div>
    );
}
export default FarmerAgreement;