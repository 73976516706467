import React from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'

const AerationPieChart = ({heading, number, total}) => {
  return (
    <div >
            <div className='dashboard-heading'>
                  <h5 className="m-0 text-white">{heading}</h5>
            </div>
            <p className=" pt-3 pt-3 text-center mb-0" style={{color : "grey",padding : '0px 15px', fontSize : '14px', fontWeight : 'bolder', backgroundColor : 'ghostwhite'}}>Progress Bar Shows the {heading}</p>
            <div className="dashboard-chart" style={{height : '300px'}}>
            <CircularProgressbar
              value={(number*100)/total}
              text={`${((number*100)/total).toFixed(0)}%`}
              className="mt-3"
              background
              backgroundPadding={7}
              strokeWidth={5}
            
              styles={buildStyles({
                backgroundColor: "#769f34",
                textColor: "#fff",
                pathColor: "#fff",
                trailColor: "transparent"
              })}
            />
            </div>
            {/* <PercentStats /> */}
          </div>
  )
}

export default AerationPieChart