import moment from "moment";

const AerationDetails = ({data}) => {
    const { pipesRequired } = data;
    const array = pipesRequired!=undefined ? Array(pipesRequired).fill(0) : [];
    
    if(array.length === 0) {
        return <>
        <p className="mt-1 text-danger" style={{fontStyle : 'italic'}}><i className="typcn typcn-warning"></i> <i className="typcn typcn-warning"></i> <i className="typcn typcn-warning"></i> Number of pipes are not selected. </p>
        <p className="mt-1 text-info" style={{fontStyle : 'italic'}}>Kindly select and upload pictures of Pipe Installments, First Dry and Second Dry.</p>
        </>
    }
    return (
        <div className="card p-3">
            {
                array.map((_, index) => 
                <div className="mt-2 p-2" style={{backgroundColor : 'ghostwhite', border : '0.01px solid grey' }}>
                <div>
                    <h6>
                        <b>#Pipe-{index + 1}</b>
                    </h6>
                    
                </div>

                {/* <div class="collapse" id={`${data._id}-${index}`}>
                    <div className="d-flex justify-content-between">
                        <p><u>Pipe Installation</u> - {data.pipeInstallments[index].date_!='' ? <span className="text-success">SUCCESS</span> : <span className="text-danger">PENDING</span>} </p>
                    </div>
                    {
                        data.pipeInstallments[index].date_!='' ? 
                        <>
                        <p>Date : {data.pipeInstallments[index].date_}</p>
                        <p>File : </p>
                        <img src={data.pipeInstallments[index].file} height={100} />
                        </> : null
                    }
                </div> */}

                <div>
                    <div className="d-flex justify-content-between">
                    <p><u>Pipe Installation</u> - {data.pipeInstallments[index].date_!='' ? <span className="text-success">SUCCESS</span> : <span className="text-danger">PENDING</span>} </p>
                    
                    </div>
                    
                    {
                        data.pipeInstallments[index].date_!='' ? 
                        <>
                        <p>Date : {moment(data.pipeInstallments[index].date_).format('DD-MM-YYYY')}</p>
                        <p>Photo of Field : </p>
                        <img src={data.pipeInstallments[index].file} height={100} />
                        <p>Photo of Pipe : </p>
                        <img src={data.pipeInstallments[index].file2} height={100} />
                        </> : null
                    }
                    
                </div>

                <div>
                    <div className="d-flex justify-content-between">
                        <p><u>First Dry</u> - {data.firstDry[index].date_!='' ? <span className="text-success">SUCCESS</span> : <span className="text-danger">PENDING</span>}</p>
                    
                    </div>
                    
                    {
                        data.firstDry[index].date_!='' ? 
                        <>
                        <p>Date : {moment(data.firstDry[index].date_).format('DD-MM-YYYY')}</p>
                        <p>Photo of Field : </p>
                        <img src={data.firstDry[index].file1} height={100} />
                        <p>Photo of Pipe : </p>
                        <img src={data.firstDry[index].file2} height={100} />
                        </> : null
                    }
                    
                </div>

                <div>
                    <div className="d-flex justify-content-between">
                    <p><u>Second Dry </u> - {data.secondDry[index].date_!='' ? <span className="text-success">SUCCESS</span> : <span className="text-danger">PENDING</span>} </p>
                    
                    </div>
                    
                    {
                        data.secondDry[index].date_!='' ? 
                        <>
                        <p>Date : {moment(data.secondDry[index].date_).format('DD-MM-YYYY')}</p>
                        <p>Photo of Field : </p>
                        <img src={data.firstDry[index].file1} height={100} />
                        <p>Photo of Pipe : </p>
                        <img src={data.firstDry[index].file2} height={100} />
                        </> : null
                    }
                    
                </div>
                
                </div>
                )
            }
        </div>
    );
}

export default AerationDetails;