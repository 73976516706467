import { useFormik } from "formik";
import { compressImage, generateDataURIFromImage, getTodaysDate } from "../../../../Utils/commonFunctions";
import Webcam from "react-webcam";
import validate1 from "./validate1";
import  styles from './../AddAerationDetails.module.css'
import CompressionLoader from "../../../../Components/CompressionLoader/CompressionLoader";
import SubmitButton from "../../../../Components/Buttons/SubmitButton";
import ImagePreview from "./ImagePreview";
const { useRef, useState, useEffect } = require("react");
const acceptedMimeTypes = ['image/png', 'image/jpeg', 'image/jpg'];

const videoConstraints = {
    facingMode: { exact: process.env.REACT_APP_CAMERA_FACING_MODE }
};
  
const DryForm = ({value , index, submit, operation}) => {
    const input = useRef();
    const todaysDate = getTodaysDate();
    const [disabled, setDisabled] = useState(false);
    const [isImageCompressing1, setIsImageCompressing1] = useState(false);
    const [isImageCompressing2, setIsImageCompressing2] = useState(false);
    const [fileChanged1, setFileChanged1] = useState(false);
    const [fileChanged2, setFileChanged2] = useState(false);
    const [filePreviewUrl, setFilePreviewUrl] = useState('');
    const [open, setOpen] = useState('');
    const formik = useFormik({
        initialValues : {
            date_ : todaysDate,
            file1 : '',
            open1 : '',
            type1 : 'camera',
            fileObject1 : '',
            file2 : '',
            open2 : '',
            type2 : 'camera',
            fileObject2 : '',
        },
        validate : validate1,
        onSubmit : (values) => {
            // console.log(values);
            setDisabled(true);
            submit(values, index);
            
        } 
    });
    const maxFileSize = process.env.REACT_APP_FILE_MAX_SIZE; //in kbs
    const fileChangeHandler1 = async (event) => {
        
        const file = event.target.files[0];

        if(file){
            setFileChanged1(true);
            //convert size(bytes) to kbs.
            const {size, type} = file;
            const fileSizeInKB = size / 1024;
            if(acceptedMimeTypes.includes(type) === false){
                formik.setFieldError('file1',`File extension should be pdf, jpg or png.`);
                // console.log(formik.errors);
            }else if(fileSizeInKB > maxFileSize){
                formik.setFieldError('file1',`Maximum size limit is ${maxFileSize/1024}mb`);
            }else{
                //sets the loader in loading state.
                setIsImageCompressing1(true);

                //compress the file before generating datauri.
                const compressedImage = await compressImage(file);

                generateDataURIFromImage(compressedImage, (uri) => {
                    formik.setFieldValue('file1', uri);
                    formik.setFieldValue('fileObject1', compressedImage);

                    //removes loading state.
                    setIsImageCompressing1(false);
                });
            }
            
        }
    }
    const fileChangeHandler2 = async (event) => {
        
        const file = event.target.files[0];

        if(file){
            setFileChanged2(true);
            //convert size(bytes) to kbs.
            const {size, type} = file;
            const fileSizeInKB = size / 1024;
            if(acceptedMimeTypes.includes(type) === false){
                formik.setFieldError('file2',`File extension should be pdf, jpg or png.`);
                // console.log(formik.errors);
            }else if(fileSizeInKB > maxFileSize){
                formik.setFieldError('file2',`Maximum size limit is ${maxFileSize/1024}mb`);
            }else{
                //sets the loader in loading state.
                setIsImageCompressing2(true);

                //compress the file before generating datauri.
                const compressedImage = await compressImage(file);

                generateDataURIFromImage(compressedImage, (uri) => {
                    formik.setFieldValue('file2', uri);
                    formik.setFieldValue('fileObject2', compressedImage);

                    //removes loading state.
                    setIsImageCompressing2(false);
                });
            }
            
        }
    }
    useEffect(() => {
        if(value.date_!=""){
            formik.setValues(value);   
            setFileChanged1(true);
            setFileChanged2(true);   
        }
    },[])
    return (
        <div className="m-2 mt-3 p-3" style={{backgroundColor : 'rgb(244 244 244)',  border : `3px solid ${value.date_ != '' ? 'green' : 'red'}`}}>
        <h6 className="text-danger"><b>Pipe{index + 1}-Field{index + 1} - PENDING </b></h6>
        <form onSubmit={formik.handleSubmit}>
            <div className="row" >
                <div className="col-md-6">
                    <label className="col-form-label">Date of {operation}* </label><br/>
                    <input name="date_" max={todaysDate}
                    className={`form-control ${ formik.touched.date_ === true && formik.errors.date_ ? styles.formInputError : ""}`}
                    id="date_"  type="date" 
                    value={formik.values.date_}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    />
                    {formik.touched.date_ === true && formik.errors.date_ ? <div className={styles.error}>{formik.errors.date_}</div> : ""}
                </div>
                
                    <div className="col-md-12 mt-2">
                    <h6 className="mt-2" style={{textDecoration : 'underline'}}>Photo of the Field :</h6>
                        <label  className="col-form-label">Select Source </label><br/>
                        <label><input type="radio" name="type1" value='file' checked={formik.values.type1 === 'file'} onChange={formik.handleChange} onBlur={formik.handleBlur}/> File</label><br/>
                        <label><input type="radio" name="type1" value='camera' checked={formik.values.type1 === 'camera'} onChange={formik.handleChange} onBlur={formik.handleBlur}/> Camera</label>

                        {formik.touched.type1 === true && formik.errors.type1 ? <div className={styles.error}>{formik.errors.type1}</div> : ""}
                    </div>
                    {
                        formik.values.type1 === 'file' ? 
                        <div className="col-md-6">
                            <p className={styles.warningMessage}>Note <i className="typcn typcn-warning"></i>: </p>
                            <p className={styles.warningMessage}>1. Photo of dried field with pipe, Ensure location details are present in photo while submitting. </p>
                            <p className={styles.warningMessage}>2. Maximum file size limit is {maxFileSize/1024}mb. </p>
                            <label className="col-form-label">Upload* : <span className="text-warning"><i className="typcn typcn-warning"></i>Maximum file size limit is {maxFileSize/1024}mb</span></label><br/>
                            <input 
                            className={`form-control ${ formik.touched.file1 === true && formik.errors.file1 ? styles.formInputError : ""}`}
                            id="file1" type="file" onChange={(event) => fileChangeHandler1(event)} onBlur={formik.handleBlur}/>
                            {formik.touched.file1 === true && formik.errors.file1 ? <div className={styles.error}>{formik.errors.file1}</div> : ""}
                        </div> : 
                        <div className="col-md-6">
                        <p className={styles.warningMessage}>Note <i className="typcn typcn-warning"></i>: </p>
                        <p className={styles.warningMessage}>1. Photo of dried field with pipe, Ensure location details are present in photo while submitting. </p>
                        <p className={styles.warningMessage}>2. Maximum file size limit is {maxFileSize/1024}mb. </p>
                        <p className={styles.warningMessage}>3. For Retake - Click Capture. </p>
                        <label  className="col-form-label">Upload* </label><br/>
                        {
                            formik.values.open1 === true ? 

                            <div>
                                <button className="btn btn-xs btn-danger" type="button" onClick={() => formik.setFieldValue('open1', false)}>Close</button> 
                                <Webcam height={300} width={300} ref={input} videoConstraints={videoConstraints}/>
                                <button className="btn btn-xs btn-success" type="button" onClick={() =>{formik.setFieldValue('file1', input.current.getScreenshot()); setFileChanged1(true)}}>Capture</button>
                            </div> : 
                            <div>
                            <button className="btn btn btn-sm btn-info" type="button" onClick={() => formik.setFieldValue('open1', true)}>Open Camera <i className="typcn typcn-camera"></i></button> 
                            </div>
                        }
                        { formik.touched.file1 === true && formik.errors.file1 ? <div className={styles.error}>{formik.errors.file1}</div> : "" }
                        </div>
                    }

                    { 
                        fileChanged1 == true ? 
                        <div className="col-md-6 mt-2">
                            <p>Preview :</p>
                            {
                                isImageCompressing1 === true ? <CompressionLoader/> : <img className="image-preview-css" src={formik.values.file1} height={150} width={200} onClick={() => {setFilePreviewUrl(formik.values.file1); setOpen(true)}}/>
                            }
                        </div> : ''
                    }
             <br/>
             
                    <div className="col-md-12 mt-2">
                    <h6 className="mt-2"  style={{textDecoration : 'underline'}}>Photo of the Pipe :</h6>
                        <label  className="col-form-label">Select Source </label><br/>
                        <label><input type="radio" name="type2" value='file' checked={formik.values.type2 === 'file'} onChange={formik.handleChange} onBlur={formik.handleBlur}/> File</label><br/>
                        <label><input type="radio" name="type2" value='camera' checked={formik.values.type2 === 'camera'} onChange={formik.handleChange} onBlur={formik.handleBlur}/> Camera</label>

                        {formik.touched.type2 === true && formik.errors.type2 ? <div className={styles.error}>{formik.errors.type2}</div> : ""}
                    </div>

                    {
                        formik.values.type2 === 'file' ? 
                        <div className="col-md-6">
                            <p className={styles.warningMessage}>Note <i className="typcn typcn-warning"></i>: </p>
                            <p className={styles.warningMessage}>1. Zoomed photo of pipe showing the water level, Ensure location details are present in photo while submitting. </p>
                            <p className={styles.warningMessage}>2. Maximum file size limit is {maxFileSize/1024}mb. </p>
                            <label  className="col-form-label">Upload* <span className="text-warning"><i className="typcn typcn-warning"></i>Maximum file size limit is {maxFileSize/1024}mb</span></label><br/>
                            <input 
                            className={`form-control ${ formik.touched.file2 === true && formik.errors.file2 ? styles.formInputError : ""}`}
                            id="file2" type="file" onChange={(event) => fileChangeHandler2(event)} onBlur={formik.handleBlur}/>
                            {formik.touched.file2 === true && formik.errors.file2 ? <div className={styles.error}>{formik.errors.file2}</div> : ""}
                        </div> : 
                        <div className="col-md-6 mt-2">
                        <p className={styles.warningMessage}>Note <i className="typcn typcn-warning"></i>: </p>
                        <p className={styles.warningMessage}>1. Zoomed photo of pipe showing the water level, Ensure location details are present in photo while submitting. </p>
                        <p className={styles.warningMessage}>2. Maximum file size limit is {maxFileSize/1024}mb. </p>
                        <p className={styles.warningMessage}>3. For Retake - Click Capture. </p>
                        <label  className="col-form-label">Upload* </label><br/>
                        {
                            formik.values.open2 === true ? 

                            <div>
                                <button className="btn btn-xs btn-danger" type="button" onClick={() => formik.setFieldValue('open2', false)}>Close</button> 
                                <Webcam height={300} width={300} ref={input}  videoConstraints={videoConstraints}/>
                                <button className="btn btn-xs btn-success" type="button" onClick={() =>{formik.setFieldValue('file2', input.current.getScreenshot()); setFileChanged2(true)}}>Capture</button>
                            </div> : 
                            <div>
                                <button className="btn btn-sm btn-info" type="button" onClick={() => formik.setFieldValue('open2', true)}>Open Camera  <i className="typcn typcn-camera"></i></button> 
                            </div>
                        }
                        { formik.touched.file2 === true && formik.errors.file2 ? <div className={styles.error}>{formik.errors.file2}</div> : "" }
                        </div>
                    }

                    { 
                        fileChanged2 == true ? 
                        <div className="col-md-6 mt-2">
                            <p>Preview :</p>
                            {
                                isImageCompressing2 === true ? <CompressionLoader/> : <img className="image-preview-css" src={formik.values.file2} height={150} width={200} onClick={() => {setFilePreviewUrl(formik.values.file1); setOpen(true)}}/>
                            }
                        </div> : ''
                    }
              
            </div>
            <div className="text-center mt-3">
                <SubmitButton disabled={disabled} />
            </div>
        </form>
        <ImagePreview
            imageUrl={filePreviewUrl}
            open={open}
            onClose={() => setOpen(false)}
        />
        </div>
    );
}

export default DryForm;