import { NotificationManager } from 'react-notifications';
import { downloadFileByUrl, downloadFileInZip, isTokenValid } from '../APIs/apiCalls';
import * as turf from '@turf/turf';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import imageCompression from 'browser-image-compression';
import { geoJSON } from 'leaflet';
const axios = require('axios');
const url = process.env.REACT_APP_API_PROD_URL;



export function dateDifferenceInDays(date1, date2) {
    // Convert the input strings to Date objects
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);
  
    // Calculate the time difference in milliseconds
    const timeDifference = firstDate - secondDate;
  
    // Convert the time difference to days
    const daysDifference = timeDifference / (1000 * 3600 * 24);
  
    return daysDifference; // Return the absolute value
}

//this uri function is for aeration details.
export function generateDataUriFromFileInput(fileInput, callback) {
    if (!fileInput || !fileInput.files || fileInput.files.length === 0) {
        callback(null, 'No file selected.');
        return;
    }

    const file = fileInput.files[0];
    const reader = new FileReader();

    reader.onload = function (event) {
        const dataUri = event.target.result;
        // console.log(dataUri)
        callback(dataUri, null);
    };

    reader.onerror = function (event) {
        callback(null, 'Error reading the file.');
    };

    // Read the selected file as a data URI
    reader.readAsDataURL(file);
}



export function getMimeTypeFromDataUri(url) {
    url = String(url);
    if(url.includes('image/png')===true){
        return 'image/png';
    }else if(url.includes('image/jpeg')===true){
        return 'image/jpeg';
    }else if(url.includes('image/jpg')===true){
        return 'image/jpg';
    }else if(url.includes('application/pdf')===true){
        return 'application/pdf';
    }else{
        return null
    }
}

//this uri function used in farmer onboarding module. here value will be passed as event.target.files[0].

export function generateDataURIFromImage(fileInput, callback){
    const reader = new FileReader();
    
    reader.onloadend = function (event) {
        const dataUri = event.target.result;
       
        callback(dataUri, null);
    };

    reader.onerror = function (event) {
        callback(null, 'Error reading the file.');
    };

    // Read the selected file as a data URI
    reader.readAsDataURL(fileInput);
}

export const calculateAreaAndPerimeter = (polygon) => {
    const points = polygon.toGeoJSON().geometry.coordinates;
    console.log(points);
    var polygon_ = turf.polygon(points); //defines geometry of the coordinates
            
    const area = turf.area(polygon_); //calc area.
            
    const perimeter = turf.length(polygon_); //calc perimeter
    return {area, perimeter};
}


export const polygonsToKML = (polygons, farmer) => {
    const kmlHeader = '<?xml version="1.0" encoding="UTF-8"?>\n' +
        '<kml xmlns="http://www.opengis.net/kml/2.2">\n' +
        '<Document>\n';

    const kmlFooter = '</Document>\n' +
        '</kml>';

    const polygonPlacemarks = polygons.map((polygon, index) => {
        // console.log(polygon.getLatLngs())
        let polygonLatLng = polygon.getLatLngs()[0];
        polygonLatLng.push(polygonLatLng[0]);

        let {area, perimeter} = calculateAreaAndPerimeter(polygon);

        const coordinates = polygonLatLng.map(latlng => `${latlng.lng},${latlng.lat},0`).join(' ');
        
        return `<Placemark>
            <name>Polygon ${index + 1}</name>
            <ExtendedData>
                <Data name="Farmer Name">
                    <value>${farmer.firstname} ${farmer.lastname}</value>
                </Data>
                <Data name="Block">
                    <value>${farmer.block}</value>
                </Data>
                <Data name="Village">
                    <value>${farmer.village}</value>
                </Data>
                <Data name="Tehsil">
                    <value>${farmer.tehsil}</value>
                </Data>
                <Data name="District">
                    <value>${farmer.district}</value>
                </Data>
                <Data name="Area">
                    <value>${(area * 0.00024711).toFixed(2)}(in Acres)</value>
                </Data>
               
            </ExtendedData>
            <Polygon>
                <outerBoundaryIs>
                <LinearRing>
                    <coordinates>${coordinates}</coordinates>
                </LinearRing>
                </outerBoundaryIs>
            </Polygon>
        </Placemark>`;
    });
    
    const kml = kmlHeader + polygonPlacemarks.join('\n') + kmlFooter;

    return kml;
}

function createKML(data, index) {
    // KML header
    const kmlHeader = '<?xml version="1.0" encoding="UTF-8"?>\n' +
        '<kml xmlns="http://www.opengis.net/kml/2.2">\n' +
        '<Document>\n';

    const kmlFooter = '</Document>\n' +
        '</kml>';

    const polygonPlacemarks = data.polygon[index].polygon.map((polygon, index) => {
            
    
            let {area} = polygon;
    
            const coordinates = polygon.points.map(point => `${point[1]},${point[0]},0`).join(' ');
            
            return `<Placemark>
                <name>Polygon ${index + 1}</name>
                <ExtendedData>
                    <Data name="Farmer ID">
                        <value>${data.farmer_ID}</value>
                    </Data>
                    <Data name="Farmer Name">
                        <value>${data.farmername.toUpperCase()}</value>
                    </Data>
                    <Data name="Block">
                        <value>${data.block.toUpperCase()}</value>
                    </Data>
                    <Data name="Village">
                        <value>${data.village.toUpperCase()}</value>
                    </Data>
                    <Data name="Tehsil">
                        <value>${data.tehsil.toUpperCase()}</value>
                    </Data>
                    <Data name="District">
                        <value>${data.district.toUpperCase()}</value>
                    </Data>
                    <Data name="Area">
                        <value>${(area * 0.00024711).toFixed(2)}(in Acres)</value>
                    </Data>
                   
                </ExtendedData>
                <Polygon>
                    <outerBoundaryIs>
                        <LinearRing>
                            <coordinates>${coordinates}</coordinates>
                        </LinearRing>
                    </outerBoundaryIs>
                </Polygon>
            </Placemark>`;
    });
    
  
    const kml = kmlHeader + polygonPlacemarks.join('\n') + kmlFooter;

    return kml;
  }


export const handleKMLDownload = (data, index, fileName = 'drawn-polygon.kml') => {
  
    console.log(data)
    let originalPolygonCoords = data.polygon[index].points;
    if(originalPolygonCoords.length > 0) {
        const kmlContent = createKML(data, index); //it must contain polygon with area&perimeter
    
        const blob = new Blob([kmlContent], { type: 'application/vnd.google-earth.kml+xml' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link); // Append to html page
        link.click(); // Start download
        document.body.removeChild(link); // Clean up
        URL.revokeObjectURL(url); // Release blob URL
    }
  };


export function adjustWidth(isSidebarOpen){
    
    const screenWidth = window.innerWidth;
    if(screenWidth>991){
        if(isSidebarOpen===true){
            
            document.getElementsByClassName('main-panel')[0].style.width = 'calc(100% - 70px - 0.55rem)';
            document.getElementsByClassName('main-panel')[0].style.marginLeft = 'calc(70px + 0.55rem)';
            document.body.classList.add('sidebar-icon-only');
            return false;
        }else{
            document.getElementsByClassName('main-panel')[0].style.width = 'calc(100% - 260px)';
            document.getElementsByClassName('main-panel')[0].style.marginLeft = 'calc(227px + 2rem)';
            document.body.classList.remove('sidebar-icon-only');
            return true;
        }
    }else{
      
        document.getElementsByClassName('main-panel')[0].style.width = '100%';
        if(isSidebarOpen===true){
            return false;
        }else{
            return true;
        }
    }
    
}

export const filterConfig = {
    ignoreCase : true,
    matchFrom: 'start',
};


export const getFormData = (values) => {
    let formData = new FormData();
    for(let key in values){
        formData.append(key, values[key]);
    }
    return formData;
}

export const getTodaysDate = () =>{
    // Get current date
    const currentDate = new Date();

    // Extract year, month, and day
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');

    // Format the date as "yyyy-mm-dd"
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}

export function formatDate(year, month, day) {
    // Create a new date object with the provided year, month, and day
    // Note: Month is 0-based, so subtract 1 to align with JavaScript's Date object
    const date = new Date(year, month - 1, day);

    // Format the date parts to ensure leading zeros if needed
    const yyyy = date.getFullYear(); // Get the full year
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Get month and pad if needed
    const dd = String(date.getDate()).padStart(2, '0'); // Get date and pad if needed

    // Return the formatted string
    return `${yyyy}-${mm}-${dd}`;
}


export const quotes = [
    "Farming the future with technology.",
    "Seeds of innovation grow the future of agriculture.",
    "Tech in the soil, growth in the future.",
    "Smart farming, brighter harvests.",
    "Plants and pixels: cultivating the future.",
    "From plows to pixels: the evolution of agriculture.",
    "Sow the bytes, reap the innovation.",
    "In the fields of progress, technology is the crop.",
    "Agriculture meets innovation in every seed.",
    "Harvesting data, growing success.",
    "Smart tech, brighter agri-future.",
    "Cultivating tomorrow with today's technology.",
    "Sow the tech, reap the harvest.",
    "In the fields of agriculture, technology is the new plow.",
    "Seeds of innovation, fields of prosperity.",
    "Agriculture meets algorithms for a fruitful future.",
    "Planting progress, harvesting success.",
    "Farming the future with bytes and bales.",
    "From plows to pixels, cultivating a tech-savvy harvest.",
    "Smart farming, growing solutions.",
    "In the world of agriculture, technology is the new sunrise."
]

export function getRandomQuote() {
    let n = quotes.length-1;
    let randomIndex = Math.floor(Math.random() * (n + 1));
    return quotes[randomIndex];
}


export const isFileCSV = (file) => {
    if(file.type != 'text/csv'){
        NotificationManager.error('Only CSV files are accepted');
        return false;
    }
    return true;
}

const triggerDownload = (url, fileName = 'default-filename') => {
    // Create an anchor element and append it to the document
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download =  fileName;
    document.body.appendChild(anchor);
    // Trigger the download
    anchor.click();
    // Clean up by removing the anchor element
    document.body.removeChild(anchor);
  };

export const downloadFile = async (url, fileName = 'default-filename') => {
    try {
        // Replace this URL with your endpoint that returns the signed URL
        const response = await downloadFileByUrl({url : url});
        console.log(response);
        // const { signedUrl } = await response.json();

        // Use the signed URL to download the file
        triggerDownload(response.url, fileName)
    } catch (error) {
        console.error('Error downloading file:', error);
    }
}

export function convertCoords(coords) {
    return coords.map(pair => {
        if (Array.isArray(pair[0])) { // Check if this is a deeper array (e.g., polygon)
            return convertCoords(pair); // Recursively convert
        }
        return [pair[1], pair[0]]; // Swap latitude and longitude
    });
}


export  function combineKMLFiles(multipleEntries){
    let kmlFiles = [];
    for(let data of multipleEntries){
        const file = createKML(data);
        kmlFiles.push(file)
    }
    return kmlFiles;
}

//download KML Files as zip.
export async function downloadKMLFilesAsZip(multipleEntries) {
    const zip = new JSZip();
    const combinedKMLFile = combineKMLFiles(multipleEntries);
    // console.log(combinedKMLFile);
    combinedKMLFile.forEach((kml, index) => {
        const filename = `${multipleEntries[index].farmername}_${multipleEntries[index].village}_${multipleEntries[index].block}_${multipleEntries[index].tehsil}_${multipleEntries[index].district}_${index+1}.kml`;
        // console.log(filename);
        zip.file(filename, kml);
    });
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "polygons.zip");
}


//creating placemarks for different polygons.
const createPlacemark = (data) => {
    const polygonPlacemarks = data.polygon.map((polygon, index) => {
            
    
        let {area} = polygon;

        const coordinates = polygon.points.map(point => `${point[1]},${point[0]},0`).join(' ');
        
        return `<Placemark>
            <name>Polygon ${index + 1}</name>
            <ExtendedData>
                <Data name="Farmer Name">
                    <value>${data.farmername}</value>
                </Data>
                <Data name="Block">
                    <value>${data.block}</value>
                </Data>
                <Data name="Village">
                    <value>${data.village}</value>
                </Data>
                <Data name="Tehsil">
                    <value>${data.tehsil}</value>
                </Data>
                <Data name="District">
                    <value>${data.district}</value>
                </Data>
                <Data name="Area">
                    <value>${(area * 0.00024711).toFixed(2)}(in Acres)</value>
                </Data>
               
            </ExtendedData>
            <Polygon>
                <outerBoundaryIs>
                    <LinearRing>
                        <coordinates>${coordinates}</coordinates>
                    </LinearRing>
                </outerBoundaryIs>
            </Polygon>
        </Placemark>`;
});
    return polygonPlacemarks;
}

// download multiple entries into single KML file.
export async function downloadSingleKMLFile(multipleEntries){
    //prepare KML Content...
    const kmlHeader = '<?xml version="1.0" encoding="UTF-8"?>\n' +
        '<kml xmlns="http://www.opengis.net/kml/2.2">\n' +
        '<Document>\n';

    const kmlFooter = '</Document>\n' +
        '</kml>';
    let kmlPlacemarkContent = '';
    for(let data of multipleEntries){
        kmlPlacemarkContent += createPlacemark(data).join('\n');
    }
    let fullKMLContent = kmlHeader + kmlPlacemarkContent + kmlFooter;


    const blob = new Blob([fullKMLContent], { type: 'application/vnd.google-earth.kml+xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = "polygons.kml";
    document.body.appendChild(link); // Append to html page
    link.click(); // Start download
    document.body.removeChild(link); // Clean up
    URL.revokeObjectURL(url); // Release blob URL
}

export const compressImage = async (fileObject) => {
    const options = {
        maxSizeMB: 1, // (Optional) Maximum size of the output file in MBs, adjust as needed
        maxWidthOrHeight: 1920, // (Optional) Compresses the image to this width or height (keeping aspect ratio)
        useWebWorker: true // (Optional) Use a web worker for better performance on large files
    };

    const compressedFile = await imageCompression(fileObject, options);

    return compressedFile;
}

export const getPosition = () => {
    return new Promise((resolve, reject) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true, // Request high accuracy (typically GPS)
            maximumAge: 0 // Do not use cached location
          });
      } else {
        reject(new Error('Geolocation is not available in this browser.'));
      }
    });
};



export const handleDownloadZip = async (files, filename = 'download') => {
    try{
        const response = await downloadFileInZip({files : files});
        
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}.zip`);
        document.body.appendChild(link);
        link.click();
    }catch(error){
        console.error('Error downloading zip:', error);
    }
    
};


export const createDownloadLinks = async (rows) => {
    console.log(rows);

    let files = [];
    for(let row of rows){
        const {pipesRequired, pipeInstallments, firstDry, secondDry, farmername, block, tehsil, village, district} = row;
        
        let folderName = `${farmername}_${block}_${tehsil}_${village}_${district}`;

        if(pipesRequired){
            
            for(let i = 0; i<pipesRequired; i++){
                let subFolderName = `${folderName}/PIPE_${i+1}`;
                let obj = {};
                if(pipeInstallments[i].date_!=""){
                    const {file1, file2} = pipeInstallments[i];
                    // Replace this URL with your endpoint that returns the signed URL.
                    // const response = await downloadFileByUrl({url : file});
                    files.push({
                        url : file1,
                        folder : `${subFolderName}/PIPE_INSTALLMENTS`,
                        filename : `PIPE_INSTALLATION_PIPE_${i+1}_${farmername}_${village}_${block}_${tehsil}_${district}.jpg`
                    });
                    files.push({
                        url : file2,
                        folder : `${subFolderName}/PIPE_INSTALLMENTS`,
                        filename : `PIPE_INSTALLATION_PIPE_${i+1}_${farmername}_${village}_${block}_${tehsil}_${district}.jpg`
                    })
                }
                if(firstDry[i].date_!=""){
                    const {file1, file2} = firstDry[i];
                    // const response1 = await downloadFileByUrl({url : file1});
                    // const response2 = await downloadFileByUrl({url : file2});
                    files.push({
                        url : file1,
                        folder : `${subFolderName}/FIRST_DRY`,
                        filename : `FIRST_DRY_PIPE_${i+1}_${farmername}_${village}_${block}_${tehsil}_${district}.jpg`
                    })
                    files.push({
                        url : file2,
                        folder : `${subFolderName}/FIRST_DRY`,
                        filename : `FIRST_DRY_FIELD_${i+1}_${farmername}_${village}_${block}_${tehsil}_${district}.jpg`
                    })
                }
                if(secondDry[i].date_!=""){
                    const {file1, file2} = secondDry[i];
                    // const response1 = await downloadFileByUrl({url : file1});
                    // const response2 = await downloadFileByUrl({url : file2});
                    files.push({
                        url : file1,
                        folder : `${subFolderName}/SECOND_DRY`,
                        filename : `SECOND_DRY_PIPE_${i+1}_${farmername}_${village}_${block}_${tehsil}_${district}.jpg`
                    })
                    files.push({
                        url : file2,
                        folder : `${subFolderName}/SECOND_DRY`,
                        filename : `SECOND_DRY_FIELD_${i+1}_${farmername}_${village}_${block}_${tehsil}_${district}.jpg`
                    })
                }
                
            }
        }
    }

    await handleDownloadZip(files, 'aeration');
}

export const maskAadharNumber = (aadharNumber) => {
    const lastFourCharacters = aadharNumber.slice(8);
    return `XXXXXXXX${lastFourCharacters}`;
}
// export const isUserLoggedIn = () => {
//     let token = localStorage.getItem('token');
//     return new Promise(async (resolve, reject) => {
//         if(token === null){
//             reject(false);
//         }
//         const response = isTokenValid({token});
//         if(response.result === 'success'){
//             resolve(true);
//         }else{
//             reject(false);
//         }
//     })
// }
