import { useFormik } from "formik";
import { useEffect } from "react";
import styles from './../AddFeildInformation.module.css';
import { getTodaysDate } from "../../../../Utils/commonFunctions";
import WarningMessage from "../../../../Components/WarningMessage/WarningMessage";

const validate = (values) => {
    const errors = {};
    

    if(values.type === ""){
        errors.type = "This feild is required";
    }
    if(values.type === 'other' && values.otherType === ''){
        errors.otherType = "Other Manure is required"
    }
    if(values.quantity === ""){
        errors.quantity = "This feild is required";
    }
    else if(values.quantity<0){
        errors.quantity = "Quantity can't be negative";
    }
    if(values.date === ""){
        errors.date = "This feild is required";
    }

    return errors;
}

const OrganicFertlizers = ({organicManures, change}) => {
    const todaysDate = getTodaysDate();
    const formik = useFormik({
        initialValues : {
            type : '',
            otherType : '',
            quantity : '',
            date : ''
        },
        validate,
        onSubmit : (values) => {
            change('organicFertlizers', values);
            formik.resetForm({});
        }
    })
    useEffect(() => {

    },[])
    return (
        <div>
        <form>
            <div className="row">
                <div className="col-md-3">
                    <label className="col-form-label">Organic Manure Applied</label>
                    <select  className={`form-control ${ formik.submitCount>0 && formik.errors.type ? styles.formInputError : ""}`} id='type' placeholder="Organic Manure" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.type}>
                        <option value=''>Select</option>
                        {
                        organicManures.map((item, index) => <option key={index} value={item.name}>{item.name}</option>)
                        }
                        <option value='other'>Others</option>
                    </select>
                    {formik.submitCount>0 && formik.errors.type ? <div className={styles.error}>{formik.errors.type}</div> : ""}
                </div>
                    {
                        formik.values.type === 'other' ? 
                        <div className="col-md-3">
                            <label className="col-form-label">Other Manure</label>   
                            <input type="text" 
                             className={`form-control ${ formik.submitCount>0 && formik.errors.otherType ? styles.formInputError : ""}`}
                            id='otherType' placeholder="Other Manure" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.otherType}/>      
                             {formik.submitCount>0 && formik.errors.otherType ? <div className={styles.error}>{formik.errors.otherType}</div> : ""}
                        </div> : ''
                    }
                <div className="col-md-3">
                    <label className="col-form-label">Quantity applied(Quintals/acres)</label>
                    <input className={`form-control ${ (formik.submitCount>0 && formik.errors.quantity) ? styles.formInputError : ""}`} 
                    type="Number" id='quantity' min={0} placeholder="Organic Manure" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.quantity}/>
                   {formik.submitCount>0 && formik.errors.quantity ? <div className={styles.error}>{formik.errors.quantity}</div> : ""}
                    
                </div>
                <div className="col-md-3">
                    <label className="col-form-label">Date of Application</label>
                    <input className={`form-control ${ (formik.submitCount>0 && formik.errors.date) ? styles.formInputError : ""}`} type="date" id='date'max={todaysDate} placeholder="Date" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.date}/>
                    {formik.submitCount>0 && formik.errors.date ? <div className={styles.error}>{formik.errors.date}</div> : ""}
                </div>
            </div>
            <div className="row col-md-4">
                <button className="btn btn-secondary mt-2" type="button" onClick={formik.handleSubmit}>Add</button>
            </div>
            <WarningMessage/>
        </form>
        </div>
    );
}


export default OrganicFertlizers