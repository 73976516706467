import { useEffect, useState } from "react";
import { deleteLandRecords, getAllLandRecords, getLandRecords, getTableData, getfarmers } from "../../../../APIs/apiCalls";
import MaterialReactTable from "material-react-table";
import { useNavigate } from "react-router-dom";
import landImage from '../../../../assets/img/land.png';
import { mkConfig, generateCsv, download } from "export-to-csv";
import Spinners1 from "../../../../Components/Spinners/Spinners1";
import { downloadFile } from "../../../../Utils/commonFunctions";
import { NotificationManager } from "react-notifications";
import AlertConfirm from "react-alert-confirm";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFContent from "../../AddLandRecords/LandRecordForm/LeaseAgreementDownload/PDFContent";
import PDFContentU from "../../AddLandRecords/LandRecordForm/Undertaking/PDFContentU";
import { fetchUrl } from "../../../../APIs/server";
import ProfileLink from "../../../../Atoms/ProfileLink";

const csvConfig = mkConfig({ useKeysAsHeaders: true });
const url_ = fetchUrl;

const userType = localStorage.getItem('type');
const LandRecords = () => {
    const navigate = useNavigate();
    const [data, setData] = useState(undefined);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [success, setSuccess] = useState(0);
    const [total, setTotal] = useState(0);
    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    
    const columns = [
        {
            accessorKey: 'farmername',
            header: 'Farmer Name',
            Cell : (data) => {
                const {_id, farmername} = data.row.original;
                return (
                    <> <ProfileLink farmername={farmername} _id={_id}/> </>
                )
            }
        },
        {
            accessorKey: 'guardian',
            header : 'Guardian Name',
            Cell : ({cell}) => {
                const record = cell.getValue().toUpperCase();
                return <div>{record}</div>
            }
        },
        {
            accessorKey: 'isAWDFarmer',
            header : 'IS AWD FARMER',
            Cell : ({cell}) => {
                const record = cell.getValue();
                return <div>{record === true ? 'TRUE' : 'FALSE'}</div>
            }
        },
        {
            accessorKey: 'land',
            header : 'Land Document Status',
            Cell : ({cell}) => {
                const record = cell.row.original.land;
                return <div>{record !== undefined && record !== null  ? <span className="badge bg-success text-white"> SUCCESS <i className="typcn typcn-input-checked"></i></span> : <span className="badge bg-danger text-white">PENDING <i className="typcn typcn-warning"></i></span>}</div>
            }
        },
       
        {
            header: 'Owned Khasra Numbers',
            Cell: ({ cell }) => {
                const data = cell.row.original.land;
                
                return (
                    <div>
                        { data ? data.ownedLandKhasraNumbers.join(', ') : 'None' }
                    </div>
                );
            }
        },
        {
            header: 'Owned Land Documents',
            Cell: ({ cell }) => {
                const data = cell.row.original.land;
                
                return (
                    <div>
                        { data ? data.ownedDocuments.map((url, index) => <div>
                            {index+1}. <a className="btn btn-sm btn-secondary" target="_blank" href={url}>View</a> &nbsp;
                            <button className="btn btn-sm btn-success" onClick={() => downloadFile(url)}>Download</button>
                        </div>) : 'No documents to show'}
                    </div>
                );
            }
        },
        {
            header: 'Leased Khasra Numbers',
            Cell: ({ cell }) => {
                const data = cell.row.original.land;
                
                return (
                    <div>
                        { data ? data.leasedLandKhasraNumbers.join(', ') : 'None' }
                    </div>
                );
            }
        },
        {
            header: 'Leased Land Documents',
            Cell: ({ cell }) => {
                const data = cell.row.original.land;
                
                return (
                    <div>
                        { data ? data.leasedDocuments.map((url, index) => <div className="mt-1">
                            {index+1}. <a className="btn btn-xs btn-secondary" target="_blank" href={url}>View</a> &nbsp;
                            <button className="btn btn-xs btn-success" onClick={() => downloadFile(url)}>Download</button>
                        </div>) : 'No documents to show'}
                    </div>
                );
            }
        },
        // {
        //     header: 'Pending Land Documents',
        //     Cell: ({ cell }) => {
        //         const data = cell.row.original;
        //         let uploaded = [];
        //         for(let land of data.lands){
        //             uploaded.push(...land.khasraNumbers);
        //         }
        //         const khasraNumbers = [...data.leasedLandKhasraNumbers,...data.ownedLandKhasraNumbers];
                
        //         const pending = khasraNumbers.filter((item) => uploaded.includes(item) === false);
        //         return (
        //             <div>
        //                 {khasraNumbers.length === 0 ? 'No Khasra number is entered' : pending.length === 0 ? 'No pending land documents.' : pending.join(', ')}
        //             </div>
        //         );
        //     }
        // },
        // {
            
        //     header: 'Statistics',
        //     Cell: ({ cell }) => {
        //         const data = cell.row.original;
        //         let uploaded = [];
        //         for(let land of data.lands){
        //             uploaded.push(...land.khasraNumbers);
        //         }
        //         const khasraNumbers = [...data.leasedLandKhasraNumbers,...data.ownedLandKhasraNumbers];

        //         return (
        //             <div>
        //                 <span>{uploaded.length} Success✅ </span>, <br/>
        //                 <span>{khasraNumbers.length - uploaded.length} Pending⚠️</span>
        //             </div>
        //         );
        //     }
        // },
       {
        header : 'Total area covered',
        Cell : ({cell}) => {
            const {land}  =  cell.row.original;
            return <>{land ? `${land.ownedLandArea + land.leasedLandArea} Acres` : 'NA'}</>;
        }
       },
        // {
        //     header: 'Land Documents',
        //     Cell: ({ cell }) => {
        //         const record = cell.row.original;
        //         return (
        //             <div>
        //                 {
        //                     record.lands.length != 0 ? 
        //                     <>
        //                     {
        //                         record.lands?.map((item, index) => 
        //                             <div key={index}>
                                        
        //                                 <p>Khasra Numbers : {item.khasraNumbers.join(', ')}</p>
        //                                 <p>Area : {item.area} Acres</p>
        //                                 <button className="btn btn-sm btn-info" onClick={() => downloadFile(item.filename)}> <i className="typcn typcn-download"></i></button>&nbsp;
        //                                 <a className="btn btn-sm btn-primary" href={item.filename} target="_blank"> <i className="typcn typcn-export"></i></a>
                                       
        //                                 {  index!=record.lands.length - 1 ? <hr/> : null }
        //                             </div>
        //                         )
        //                     }</> : 'No Documents are uploaded'
        //                 }
                        
        //             </div>
        //         );
        //     }
        // },

        {
            header : 'Undertaking Documents',
            Cell: ({ cell }) => {
                const record = cell.row.original.undertakingDocument;
                return (
                    <div>
                        {
                            record != undefined ? 
                            <> 
                            <p>Khasra Numbers : {record.khasraNumbers?.join(',')} </p>
                            <p>Area : {record.area} Acres</p>
                            <PDFDownloadLink document={<PDFContentU undertaking = {record} />} fileName={`${record.farmerName}_${record.farmerVillage}_${record.farmerBlock}_${record.farmerTehsil}_${record.farmerDistrict}.pdf`}>
                                {
                                    ({ blob, url, loading, error }) => loading ? 'Loading document...' : <button className="btn btn-sm btn-primary"><i className = "typcn typcn-download"></i></button> 
                                }
                            </PDFDownloadLink></> 
                            : <p className="text-danger">Undertaking document is not uploaded</p> 
                        }
                        
                    </div>
                );
            }
        },
        {
            header : 'Leased Agreements',
            Cell: ({ cell }) => {
                const record = cell.row.original;
                return (
                    <div>
                        {
                            record.leasedAgreement && record.leasedAgreement.length>0 ? 
                            <>
                            {
                                record.leasedAgreement.map((item, index) => <div>
                                    <p key={index}>{index + 1}. Khasra Numbers : {item.khasraNumbers?.join(',')}, Area : {item.area} Acres </p>
                                    <p><PDFDownloadLink document={<PDFContent leasedAgreement = {item} />} fileName={`${item.farmerName}_${item.farmerVillage}_${item.farmerBlock}_${item.farmerTehsil}_${item.farmerDistrict}.pdf`}>
                                            {
                                                ({ blob, url, loading, error }) => loading ? 'Loading document...' : <button className="btn btn-sm btn-primary"><i className = "typcn typcn-download"></i></button> 
                                            }
                                    </PDFDownloadLink>
                                        &nbsp; </p>
                                    <hr/>
                                </div>)
                            }</> : <p className="text-danger">Leased Agreement files not uploaded</p>
                        }
                    </div>
                );
            }
        }

    ]

    useEffect(()=>{
        (async() => {
            const url = new URL(`${url_}/manage-land`);
            url.searchParams.set('page', pagination.pageIndex + 1);
            url.searchParams.set('limit', pagination.pageSize);

            for(let item of columnFilters){
                url.searchParams.set(item.id, item.value)
            }

            if(globalFilter.length > 0) {
                url.searchParams.set('farmername', globalFilter)
            }
            
            setIsRefetching(true);
            let response = await getTableData(url.href);
            setIsRefetching(false);
            let data = response.data;
            console.log(data);
            let count = response.count;
            let success = response.success;

            setTotal(count)
            setSuccess(success);
            setData(data);
            setRowCount(response.numberOfRecords);
        })();
    },[columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize]);
    if(data===undefined){
        return <Spinners1/>
    }
    return (
        <div>
            <p>{success} RECORDS <span className="text text-success">UPLOADED <i className="typcn typcn-input-checked"></i></span></p>
            <p>{total - success} RECORDS <span className="text text-danger">PENDING <i className="typcn typcn-warning"></i> </span></p>
        
        <MaterialReactTable
            columns={columns}
            data = {data}
            muiTableProps={{
                sx: {
                border: '1px solid rgba(81, 81, 81, 1)',
                },
            }}
            muiTableHeadCellProps={{
                sx: {
                    border: '1px solid rgba(81, 81, 81, 1)',
                },
            }}
            muiTableBodyCellProps={{
                sx: {
                    border: '1px solid rgba(81, 81, 81, 1)',
                },
            }}
            renderTopToolbarCustomActions={() => (
                <div>
                    
                    {
                        userType !='viewer' ? 
                        <><button 
                            className='btn btn-bluish-green  btn-icon-text'
                            onClick={() => navigate('/add-landrecords')}
                        >
                            <i className='typcn typcn-plus btn-icon-prepend'></i> <span>New/Edit </span>
                        </button>
                        &nbsp;</> : null
                    }
                    {/* <button
                      className='btn btn-primary  btn-icon-text'
                      onClick={handleExportData}
                    >
                        <i className='typcn typcn-download btn-icon-prepend'></i> <span>Export</span>  
                    </button> */}
                </div>
                
            )}
            muiTablePaginationProps={{
                showFirstButton: true,
                showLastButton: true,
            }}
            options={{
                exportButton: true
            }}

            
            manualPagination={true}
            onPaginationChange={setPagination}
            manualFiltering = {true}
            onColumnFiltersChange={setColumnFilters}
            
            state={{
                pagination,
                showProgressBars: isRefetching,
            }}
            rowCount={rowCount}
            
            onGlobalFilterChange = {setGlobalFilter}
                
        />
        </div>
    );
}

export default LandRecords;