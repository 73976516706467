import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'

const ThreeLoader = () => {
  return (
	<>
	<ClipLoader
		color='blue'
		loading={true}
		size={14}
		aria-label="Loading Spinner"
		data-testid="loader"
	/> <ClipLoader
		color='blue'
		loading={true}
		size={14}
		aria-label="Loading Spinner"
		data-testid="loader"
	/> <ClipLoader
		color='blue'
		loading={true}
		size={14}
		aria-label="Loading Spinner"
		data-testid="loader"
	/>
	</>
  )
}

export default ThreeLoader