import { IconButton } from "@mui/material/node";
import DeleteIcon from '@mui/icons-material/Delete';
import MaterialReactTable from "material-react-table";
import { fetchOptions, updateOptions, updatePestsAndDiseases } from "../../APIs/apiCalls";
import Layout from "../../Layout";


const PestAndDiseasesTable = ({data, change, delete_, heading, collection}) => {
    const columns = [
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'crop',
            header: 'Crop',
        },
        {
            // Add a column for the delete button
            accessorKey: 'delete',
            header: 'Delete',
            enableSorting: false, 
            enableEditing : false,
            Cell: ({ row }) => (
                <IconButton color="error" onClick={() => handleDeletion(row)}>
                    <DeleteIcon />
                </IconButton>
            ),
        }, // end
    ];
    const handleDeletion = (row) => {
        const id = row.original._id;
        delete_(id);
    }
    const handleSaveRow = async ({ exitEditingMode, row, values }) => {
        
        //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here.
        
        let {crop, type, _id} = row.original;

        fetchOptions('pests-and-diseases').then((response) => {
            // console.log(response);
            let n = response.data.length;
            let {data} = response.data[n-1];
            crop = crop.toLowerCase()
            // console.log(data[crop]);
            if(data[crop]===undefined){
                data[crop] = {};
                data[crop]['pest'] = [];
                data[crop]['diseases'] = [];
            }
            
            const index = data[crop][type].findIndex((item) => item.name === values.name)
            if(index === -1)
            {    data[crop][type].push(values.name);    }
           
            updatePestsAndDiseases(response.data[n-1]._id, {data : data}).then((response_) => {
                // console.log(response_);
                change(_id ,values, collection);
            })
        })

        

        exitEditingMode(); //required to exit editing mode
      };
    return (
       <>
        <h4>{heading}</h4>
        <MaterialReactTable
          columns={columns}
          data={data}
          editingMode="modal" //default
          enableEditing
          onEditingRowSave={handleSaveRow}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
            initialState={{ density: 'compact' }}
            enableFilters = {false}
            enableHiding={false}
        />
      </>
    );
}

export default PestAndDiseasesTable;