import { useEffect, useState } from "react";
import { addFeildData, fetchOptions, getFeildDataById, getLocations, getfarmers, updateFeildData } from "../../../../APIs/apiCalls";
import {  useFormik } from "formik";
import validate from "./validate";
import styles from './../AddFeildInformation.module.css';
import {  NotificationManager } from "react-notifications";
import { useNavigate, useParams } from "react-router-dom";
import {   majorIssues, organicManures, season, yearsArray } from "./dropdownOptions";
import OtherNutrientsForm from "./OtherNutrientsForm";
import IrrigationForm from "./IrrigationForm";
import PestManagementForm from "./PestManagementForm";
import MajorDiseasesForm from "./MajorDiseasesForm";
import AddedItems from "./AddedItems";
import CreditForm from "./CreditForm";
import OrganicFertlizers from "./OrganicFertilizers";
import Select, { createFilter } from "react-select";
import { filterConfig, formatDate, getTodaysDate } from "../../../../Utils/commonFunctions";
import Spinners1 from "../../../../Components/Spinners/Spinners1";
import FarmerSearchBar from "../../../../Components/FarmerSearchBar/FarmerSearchBar";
import { MultiSelect } from "react-multi-select-component";
import AlertConfirm from "react-alert-confirm";


const initialValues = 
{
    farmerName : '',
    farmerId : '',
    year : 2024,
    season : '',
    state : '',
    district : '',
    tehsil : '',
    village : '',
    crop : '',
    otherCrop : '',
    variety : '',
    sowingDate : '',
    dateOfNursery : '',
    expectedSowingDate : '',
    harvestingDate : '',
    expectedHarvestingDate : '',
    landPreprationDate : '',
    transplantationDate : '',
    majorIssues : '',
    seedRate : '',
    organicFertlizers : [],
    fertilizers : [],
    irrigations : [],
    pests: [],
    majorDiseases : [],
    credits : [],
    pesticideCost : '',
    labourCost : '',
    fertilizerCost : '',
    otherCost: '',
    totalCost : '',
    yieldPerAcre : '',
    totalYield : '',
    majorBuyers : '',
    revenue : '',
    leasedLandArea : '',
    ownedLandArea : '',
}

const FeildForm = (props) => {
    
    const [location, setLocation] = useState([]);
    const [farmersList, setFarmersList] = useState([]);
    const [cropsList, setCropList] = useState([]);
    const [object, setObject] = useState({});
    const [fertilizers, setFertilizers] = useState([]);
    const [pesticides, setPesticides] = useState([]);
    const [fungicides, setFungicides] = useState([]);
    const [organicManures, setOrganicManures] = useState([]);
    const [irrigations, setIrrigations] = useState([]);
    const {id} = useParams();
    const todaysDate = getTodaysDate();
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState([]);
    const [disabled, setDisabled] = useState(false);

    const formik = useFormik({
        initialValues : initialValues,
        validate,
        onSubmit : (values) => {
           
            setDisabled(true);
            if(id!=undefined){
                updateFeildData(id, values).then((response) => {
                    setDisabled(false);
                    if(response.result === 'success') {
                        NotificationManager.success('Crops details are updated successfully')
                    }
                })
            }else{
                addFeildData(values).then((response) => {
                    setDisabled(false);
                    if(response.result === 'success') {
                        formik.resetForm(initialValues);
                        NotificationManager.success('Crops details are saved successfully')
                    }
                })
            }
        }
    });

    const createLabelsAndValues = (value, locationArray) => {
        formik.setFieldValue('tehsil', value)
        let arr = Array.from(new Set(locationArray.filter(item=> item.tehsil === value))).sort((a,b) => a.village.localeCompare(b.village)).map((obj) => obj['village']);
        let optns = [];
        for(let item of arr){
          let obj = { value : item, label : item.toUpperCase() };
          optns.push(obj);
        }
        setOptions(optns);
        return optns;
      }

    const changeFeildValue = (feildname, value) => {
        // console.log( value);
        // console.log( feildname);
        let arr = formik.getFieldProps(feildname).value;
        // console.log(arr);
        if(arr){
            arr.push(value);
            formik.setFieldValue(feildname, arr);
        }else{
            arr = [value];
            formik.setFieldValue(feildname, arr);
        }
    }

    const updateAfterDeletion = (feildname, value) => {
        formik.setFieldValue(feildname, value);
    }


    const changeHandler = (event) => {
        // console.log(value);
        const index = farmersList.findIndex((item) => item._id === event.target.value);
        if(index!=-1){
            formik.setFieldValue('farmerId', event.target.value);
            formik.setFieldValue('farmerName', farmersList[index].firstname + ' ' + farmersList[index].lastname);
        }
    }

    const cropChangeHandler = (event) => {
        const {value} = event.target;
        formik.setFieldValue('crop', value);
        formik.setFieldValue('pests', []);
        formik.setFieldValue('majorDiseases', []);
    }

    const yearChangeHandler = (event) => {
        const {value} = event.target;
        AlertConfirm({
            title: `You have selected, Year : ${value}.`,
            desc: 'Shall we move forward ?',
            onOk: () => {
              formik.setFieldValue('year', value);
            },
            onCancel: () => {
              // console.log('cancel');
            }
        })
    }

    const handleReset = () => {
        formik.setValues(initialValues)
        formik.resetForm({});
    }

    const fetchDropdownOptons = () => {
        (async () => {
            let response = await fetchOptions('pests-and-diseases');
            let crops = Object.keys(response.data[0].data);
            setCropList(crops)
            setObject(response.data[0].data);
            response = await fetchOptions('fertilizers');
            setFertilizers(response.data);
            response = await fetchOptions('pesticides');
            setPesticides(response.data);
            response = await fetchOptions('fungicides');
            setFungicides(response.data)
            response = await fetchOptions('organic-fertilizers');
            setOrganicManures(response.data);
            response = await fetchOptions('irrigations');
            setIrrigations(response.data)
        })();
    }

    const changeDropdowns = (value) => {
        
        const {state, district, village, tehsil, _id, firstname, lastname} = value;
        // formik.setFieldValue('state', state);
        // formik.setFieldValue('district', district);
        // formik.setFieldValue('tehsil', tehsil);
        // formik.setFieldValue('village', { label : village.toUpperCase(), value : village});
        // formik.setFieldValue('farmerName', `${firstname} ${lastname}`);
        formik.setFieldValue('farmerId', _id);
        // let optns = createLabelsAndValues(tehsil, location);
        // setOptions(optns);
        // formik.setFieldValue('village', { label : village.toUpperCase(), value : village})
    }

    useEffect(() => {
        (async() =>{
            fetchDropdownOptons();
            if(id!=undefined){
                let response = await getFeildDataById(id);
                let {data} = response;
                if(data.majorIssues){
                    setValue(data.majorIssues)
                }
                
                formik.setValues(data);
            }else{
                formik.setValues(initialValues)
                formik.resetForm({});
            }
        })();
    },[id])
    return (
        <div>
            <h4 >Crops Information Form</h4>
            <form onSubmit={formik.handleSubmit}>
                
                
                {/* Crop Details */}
                <div className="mt-3">
                    
                    <FarmerSearchBar change = {changeDropdowns}/>
                    <div className="row">

                        
                        <div className="col-md-4 mt-2">
                            <label className="col-form-label">Crop*</label>
                            <select 
                            className={`form-control ${ formik.touched.crop === true && formik.errors.crop ? styles.formInputError : ""}`}
                            id="crop" onChange={(event) => cropChangeHandler(event)} onBlur={formik.handleBlur} value={formik.values.crop}>
                            <option value=''>Select crop</option>
                            {
                                cropsList.map((crop, index) => <option  key={index} value={crop} style={{textTransform : 'capitalize'}}>{crop}</option>)
                            }
                            <option value="other">Other</option>
                            </select>
                            {formik.touched.crop === true && formik.errors.crop ? <div className={styles.error}>{formik.errors.crop}</div> : ""}
                        </div>

                        {
                            formik.values.crop.toLowerCase() === 'other' ? 
                            <div className="col-md-4 mt-2">
                                <label className="col-form-label">Other Crop*</label>
                                <input className={`form-control ${ formik.touched.otherCrop === true && formik.errors.otherCrop ? styles.formInputError : ""}`}
                                id="otherCrop" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.otherCrop}/>
                                
                                {formik.touched.otherCrop === true && formik.errors.otherCrop ? <div className={styles.error}>{formik.errors.otherCrop}</div> : ""}
                            </div> : ''
                        }

                        <div className="col-md-4 mt-2">
                            <label className="col-form-label">Year*</label>
                            <select className={`form-control ${ formik.touched.year === true && formik.errors.year ? styles.formInputError : ""}`}
                            id="year" onChange={(event) => yearChangeHandler(event)} onBlur={formik.handleBlur} value={formik.values.year}
                            >
                                <option value=''>Select year</option>
                                {
                                    yearsArray.map((item) => <option value={item}>{item}</option>)
                                }
                            </select>
                            {formik.touched.year === true && formik.errors.year ? <div className={styles.error}>{formik.errors.year}</div> : ""}
                        </div>
                        <div className="col-md-4 mt-2">
                            <label className="col-form-label">Season*</label>
                            <select className={`form-control ${ formik.touched.season === true && formik.errors.season ? styles.formInputError : ""}`}
                            id="season"
                            onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.season}
                            >
                                <option value=''>Select season</option>
                                {
                                    season.map((item) => <option value={item}>{item}</option>)
                                }
                            </select>
                            {formik.touched.season === true && formik.errors.season ? <div className={styles.error}>{formik.errors.season}</div> : ""}
                        </div>
                        <div className="col-md-4 mt-2">
                            <label className="col-form-label">Leased Land Area(acres)*</label>
                            <input min={0}
                            className={`form-control ${ formik.touched.leasedLandArea === true && formik.errors.leasedLandArea ? styles.formInputError : ""}`} type="Number" id="leasedLandArea" placeholder="Area(acres)" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.leasedLandArea} step="any"/>
                            {formik.touched.leasedLandArea === true && formik.errors.leasedLandArea ? <div className={styles.error}>{formik.errors.leasedLandArea}</div> : ""}
                        </div>
                        <div className="col-md-4 mt-2">
                            <label className="col-form-label">Owned Land Area(acres)*</label>
                            <input min={0}
                            className={`form-control ${ formik.touched.ownedLandArea === true && formik.errors.ownedLandArea ? styles.formInputError : ""}`} type="Number" id="ownedLandArea" placeholder="Area(acres)" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.ownedLandArea} step="any"/>
                            {formik.touched.ownedLandArea === true && formik.errors.ownedLandArea ? <div className={styles.error}>{formik.errors.ownedLandArea}</div> : ""}
                        </div> 
                        <div className="col-md-4 mt-2">
                            <label className="col-form-label">Total Area(acres)</label>
                            <input disabled={true}
                            className={`form-control`} type="Number" id="area" placeholder="Area(acres)" value={formik.values.ownedLandArea + formik.values.leasedLandArea} step="any"/>
                        </div>

                        <div className="col-md-4 mt-2">
                            <label className="col-form-label">Variety*</label>
                            <input 
                            className={`form-control ${ formik.touched.variety === true && formik.errors.variety ? styles.formInputError : ""}`} type="text" id="variety" placeholder="Variety" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.variety}/>
                            {formik.touched.variety === true && formik.errors.variety ? <div className={styles.error}>{formik.errors.variety}</div> : ""}
                        </div>
                        <div className="col-md-4 mt-2">
                            <label className="col-form-label">Major Issues*</label>
                            <MultiSelect
                                id='majorIssues'
                                className={` ${ formik.submitCount>0 && formik.errors.majorIssues ? styles.formInputError : ""}`}
                                options={majorIssues}
                                value={value}
                                onChange={(event) =>  {setValue(event); formik.setFieldValue('majorIssues', event)}}
                                labelledBy={"Select"}
                            />
                            { formik.submitCount>0  && formik.errors.majorIssues ? <div className={styles.error}>{formik.errors.majorIssues}</div> : ""}
                        </div>
                        <div className="col-md-4 mt-2">
                            <label className="col-form-label">Seed Rate(kg/acre)</label>
                            <input className="form-control" min={0} id="seedRate" placeholder="Seed Rate" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.seedRate}/>
                        </div>
                         
                        <div className="col-md-6 mt-2">
                            <label className="col-form-label">Date of Nursery</label>
                            <input className="form-control" type="Date" id="dateOfNursery" placeholder="Date of Nursery" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.dateOfNursery}
                             min={formatDate(formik.values.year, 1, 1)} max={formatDate(formik.values.year, 12, 31)}/>
                        </div>

                        <div className="col-md-6 mt-2">
                            <label className="col-form-label">Date of Land Prepration</label>
                            <input 
                            className={`form-control ${ formik.touched.landPreprationDate === true && formik.errors.landPreprationDate ? styles.formInputError : ""}`}
                            type="Date" id='landPreprationDate' placeholder="Land Prepration Date" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.landPreprationDate}
                            min={formatDate(formik.values.year, 1, 1)} max={formatDate(formik.values.year, 12, 31)}/>
                            {formik.touched.landPreprationDate === true && formik.errors.landPreprationDate ? <div className={styles.error}>{formik.errors.landPreprationDate}</div> : ""}
                        </div>

                        <div className="col-md-6 mt-2">
                            <label className="col-form-label">Expected Date of Sowing/Transplantation</label>
                            <input 
                            className={`form-control ${ formik.touched.expectedSowingDate === true && formik.errors.expectedSowingDate ? styles.formInputError : ""}`}
                             type="Date" id="expectedSowingDate" placeholder="Expected Sowing Date" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.expectedSowingDate}
                             min={formatDate(formik.values.year, 1, 1)} max={formatDate(formik.values.year, 12, 31)}
                             />
                            {formik.touched.expectedSowingDate === true && formik.errors.expectedSowingDate ? <div className={styles.error}>{formik.errors.expectedSowingDate}</div> : ""}
                        </div>

                        <div className="col-md-6 mt-2">
                            <label className="col-form-label"> Date of Sowing/Transplantation</label>
                            <input 
                            className={`form-control ${ formik.touched.sowingDate === true && formik.errors.sowingDate ? styles.formInputError : ""}`}
                            type="Date" id="sowingDate" placeholder="Sowing Date" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.sowingDate}
                            min={formatDate(formik.values.year, 1, 1)} max={formatDate(formik.values.year, 12, 31)}
                            />
                            {formik.touched.sowingDate === true && formik.errors.sowingDate ? <div className={styles.error}>{formik.errors.sowingDate}</div> : ""}
                        </div>
                        <div className="col-md-6 mt-2">
                            <label className="col-form-label">Expected Date of Harvest</label>
                            <input 
                            className={`form-control ${ formik.touched.expectedHarvestingDate === true && formik.errors.expectedHarvestingDate ? styles.formInputError : ""}`} 
                            type="Date" id='expectedHarvestingDate' placeholder=" Expected Harvesting Date" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.expectedHarvestingDate}
                            min={formatDate(formik.values.year, 1, 1)} max={formatDate(parseInt(formik.values.year) + 1, 3, 31)}/>
                            {formik.touched.expectedHarvestingDate === true && formik.errors.expectedHarvestingDate ? <div className={styles.error}>{formik.errors.expectedHarvestingDate}</div> : ""}
                        </div>
                        <div className="col-md-6 mt-2">
                            <label className="col-form-label">Date of Harvest</label>
                            <input 
                            className={`form-control ${ formik.touched.harvestingDate === true && formik.errors.harvestingDate ? styles.formInputError : ""}`} 
                            type="Date" id='harvestingDate' placeholder="Harvesting Date" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.harvestingDate}
                            min={formatDate(formik.values.year, 1, 1)} max={formatDate(parseInt(formik.values.year) + 1, 3, 31)}/>
                            {formik.touched.harvestingDate === true && formik.errors.harvestingDate ? <div className={styles.error}>{formik.errors.harvestingDate}</div> : ""}
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                    <h4>Nutrient Management</h4>
                    
                    <h6 className="mt-3"> <u>Organic Fertilizers :</u> </h6>
                    <OrganicFertlizers change={changeFeildValue} organicManures = {organicManures} />
                    <AddedItems items={formik.values.organicFertlizers} change={updateAfterDeletion} feildname='organicFertlizers'/>

                    <h6 className="mt-5"><u>Fertilizers:</u></h6>
                    <OtherNutrientsForm change={changeFeildValue} fertilizers={fertilizers}/>
                    <AddedItems items={formik.values.fertilizers} change={updateAfterDeletion} feildname='fertilizers'/>
                </div>
                <div className="mt-5">
                    <h4>Irrigation Details</h4>
                    <IrrigationForm change={changeFeildValue} irrigations={irrigations}/>
                    <AddedItems items={formik.values.irrigations} change={updateAfterDeletion} feildname="irrigations"/>
                </div>
                <div className="mt-5">
                    <h4>Pest Management</h4>
                    <PestManagementForm change={changeFeildValue} crop = {formik.values.crop} pest = {object[formik.values.crop]?.pest} pesticides={pesticides}/>
                    <AddedItems items={formik.values.pests} change={updateAfterDeletion} feildname="pests"/>
                </div>
                <div className="mt-5">
                    {/* <h4>Major Diseases</h4> */}
                    <MajorDiseasesForm change={changeFeildValue}  crop = {formik.values.crop} diseases = {object[formik.values.crop]?.diseases} fungicides = {fungicides}/>
                    <AddedItems items={formik.values.majorDiseases} change={updateAfterDeletion} feildname="majorDiseases"/>
                </div>
                <div className="mt-5">
                    <h4>Credit Management</h4>
                    <CreditForm change={changeFeildValue}/>
                    <AddedItems items={formik.values.credits} change={updateAfterDeletion} feildname="credits"/>
                </div>

                <div className="mt-5">
                <h4>Costing(in Rs.)</h4>
                    <div className="row">
                        <div className="col-md-4">
                            <label className="col-form-label">Per acre cost of Pesticides</label>
                            <input className={`form-control ${ formik.touched.pesticideCost === true && formik.errors.pesticideCost ? styles.formInputError : ""}`}
                             type="Number" min={0} id='pesticideCost' placeholder="Per acre cost of Pesticides" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.pesticideCost}/>
                            {formik.touched.pesticideCost === true && formik.errors.pesticideCost ? <div className={styles.error}>{formik.errors.pesticideCost}</div> : ""}
                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">Per acre cost of Labour</label>
                            <input className={`form-control ${ formik.touched.labourCost === true && formik.errors.labourCost ? styles.formInputError : ""}`} type="Number" min={0} id='labourCost' placeholder="Per acre cost of Labour" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.labourCost}/>
                            {formik.touched.labourCost === true && formik.errors.labourCost ? <div className={styles.error}>{formik.errors.labourCost}</div> : ""}
                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">Per acre cost of Fertilizers</label>
                            <input className={`form-control ${ formik.touched.fertilizerCost === true && formik.errors.fertilizerCost ? styles.formInputError : ""}`} 
                            type="Number" min={0} id='fertilizerCost' placeholder="Per acre cost of Fertilizers" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.fertilizerCost}/>
                            {formik.touched.fertilizerCost === true && formik.errors.fertilizerCost ? <div className={styles.error}>{formik.errors.fertilizerCost}</div> : ""}
                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">Other cost involved</label>
                            <input className={`form-control ${ formik.touched.otherCost === true && formik.errors.otherCost ? styles.formInputError : ""}`} 
                            type="Number" min={0} id='otherCost' placeholder="Other cost involved" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.otherCost}/>
                            {formik.touched.otherCost === true && formik.errors.otherCost ? <div className={styles.error}>{formik.errors.otherCost}</div> : ""}
                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">Total cost of cultivation(per acre)</label>
                            <input className='form-control' disabled={true}
                            type="Number" min={0} id='totalCost' placeholder="Total cost of cultivation(per acre)" value={(formik.values.pesticideCost!='' ? formik.values.pesticideCost : 0) + (formik.values.labourCost!='' ? formik.values.labourCost : 0)  + (formik.values.fertilizerCost != '' ? formik.values.fertilizerCost : 0) + (formik.values.otherCost!='' ? formik.values.otherCost : 0)}/>
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                <h4>Yield Information</h4>
                    <div className="row">
                        
                        <div className="col-md-4">
                            <label className="col-form-label">Yield Per Acre (Quintals)</label>
                            <input className={`form-control ${ formik.touched.yieldPerAcre === true && formik.errors.yieldPerAcre ? styles.formInputError : ""}`} 
                            type="Number" min={0} id='yieldPerAcre' placeholder="Yield" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.yieldPerAcre} step="any"/>
                            {formik.touched.yieldPerAcre === true && formik.errors.yieldPerAcre ? <div className={styles.error}>{formik.errors.yieldPerAcre}</div> : ""}
                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">Total Yield in Quintals</label>
                            <input className={`form-control`} 
                            type="Number" min={0} id='totalYield' placeholder="Yeild" disabled={true} value={formik.values.yieldPerAcre * (formik.values.leasedLandArea + formik.values.ownedLandArea)}/>
                            <span className={styles.warningMessage}><i className="typcn typcn-warning"></i> It'll automatically calculate based on Area and Yield Per Acre. </span>
                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">Major Buyers</label>
                            <input className={`form-control ${ formik.touched.majorBuyers === true && formik.errors.majorBuyers ? styles.formInputError : ""}`}
                            type="text" id='majorBuyers' placeholder="Major Buyers" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.majorBuyers}/>
                            {formik.touched.majorBuyers === true && formik.errors.majorBuyers ? <div className={styles.error}>{formik.errors.majorBuyers}</div> : ""}
                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label">Revenue Per Acre</label>
                            <input className={`form-control ${ formik.touched.revenue === true && formik.errors.revenue ? styles.formInputError : ""}`} 
                            type="Number" min={0} id='revenue' placeholder="Revenue Per Acre" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.revenue}/>
                            {formik.touched.revenue === true && formik.errors.revenue ? <div className={styles.error}>{formik.errors.revenue}</div> : ""}
                        </div>
                    </div>
                </div>
                
                <div className="text-center">
                    {
                        id!=undefined ? <button type="submit" className="btn btn-bluish-green mt-3" id="btn-submit" disabled={disabled}>Update</button> :<button type="submit" className="btn btn-bluish-green mt-3" id="btn-submit" disabled={disabled}>Submit</button>
                    }
                    <button className="btn btn-danger mt-3 ml-2" id="btn-submit" onClick={handleReset}>Reset</button>
                </div>
            </form>
            
        </div>
    );
}

export default FeildForm;