import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import styles from './LandRecordForm.module.css';
import makeAnimated from 'react-select/animated';
import { useFormik } from 'formik';
import ESignature from '../../../Documents/Agreement/ESignature';
import { NotificationManager } from 'react-notifications';
import { addUndertakingDocument } from '../../../../APIs/apiCalls';

const getFullYear = (new Date()).getFullYear();
 
const relations = [
    'son',
    'daughter',
    'grandson',
    'granddaughter'
];

const PreviewSignature = ({uri}) => {
    return <img height={90} width={170} src={uri}/>
}


const validate = (values) => {
    const error = {};
    if(values.year===''){
        error.year = 'Year is required';
    }

    if(values.khasraNumbers.length ===0){
        error.khasraNumbers = 'Khasra Number is required';
    }

    if(values.area===''){
        error.area = 'Area is required';
    }
    
    if(values.ownerName===''){
        error.ownerName = 'Name is required';
    }

    if(values.ownerDistrict===''){
        error.ownerDistrict = 'District is required';
    }
    if(values.ownerGuardian===''){
        error.ownerGuardian = 'Guardian is required';
    }
    if(values.ownerVillage===''){
        error.ownerVillage = 'Village is required';
    }
    if(values.ownerBlock===''){
        error.ownerBlock = 'Block is required';
    }
    if(values.area===''){
        error.area = 'Area is required';
    }

    if(values.ownerRelation===''){
        error.ownerRelation = 'Relation is required';
    }

    if(values.relation===''){
        error.relation = 'Relation is required';
    }

    // if(values.ownerSignType===''){
    //     error.ownerSignType = 'Sign Type is required';
    // }
    // if(values.farmerSignType===''){
    //     error.farmerSignType = 'SignType is required';
    // }
    if(values.ownerDate===''){
        error.ownerDate = 'Owner Date is required';
    }
    if(values.farmerDate===''){
        error.farmerDate = 'Farmer Date is required';
    }
    console.log(error);
    return error;
}


const UndertakingSign = ({farmer, location, changeRecords, remove}) => {
    console.log(farmer);
    
    const animatedComponents = makeAnimated();
    const [language, setLanguage] = useState('hindi');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const transformedArray = farmer.ownedLandKhasraNumbers ? farmer.ownedLandKhasraNumbers?.map(item => {
        return { label: item, value: item };
    }) : [];
    

    const formik = useFormik({
        initialValues : {
            year : getFullYear,
            khasraNumbers : [],
            area : '',
            farmerId : farmer._id,
            ownerName : '',
            ownerGuardian : '',
            ownerVillage : '',
            ownerBlock : '',
            ownerDistrict : '',
            ownerSign : '',
            ownerSignType : '',
            ownerDate  : '',
            farmerSign : '',
            farmerSignType : '',
            farmerDate  : '',
            farmerName : '',
            ownerRelation : '',
            relation : ''
        },
        validate,
        onSubmit : (values) => {
            console.log(values);
            setDisabled(true);
            addUndertakingDocument(values).then(response => {
                console.log(response);
                setDisabled(false);
                NotificationManager.success('Record saved successfully')
                changeRecords();
            }).catch(error => {
                setDisabled(false);
                NotificationManager.error('Internal Server Error')
                console.log(error);
            })
        }
    });

    const selectHandler = (event) => {
        const values = event.map((value) =>{ return value.value});
        formik.setFieldValue('khasraNumbers',values)
    }

    useEffect(() => {
        const {farmername, _id} = farmer;
        formik.setFieldValue('farmerName', farmername);
        formik.setFieldValue('farmerId', _id);
    },[farmer]);
  
    return (
    <div>
        <h5>Undertaking Document</h5>

        {/* <div className='row'>
            <div className='col-md-3 form-group'>
                <select className='form-control' value={language} onChange={(event) => setLanguage(event.target.value)}>
                    <option>Select language</option>
                    <option value='english'>English</option>
                    <option value='hindi'>Hindi</option>
                </select>
            </div>
        </div> */}
        <div>
            <form onSubmit={formik.handleSubmit}>
                <p>यह प्रमाणित किया जाता है कि मैं,  
                    <div style={{display : 'inline'}}>
                        <input className={`col-md-3 form-control  ${styles.lineInput} ${ formik.touched.ownerName === true && formik.errors.ownerName ? styles.formInputError : ''}`}  name='ownerName' value={formik.values.ownerName} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='owner name'/>
                    </div>,   
                
                <select className={`col-md-3 form-control  ${styles.lineInput} ${ formik.touched.ownerRelation === true && formik.errors.ownerRelation ? styles.formInputError : ''}`}  name='ownerRelation' value={formik.values.ownerRelation} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                    <option value=''>Select Relation</option>
                    <option value='son'>पुत्र</option>
                    <option value='daughter'>पुत्री</option>
                    <option value='wife'>पत्नी</option>
                </select>
                 <input placeholder='owner guardian' className={`col-md-3 form-control ${styles.lineInput} ${formik.touched.ownerGuardian && formik.errors.ownerGuardian ? styles.formInputError : ''}`} name='ownerGuardian' value={formik.values.ownerGuardian} onChange={formik.handleChange} onBlur={formik.handleBlur}/>,
                जिला <select className={`col-md-3 form-control ${styles.lineInput} ${formik.touched.ownerDistrict && formik.errors.ownerDistrict ? styles.formInputError : ''}`} name='ownerDistrict' value={formik.values.ownerDistrict} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                    <option>Select District</option>
                    {
                        Array.from(new Set(location.map(item => item.district))).sort().map((item,index) => <option value={item} key={index}>{item.toUpperCase()}</option>)
                    }
                </select>, 
                ब्लॉक <select placeholder='owner block' className={`col-md-3 form-control ${styles.lineInput} ${formik.touched.ownerBlock && formik.errors.ownerBlock ? styles.formInputError : ''}`} name='ownerBlock' value={formik.values.ownerBlock} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                    <option>Select Block</option>
                    {
                        Array.from(new Set(location.filter(item => item.district === formik.values.ownerDistrict).map(item => item.block))).sort().map((item,index) => <option value={item} key={index}>{item.toUpperCase()}</option>)
                    }
                </select>, 
                गाँव <select className={`col-md-3 form-control ${styles.lineInput} ${formik.touched.ownerVillage && formik.errors.ownerVillage ? styles.formInputError : ''}`} name='ownerVillage' value={formik.values.ownerVillage} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                    <option>Select Village</option>
                    {
                        Array.from(new Set(location.filter(item => item.block === formik.values.ownerBlock).map(item => item.village))).sort().map((item,index) => <option value={item} key={index}>{item.toUpperCase()}</option>)
                    }
                </select>, 
                अनुमति { formik.values.ownerRelation === 'son' && 'देता' } { formik.values.ownerRelation === 'son' || formik.values.ownerRelation === 'daughter' && 'देती' } { formik.values.ownerRelation === '' && 'देता/देती'} हूं कि 
                <input placeholder='farmer name' className={`col-md-3 form-control ${styles.lineInput}`} name='farmerName' value={formik.values.farmerName} onChange={formik.handleChange} onBlur={formik.handleBlur} disabled={true}/>, 
                वर्ष <input placeholder='year' className={`col-md-3 form-control ${styles.lineInput} ${formik.touched.year && formik.errors.year ? styles.formInputError : ''}`} name='year' value={formik.values.year} onChange={formik.handleChange} onBlur={formik.handleBlur} disabled={true}/> 
                में मेरे खेत पर, जिसकी पट्टा/खसरा संख्या 
                <Select
                    className={`${formik.touched.khasraNumbers && formik.errors.khasraNumbers ? styles.formInputError : ''}`}
                    id="khasraNumbers"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    placeholder = "Select Khasra Numbers"
                    onChange={(event) => selectHandler(event)}
                    options={transformedArray}
                /> है और 
                क्षेत्रफल (एकड़ में)<input placeholder='owner area' type='number' min={0} step='any' className={`col-md-3 form-control ${styles.lineInput} ${formik.touched.area && formik.errors.area ? styles.formInputError : ''}`} name='area' value={formik.values.area} onChange={formik.handleChange} onBlur={formik.handleBlur}/> है, '
                निम्नलिखित उन्नत कृषि पद्धतियों को अपनाते हुए खेती कर सकता  है। 
                वह { formik.values.ownerRelation === 'son' && 'मेरा' } { formik.values.ownerRelation === 'son' || formik.values.ownerRelation === 'daughter' && 'मेरी' } { formik.values.ownerRelation === '' && 'मेरा/मेरी'} <select className={`col-md-3 form-control ${styles.lineInput} ${formik.touched.relation && formik.errors.relation ? styles.formInputError : ''}`} name='relation' value={formik.values.relation} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                    <option>Select Relation</option>
                    <option value='son'>Son</option>
                    <option value='daughter'>Daughter</option>
                    <option value='grandson'>Grandson</option>
                    <option value='granddaughter'>Grand Daughter</option>
                </select> है। 
                इस समझौते के साथ, यह सहमति हुई है कि इस नामांकित भूमि से उत्पन्न होने वाले सभी कार्बन क्रेडिट भारतरोहण के नाम पर पंजीकृत किए जाएंगे 
                ताकि उन्हें तीसरे पक्ष के खरीदार को बेचा जा सके। कार्बन क्रेडिट के निर्माण के बाद से ही उन पर सभी अधिकार, शीर्षक और हित भारतरोहण के पास निहित हो जाएंगे।
                </p>

                <br/>

                <p>{language === 'english' ? 'Signature of Landowner' : 'भूस्वामी के हस्ताक्षर' }  - 
                    <select className={`col-md-2 form-control ${styles.lineInput} ${formik.touched.ownerSignType && formik.errors.ownerSignType ? styles.formInputError : ''}`} name='ownerSignType' value={formik.values.ownerSignType} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                        <option value=''>Select Signature type</option>
                        <option value='signature-pad'>Signature Pad</option>
                        <option value='sign-manually'>Sign By Name</option>
                    </select>
                </p>

                {
                    formik.values.ownerSignType === 'signature-pad' ? 
                    <div className=' row col-md-4'>
                        <ESignature signatureHandler={(uri) => formik.setFieldValue('ownerSign', uri)} />
                        { formik.values.ownerSign != '' ? <PreviewSignature uri={formik.values.ownerSign}/> : null }
                    </div> 
                        : formik.values.ownerSignType === 'sign-manually' ? <p className={styles.cedarvilleCursiveRegular}>{formik.values.ownerName}</p> : null
                }
                
                <p>{language === 'english' ? 'Date' : 'दिनांक' } - <input className={`col-md-3 form-control ${styles.lineInput} ${formik.touched.ownerDate && formik.errors.ownerDate ? styles.formInputError : ''}`} 
                type="date" name='ownerDate' 
                value={formik.values.ownerDate}
                onChange={formik.handleChange} 
                min="2024-02-01"
                max="2024-05-31"
                onBlur={formik.handleBlur}/></p>

                <br/>
                
                <p>{language === 'english' ? 'Accepted by' : 'स्वीकृतकर्ता' }, </p>
                <p>{language === 'english' ? 'Signature of Farmer' : 'खेती करने वाले किसान के हस्ताक्षर' } - 
                    <select className={`col-md-2 form-control ${styles.lineInput} ${formik.touched.farmerSignType && formik.errors.farmerSignType ? styles.formInputError : ''}`} name='farmerSignType' value={formik.values.farmerSignType}
                    onChange={formik.handleChange} onBlur={formik.handleBlur}>
                        <option value=''>Select Signature type</option>
                        <option value='signature-pad'>Signature Pad</option>
                        <option value='sign-manually'>Sign By Name</option>
                    </select>
                </p> 
                
                {
                    formik.values.farmerSignType === 'signature-pad' ? 
                    <div className=' row col-md-4'>
                        <ESignature signatureHandler={(uri) => formik.setFieldValue('farmerSign', uri)}/> 
                        { formik.values.farmerSign != '' ? <PreviewSignature uri={formik.values.farmerSign}/> : null }
                    </div> : formik.values.farmerSignType === 'sign-manually' ? <p className={styles.cedarvilleCursiveRegular}>{formik.values.farmerName}</p> : null
                }
                <p>{language === 'english' ? 'Date' : 'दिनांक' } - <input className={`col-md-3 form-control ${styles.lineInput} ${formik.touched.farmerDate && formik.errors.farmerDate ? styles.formInputError : ''}`} 
                type="date" 
                name='farmerDate' 
                value={formik.values.farmerDate} 
                onChange={formik.handleChange} 
                min="2024-02-01"
                max="2024-05-31"
                onBlur={formik.handleBlur}/></p>
                
                <button className="btn btn-secondary" type="submit" disabled={disabled}>{disabled === true ? 'Submitting...' : 'Submit'}</button> &nbsp;
                <button className="btn btn-danger" type="button" onClick={() => remove()}>Cancel</button>
            </form>

               
        </div>
    </div>

  )
}

export default UndertakingSign