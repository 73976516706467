import { useEffect, useState } from "react";
import { AWDStateBarChart } from "./AWDStateBarChart";
import { getAerationCounts } from "../../../APIs/apiCalls";
import AerationPieChart from "./AerationPieChart";
import FirstDry from "./FirstDry";


const AWDDashboard = ({data}) => {
    const [firstDryNumber, setFirstDryNumber] = useState(0);
    const [secondDryNumber, setSecondDryNumber] = useState(0);
    const [pipeInstallationNumber, setPipeInstallationNumber] = useState(0);
    useEffect(() => {
        (async()=>{
            let response = await getAerationCounts();
            setPipeInstallationNumber(response.pipeInstallments||0);
            setFirstDryNumber(response.firstDry||0);
            setSecondDryNumber(response.secondDry||0);
        })();
    },[]);
    return (
        <div>
            <div className="row">
                <AWDStateBarChart data={data}/>
            </div>
            <div className="row mt-3">
                <div className="col-md-4">
                    <AerationPieChart heading={'Pipe Installation Percent Stats'} number={pipeInstallationNumber} total={data.farmerCount}/>
                </div>
                <div className="col-md-4">
                    <AerationPieChart heading={'First Dry Percent Stats'} number={firstDryNumber} total={data.farmerCount}/>
                </div>
                <div className="col-md-4">
                    <AerationPieChart heading={'Second Dry Percent Stats'} number={secondDryNumber} total={data.farmerCount}/>
                </div>
            </div>
            {/* <div className="row mt-3">
                <FirstDry/>
            </div> */}
        </div>
    );
}

export default AWDDashboard;