import { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
// This component shows the barchart of crops vs farmers.
const BarChart = ({data}) => {
    const [data_, setData] = useState([]);
    const [labels, setLabels] = useState([]);
    let cropsObject = {};
    useState(() => {
        (async() => {
           
            for(let item of data.crops){
                cropsObject[item.crop] = item.numberOfFarmers;
            }
 
            setData(Object.values(cropsObject));
            setLabels(Object.keys(cropsObject));
        })();
    },[])
    return (
        <>
        <div className='dashboard-heading'>
            <h5 className="m-0 text-white">Crop cultivated by different farmers</h5>
        </div>
        <div className='dashboard-chart'>
             <Bar 
             
                options={{
                responsive: true,
                plugins: {
                    datalabels: {
                        display: true,
                        color: "black",
                        formatter: (value, context) => {  return `${value} farmer${value>1?'s' : ''}`  },
                        anchor: "end",
                        align: "start",
                        textStrokeWidth  : 0.6
                    },
                    legend: {
                        position: 'top',
                    },
                    title: {
                    display: true,
                    text: 'Bar Chart Shows about each crop services taken by different farmers',
                    position : 'top'
                    },
                },
            }} 
            
            data={{
                labels : labels,
                datasets: [
                  {
                    label: 'Number of Farmers',
                    data : data_,
                    backgroundColor: ['#d18a49','#769f34'],
                    borderWidth : 2,
                    borderColor : 'black'
                  }
                ],
            }} 
            plugins={[ChartDataLabels]}/>
        </div>

        </>
    );
}

export default BarChart;

// data={{
//     labels : ['paddy', 'ginger', 'mustard', 'cumin', 'potato'],
//     datasets: [
//       {
//         label: 'Number of Farmers',
//         data : [12050, 2100, 4250, 6150, 7100],
//         backgroundColor: ['#d18a49','#769f34'],
//         borderWidth : 2,
//         borderColor : 'black'
//       }
//     ],
// }} 