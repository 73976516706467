import { useCallback, useEffect, useState } from "react";
import { NotificationContainer, NotificationManager } from "react-notifications";

import { useFormik } from "formik";
import validate from "./validate";
import { addLandRecords, deleteFileFromSpace, updateLandRecords, uploadFileToSpace } from "../../../../APIs/apiCalls";
import styles from './LandRecordForm.module.css'
import SubmitButton from "../../../../Components/Buttons/SubmitButton";
import Dropzone, { useDropzone } from 'react-dropzone';
import AlertConfirm from "react-alert-confirm";
import ThreeLoader from "../../../../Components/Spinners/ThreeLoader";


const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  };
  
  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 180,
    height: 200,
    padding: 4,
    boxSizing: 'border-box'
  };
  
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow : 'hidden',
    flexDirection: 'column',
    alignItems: 'center'
  };
  
  const pdf = {
    width: '100%',
    height: '100%'
  };




const LandRecordForm = ({data, farmer, edit, changeRecords}) => {
    const [leasedFilesUploading, setLeasedFilesUploading] = useState(false);
    const [ownedFilesUploading, setOwnedFilesUploading] = useState(false);
    console.log(farmer);
    const initialValues = {
        farmerId : farmer._id,
        ownedLandArea : '',
        leasedLandArea : '',
        ownedLandKhasraNumbers : '',
        leasedLandKhasraNumbers : '',
        leasedDocuments : [],
        ownedDocuments : [],
    };
    
    const [disabled, setDisabled] = useState(false);

    const [rejectedFiles, setRejectedFiles] = useState({
        leasedDocuments : [],
        ownedDocuments : [],
    });

    const maxFileSize = process.env.REACT_APP_FILE_MAX_SIZE; //in kbs

    const formik = useFormik({
        initialValues,
        validate,
        onSubmit : (values) => {
            console.log(values);
            console.log(initialValues);
            setDisabled(true)
            
            if(values === initialValues){
                NotificationManager.error("Form Can't be empty"); return;
            }else{
                console.log(values);
                AlertConfirm({
                    title: 'Are you sure?',
                    desc: 'This cannot be undone.',
                    onOk: () => {
                        if(edit === true){
                            console.log("hit");
                            
                            updateLandRecords(data._id,values).then((response) => {
                                console.log(response);
                                changeRecords(response.data);
                                NotificationManager.success("Record Updated");
                            }).catch((error) => {
                                console.log(error);
                            })
                        }else{
                            addLandRecords(values).then((response) => {
                                console.log(response);
                                formik.resetForm(initialValues);
                                changeRecords(response.data);
                                NotificationManager.success("Record Added");
                            }).catch((error) => {
                                console.log(error);
                            })
                        }
                    },
                    onCancel: () => {
                      // console.log('cancel');
                    }
                });
                setDisabled(false);
            }
        }
    })
    
    // const addHandler = (field) => {
    //     let arr = formik.values[field];
    //     arr.push('');
    //     formik.setFieldValue(field,arr);
    // }

    // const inputChangeHandler = (event, index, field) => {
    //     let arr = formik.values[field];
    //     arr[index] = event.target.value;
    //     formik.setFieldValue(field, arr)
    // }

    const removeFunction =async (index_, field) => {
        let files = formik.values[field];
        let url = files[index_];
        console.log(url);
        
        const response = await deleteFileFromSpace({url});
        console.log(response);
        
        files.splice(index_,1);
        formik.setFieldValue(field, files);
    };

    const onDropAccepted = async (files, field) => {
        const arr = formik.values[field];
        const { village, block, tehsil, district, farmername }  = farmer;
    
        field !== 'leasedDocuments' ? setOwnedFilesUploading(true) : setLeasedFilesUploading(true);
        for(let file of files) {
            let fileName = `${farmername}_${village}_${tehsil}_${block}_${district}_${Date.now()}`.split(' ').join('').toUpperCase() + '.pdf';         
            
            const formData = new FormData();
            formData.append('file', file);
            formData.append('filename', fileName);
            formData.append('folder', 'lands');

            const response = await uploadFileToSpace(formData);
            
            arr.push(response.url);
            formik.setFieldValue(field, arr);
        }
        
        
        field !== 'leasedDocuments' ? setOwnedFilesUploading(false) : setLeasedFilesUploading(false);
    }

    const onDropRejected = (rejectedFiles, field) => {
        console.log(rejectedFiles);
        
        field === 'leasedDocuments' ? 
        setRejectedFiles((prevState) => ({...prevState, leasedDocuments : [...prevState.leasedDocuments, ...rejectedFiles] })) : 
        setRejectedFiles((prevState) => ({...prevState, ownedDocuments : [...prevState.ownedDocuments, ...rejectedFiles] }));
        console.log(rejectedFiles);
    }

    const DropZone = ({field}) => {
        return (
            <Dropzone onDropRejected={(rejectedFiles) => onDropRejected(rejectedFiles, field)} 
            onDropAccepted={(acceptedFiles) => onDropAccepted(acceptedFiles, field)} 
            multiple={true} accept={ {'application/pdf': []}} maxSize={maxFileSize*1024}>

                {({getRootProps, getInputProps}) => (
                    <div {...getRootProps({ className: 'dropzone' })} style={{backgroundColor : '#e5e4e2'}}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                        <em>(Only *.pdf files will be accepted)</em>
                    </div>
                )}
                
            </Dropzone>
        )
    }

    const ReturnDocuments = ({field}) => {
        useEffect(() => {}, [formik.values[field]])
        return formik.values[field].map((file, index) => (
            <div key={index} style={thumb}>
              <div style={thumbInner}>
                <embed
                  src={file}
                  style={pdf}
                  type="application/pdf"
                  alt="image"
                />
                <div>
                    <button type="button" className="btn btn-sm btn-danger mt-1" onClick={() => removeFunction(index, field)}><i className="typcn typcn-delete"></i></button> &nbsp;
                    <a className="btn btn-sm btn-info mt-1" href={file} target="_blank" ><i className="typcn typcn-eye"></i></a>
                </div>
              </div>
             </div>
        ));
    }

    const FileRejectionItems = ({field}) => {
        
        return <ol style={{color : 'red'}}>
        {
            rejectedFiles[field].map(({ file, errors }, index) => (
                <li key={index}>
                  Filename - {file.path} - {file.size} bytes
                  <ul>
                    {errors.map(({code, message}, index_) => <><li key={index_}>Error Code : {code}</li>
                    <li key={index_}>Error Message : {message}</li>
                    </>)}
                  </ul>
                </li>
            ))    
        }
        </ol>
    }



    

    useEffect(()=>{ 
        console.log("hit")
        formik.setValues(initialValues);
        
        if(edit === true){ 
            const {ownedLandKhasraNumbers, leasedLandKhasraNumbers} = data;
            console.log(ownedLandKhasraNumbers);
            
            data.leasedLandKhasraNumbers = leasedLandKhasraNumbers.join(', ');
            data.ownedLandKhasraNumbers = ownedLandKhasraNumbers.join(', ');

            formik.setValues(data);
        }
    },[edit, farmer]);

    return (
        <div>
            
            <h4>Land Records Form</h4>
            <form id="myForm" className="p-0" onSubmit={formik.handleSubmit}>
                <div className="col-md-12 mt-2 pt-3">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Owned Land Area(Acres)</label>
                                <div className="col-sm-8">
                                <input type="number" min={0} className={`form-control ${ formik.touched.ownedLandArea===true && formik.errors.ownedLandArea ? styles.formInputError : "" }`} placeholder="Owned Land Area in Acres" id='ownedLandArea' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.ownedLandArea}  step="any"/> {formik.touched.ownedLandArea === true && formik.errors.ownedLandArea ? <div className={styles.error}>{formik.errors.ownedLandArea}</div> : ""}
                                </div>
                                {formik.touched.ownedLandArea === true && formik.errors.ownedLandArea ? <div className={styles.error}>{formik.errors.ownedLandArea}</div> : ""}
                            </div>
                        </div> 
                    </div>
                    <div className='row'>
                        <div className="col-md-6">
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Khasra Numbers</label>
                                <div className="col-sm-8">
                                    <input type="text" className={`form-control ${ formik.touched.ownedLandKhasraNumbers===true && formik.errors.ownedLandKhasraNumbers ? styles.formInputError : "" }`} placeholder="Owned Land Khasra Numbers" id='ownedLandKhasraNumbers' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.ownedLandKhasraNumbers}  step="any"/> {formik.touched.ownedLandKhasraNumbers === true && formik.errors.ownedLandKhasraNumbers ? <div className={styles.error}>{formik.errors.ownedLandKhasraNumbers}</div> : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-md-2 col-form-label">Upload Owned Agreements - </label>
                        <section className="col-md-4">
                            <DropZone field={'ownedDocuments'}/>
                        </section>
                    </div>
                    
                    <div>
                        <aside style={thumbsContainer}>
                            <ReturnDocuments field={'ownedDocuments'}/>
                        </aside>
                        {
                            ownedFilesUploading === true ?
                            <span className="text-info">Uploading..<ThreeLoader/> </span> : null
                        }
                    </div>
                    <div>
                            <FileRejectionItems field={'ownedDocuments'}/>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Leased Land Area(Acres)</label>
                                <div className="col-sm-8">
                                <input type="number" min={0} className={`form-control ${ formik.touched.leasedLandArea===true && formik.errors.leasedLandArea ? styles.formInputError : "" }`} placeholder="Leased Land Area in Acres" id='leasedLandArea' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.leasedLandArea} step="any"/> {formik.touched.leasedLandArea === true && formik.errors.leasedLandArea ? <div className={styles.error}>{formik.errors.leasedLandArea}</div> : ""}
                                </div>
                                {formik.touched.leasedLandArea === true && formik.errors.leasedLandArea ? <div className={styles.error}>{formik.errors.leasedLandArea}</div> : ""}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-6">
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Khasra Numbers</label>
                                <div className="col-sm-8">
                                    <input type="text" className={`form-control ${ formik.touched.leasedLandKhasraNumbers===true && formik.errors.leasedLandKhasraNumbers ? styles.formInputError : "" }`} placeholder="Leased Land Khasra Numbers" id='leasedLandKhasraNumbers' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.leasedLandKhasraNumbers}  step="any"/> {formik.touched.leasedLandKhasraNumbers === true && formik.errors.leasedLandKhasraNumbers ? <div className={styles.error}>{formik.errors.leasedLandKhasraNumbers}</div> : ""}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <label className="col-md-2 col-form-label">Upload leased Agreements</label>
                        <section className="col-md-4">
                            <DropZone field={'leasedDocuments'}/>
                        </section>
                    </div>


                    <div>
                        <aside style={thumbsContainer}>
                            <ReturnDocuments field={'leasedDocuments'}/>
                        </aside>
                        {
                            leasedFilesUploading === true ?
                            <span className="text-info">Uploading..<ThreeLoader/> </span>  : null
                        }
                    </div>
                    <div>
                        <FileRejectionItems field={'leasedDocuments'}/>
                    </div>
                    
                    
                    <SubmitButton disabled={disabled}/> &nbsp;
                    <button type="reset" id="resetbtn" className="btn btn-danger" onClick={() => formik.resetForm(initialValues)}>Reset</button>
                </div>
            </form>
            <NotificationContainer/>
        </div>
    );
}

export default LandRecordForm;
