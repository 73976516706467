const validate = (values) => {
  
    let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
    const errors = {};
    if(values.state === ""){
        errors.state = "State Name is required";
    }
    if(values.district === ""){
        errors.district = "District name is required";
    }
    if(values.tehsil === ""){
        errors.tehsil = "Tehsil is required";
    }
    if(values.block === ""){
        errors.block = "Block is required";
    }
    else if(values.village === ""){
        errors.village = "Village is required";
    }
    return errors;
}

export default validate;