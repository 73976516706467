import { useFormik } from "formik";
import styles from './Documents.module.css';
import { compressImage, generateDataURIFromImage, getFormData } from "../../Utils/commonFunctions";
import DisplayFile from "./DisplayFile";
import { addDocument } from "../../APIs/apiCalls";
import { NotificationManager } from "react-notifications";
import { acceptedMimeTypes } from "./options";
import { useState } from "react";
import SubmitButton from "../../Components/Buttons/SubmitButton";

const validate = (values) => {
    const errors = {};

    let acntRegex = new RegExp(/^[0-9]{9,18}$/);
    let ifscRegex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);


    if(values.bankName === ''){
        errors.bankName = 'Bank Name is required.';
    }

    if(values.branchName === ''){
        errors.branchName = 'Branch Name is required.'
    }


    if(values.accountNumber === ''){
        errors.accountNumber = 'Account Number is required.';
    }else if(acntRegex.test(values.accountNumber) === false){
        errors.accountNumber = 'Account Number is invalid.';
    }
    
    if(values.ifsc === ''){
        errors.ifsc = 'IFSC Code is required.';
    }else if(ifscRegex.test(values.ifsc) === false){
        errors.ifsc = 'IFSC Code is invalid.';
    }
    if(values.document === ''){
        errors.document = 'Bank Passbook is required.';
    }
    return errors;
}

const BankDetailsForm = ({type, farmerId, farmer, reset}) =>{
    const [disabled, setDisabled] = useState(false);
    const formik = useFormik({
        initialValues : {
            bankName : '',
            branchName : '',
            accountNumber : '',
            ifsc : '',
            document : '',
            uri : ''
        },
        validate,
        onSubmit : async(values) => {
            // console.log(values);
            if(farmerId===undefined){
                NotificationManager.error("Farmer Id is required.")
            }
            const obj = {...values, type, farmerId};
            const entry = getFormData(obj);
            setDisabled(true);
            const response = await addDocument(entry);
            setDisabled(false);
            if(response.result === 'success'){
                formik.resetForm({});
                reset();
                NotificationManager.success('Document saved Successfully');
            }
        }
    });
    const maxFileSize = process.env.REACT_APP_FILE_MAX_SIZE; //in kbs
    const changeHandler = async (event) => {
        let file = event.target.files[0];
        if(file.type != 'application/pdf'){
            file = await compressImage(file);
        }
        if(file){
            const {size, type} = file;
            
            //convert size(bytes) to kbs.
            const fileSizeInKB = size / 1024;
            if(acceptedMimeTypes.includes(type) === false){
                formik.setFieldError('document',`File extension should be pdf, jpg or png.`);
            }else if(fileSizeInKB > maxFileSize){
                formik.setFieldError('document',`Maximum size limit is ${maxFileSize/1024}mb`);
            }else{
                formik.setFieldValue('document', file);
            }
        }
    }
    return (
        <form className="mt-5"  onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <h5>Upload Bank Details</h5>
            <div className="row">
                <div className="col-md-6">
                    <label className="col-form-label">Beneficiary Bank Name*</label>
                    <input type="text" 
                    className={`form-control ${formik.touched.bankName === true && formik.errors.bankName ? styles.formInputError : ''}`} 
                    id="bankName"
                    placeholder="Beneficiary Bank Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.bankName}
                    />
                    {formik.touched.bankName === true && formik.errors.bankName ? <div className={styles.error}>{formik.errors.bankName}</div> : ""}
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Branch Name*</label>
                    <input type="text" 
                    className={`form-control ${formik.touched.branchName === true && formik.errors.branchName ? styles.formInputError : ''}`} 
                    id="branchName"
                    placeholder="Branch Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.branchName}
                    />
                    {formik.touched.branchName === true && formik.errors.branchName ? <div className={styles.error}>{formik.errors.branchName}</div> : ""}
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Account Number*</label>
                    <input type="text" 
                    className={`form-control ${formik.touched.accountNumber === true && formik.errors.accountNumber ? styles.formInputError : ''}`} 
                    id="accountNumber"
                    placeholder="Account Number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.accountNumber}
                    />
                    {formik.touched.accountNumber === true && formik.errors.accountNumber ? <div className={styles.error}>{formik.errors.accountNumber}</div> : ""}
                </div>
                <div className="col-md-6">
                <label className="col-form-label">IFSC Code*</label>
                    <input type="text"
                    placeholder="IFSC Code" 
                    className={`form-control ${formik.touched.ifsc === true && formik.errors.ifsc ? styles.formInputError : ''}`} 
                    id="ifsc"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.ifsc}
                    />
                    {formik.touched.ifsc === true && formik.errors.ifsc ? <div className={styles.error}>{formik.errors.ifsc}</div> : ""}
                </div>
                <div className="col-md-12">
                    <label className="col-form-label">Bank Passbook* <span className="text-warning"><i className="typcn typcn-warning"></i>Maximum file size limit is {maxFileSize/1024}mb</span></label>
                    <input type="file" 
                    className={`form-control ${formik.touched.document === true && formik.errors.document ? styles.formInputError : ''}`} 
                    id="document"
                    placeholder="Bank Passbook"
                    onChange={(event) => changeHandler(event)}
                    onBlur={formik.handleBlur}
                    />
                    {formik.touched.document === true && formik.errors.document ? <div className={styles.error}>{formik.errors.document}</div> : ""}
                    {
                        formik.values.document!="" && <DisplayFile mimetype = {formik.values.document.type} uri={URL.createObjectURL(formik.values.document)} />
                    }
                </div>
                <div className="col-md-12">
                    <SubmitButton disabled={disabled}/> &nbsp;
                    <button type="reset" id="resetbtn" className="btn btn-danger mt-2">Reset</button>
                </div>
            </div>
        </form>
    )
}

export default BankDetailsForm;