import { useState } from "react";
import Switch from "react-switch";

const ToggleSwitch = ({ischecked, change}) => {
    const [checked, setChecked] = useState(false);
    const handleChange = () => {
        change(!checked);
        setChecked(!checked);
    }
    return (
        <div className='mb-3'>
            <span className='' style={{color : 'green', fontWeight : 'bolder'}}>ONLY AWD DASHBOARD</span>
            <Switch onChange={() => handleChange()} checked={checked} className='dashboard-switch'/>
        </div>
    );
}

export default ToggleSwitch;