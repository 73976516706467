import { useFormik } from "formik";
import Select,  { createFilter } from 'react-select';
import styles from './../ManageMembers/ManageMembers.module.css';
import { useEffect, useState } from "react";
const filterConfig = {
    ignoreCase : true,
    matchFrom: 'start',
};

const validate = (values) => {
    const errors = {};
    console.log(values)

    if(values.state === ''){
        errors.state = 'State is required'
    }
    if(values.district === ''){
        errors.district = 'district is required'
    }
    if(values.village === ''){
        errors.village = 'village is required'
    }
    if(values.tehsil === ''){
        errors.tehsil = 'tehsil is required'
    }
    if(values.block === ''){
        errors.block = 'block is required'
    }
    return errors;
}

const Location = ({add, location}) => {
    const[options, setOptions] = useState([]);
    const formik = useFormik({
        initialValues : {
            state : '',
            district : '',
            village : '',
            tehsil : '',
            block : '',
        },
        validate,
        onSubmit : (values) => {
            console.log(values);
            add(values)
        }
    })
    const createLabelsAndValues = (value, locationArray) => {
        formik.setFieldValue('block', value)
        let arr = Array.from(new Set(locationArray.filter(item=> item.block === value))).sort((a,b) => a.village.localeCompare(b.village)).map((obj) => obj['village']);
        let optns = [];
        for(let item of arr){
          let obj = { value : item, label : item.toUpperCase() };
          optns.push(obj);
        }
        setOptions(optns);
        return optns;
      }

      useEffect(()=>{

      },[]);
    return (
        
            <form onSubmit={formik.handleSubmit}>
            <h5 className="card-title">Location Details</h5>
            <div className="row">
                <div className="col-md-6">
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">State*</label>
                    <div className="col-sm-9">
                    <select className={`form-control ${ formik.touched.state===true && formik.errors.state ? styles.formInputError : "" }`} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.state} id='state'>
                        <option value="">Select State</option> { Array.from(new Set(location.map(item => item.state))).map((item, index) => <option key={index} value={item}>{item.toUpperCase()}</option>) }
                    </select> {formik.touched.state === true && formik.errors.state ? <div className={styles.error}>{formik.errors.state}</div> : ""}
                    </div>
                </div>
                </div>
                <div className="col-md-6">
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">District*</label>
                    <div className="col-sm-9">
                    <select className={`form-control ${ formik.touched.district===true && formik.errors.district ? styles.formInputError : "" }`} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.district} id='district'>
                        <option value="">Select District</option> { Array.from(new Set(location.filter(item => item.state === formik.values.state).map(item => item.district))).map((item, index) => <option value={item} key={index}>{item.toUpperCase()}</option>) }
                    </select> {formik.touched.district === true && formik.errors.district ? <div className={styles.error}>{formik.errors.district}</div> : ""}
                    </div>
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Tehsil*</label>
                    <div className="col-sm-9">
                    <select className={`form-control ${ formik.touched.tehsil===true && formik.errors.tehsil ? styles.formInputError : "" }`} id='tehsil' placeholder="Tehsil" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.tehsil}>
                        <option value=''>Select a Tehsil</option> { Array.from(new Set(location.filter(item => item.district === formik.values.district).map(item => item.tehsil))).map((item, index) => <option value={item} key={index}>{item.toUpperCase()}</option>) }
                    </select> {formik.touched.tehsil === true && formik.errors.tehsil ? <div className={styles.error}>{formik.errors.tehsil}</div> : ""}
                    </div>
                </div>
                </div>
                <div className="col-md-6">
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Block*</label>
                    <div className="col-sm-9">
                    <select className={`form-control ${ formik.touched.block===true && formik.errors.block ? styles.formInputError : "" }`} placeholder="Block" id='block' onChange={(event) => createLabelsAndValues(event.target.value, location)} onBlur={formik.handleBlur} value={formik.values.block}>
                        <option value=''>Select a Block</option> { Array.from(new Set(location.filter(item => item.tehsil === formik.values.tehsil).map(item => item.block))).map((item, index) => <option value={item} key={index}>{item.toUpperCase()}</option>) }
                    </select> {formik.touched.block === true && formik.errors.block ? <div className={styles.error}>{formik.errors.block}</div> : ""}
                    </div>
                </div>
                </div>
            </div>
            <div className="row"> 
            <div className="col-md-6">
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Village*</label>
                    <div className="col-sm-9">
                    <Select className={ formik.touched.village===true && formik.errors.village ? styles.formInputError : "" } 
                    id='village' 
                    value={formik.values.village}
                    options={options} isClearable={true} 
                    isSearchable={true} onBlur={formik.handleBlur} 
                    placeholder="Search." 
                    onChange={(event) => formik.setFieldValue('village', event)} 
                    filterOption={createFilter(filterConfig)} /> 
                    {formik.touched.village === true && formik.errors.village ? <div className={styles.error}>{formik.errors.village}</div> : ""}
                    </div>
                </div>
                </div>
            </div>
            <button className="btn btn-sm btn-secondary" type="submit" onClick={formik.handleSubmit}>Add</button>
            </form>
      
    );
}

export default Location;