import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { DialogTitle } from "@mui/material/node";

const ImagePreview = ({imageUrl, open, onClose}) => {
    return (
        <Dialog open={open} style={{backgroundColor : 'black'}}>
            <DialogTitle textAlign="center">Preview Image</DialogTitle>
            <DialogContent>
                <div>
                    <img src={imageUrl} height={500} width={500}/>
                </div>
                
                <DialogActions sx={{ p: '1.25rem' }}>
                  <Button onClick={onClose}>
                    Cancel
                  </Button>
                  {/* <Button  color="success" variant="contained" type='submit'>
                      Save
                  </Button> */}
              </DialogActions>
            </DialogContent>
        </Dialog>
    );
}

export default ImagePreview;