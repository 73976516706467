

import Layout from "../../../Layout";
import PolygonTable from "./PolygonTable";


const ManagePolygon = () => {
    return (
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row" style={{paddingTop : '80px'}}>
                            <div className="col-12 grid-margin">
                                <div className="card">
                                    <div className="card-body1">
                                        <h4>Polygon Records</h4>
                                        <PolygonTable />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>   
    );
}

export default ManagePolygon;