const LocationDeniedError = () => {
  return (
    <div className="col-12 grid-margin">
        <div className="card">
            <div className="card-body text-danger" style={{fontStyle : 'italic'}}>
                <h6 style={{fontWeight : 'bolder', color : "black"}}>USER DENIED LOCATION ACCESS 🚫🚫🚫</h6>
                <p>1. Kindly give access to the location  to continue with the operations. </p>
                <p>2. Go to the mobile settings and turn on your GPS "<i className="typcn typcn-location"></i>"</p>
                <p>3. Kindly Refresh the page, then carry forward with your operations.</p>
            </div>
        </div>
    </div>
  );
}

export default LocationDeniedError;