import { useState } from "react";
import { downloadFile } from "../../../../Utils/commonFunctions";
import ImagePreview from "./ImagePreview";
import moment from "moment";
const DryFeildInfo = ({index, operation, value, change}) => {
    // console.log(value);
    const [filePreviewUrl, setFilePreviewUrl] = useState('');
    const [open, setOpen] = useState('');
    const userType = localStorage.getItem('type');
    console.log(userType)
    if(!value){
        return <></>;
    }
    return (
        <div className="m-2 p-3 px-4" style={{backgroundColor : 'rgb(244 244 244)', border : '3px solid green'}}>
                    <div className="row justify-content-between mb-2">
                        <h5 className="text-success">Feild - {index + 1} - Success </h5>
                        <div>
                            {/* user should role not be  viewer*/}
                            { userType != 'viewer' && <><button className="btn btn-success p-2" onClick={() =>change('edit')}  data-toggle="tooltip" data-placement="top" title="Edit">  <i className="typcn typcn-edit"></i></button> &nbsp;</> }
                            
                            {/* user should not be viewer/editor */}
                            { (userType === 'admin' || userType === 'super-admin') && 
                            <button className="btn btn-danger p-2" onClick={() => change('delete')}>  <i className="typcn typcn-trash"></i></button> }
                        </div>
                    </div>
                    <p><b>Date of {operation} :</b> {moment(value.date_).format('DD-MM-YYYY')}</p>
                    <div className="row">
                        <div className="col-md-6">
                            <p><b>Field Photo Source</b> : {value.type1 === 'file' ? 'File' : 'Camera'}</p>
                            <p><b>Photo of the Field : </b></p>
                            <img className="image-preview-css" src={value.file1} height={150} width={200} onClick={() => {setFilePreviewUrl(value.file1); setOpen(true)}} />
                            <p><button className="btn btn-sm btn-bluish-green mt-2" onClick={() => downloadFile(value.file1)}>Download <i className="typcn typcn-download"></i></button></p>
                        </div>
                        
                        <div className="col-md-6">
                            <p><b>Pipe Photo Source :</b> {value.type1 === 'file' ? 'File' : 'Camera'}</p>
                            <p><b>Photo of the Pipe : </b></p>
                            <img className="image-preview-css" src={value.file2} height={150} width={200} onClick={() => {setFilePreviewUrl(value.file2); setOpen(true)}}/>
                            <p><button className="btn btn-sm btn-bluish-green mt-2" onClick={() => downloadFile(value.file2)}>Download <i className="typcn typcn-download"></i></button></p> 
                        </div>
                    </div>
                
                
           
            <ImagePreview
                imageUrl={filePreviewUrl}
                open={open}
                onClose={() => setOpen(false)}
            />
        </div>
    );
}

export default DryFeildInfo;