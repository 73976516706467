import styles from './WarningMessage.module.css';
const WarningMessage = (props) => {
   
    return (
        <div>
             <span className={styles.warningMessage}><i className="typcn typcn-warning"></i> Please ensure that you click on the "Add" button upon completing the form, as failure to do so will result in the information not being saved.</span>
        </div>
    );
}

export default WarningMessage;