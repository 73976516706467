import MaterialReactTable from "material-react-table";
import { useEffect, useState } from "react";
import { deleteAerationDetails, exportCSV, getAerationCounts, getAerationDetails, getTableData, getfarmers } from "../../../../APIs/apiCalls";
import { useNavigate } from "react-router-dom";
import { NotificationContainer, NotificationManager } from "react-notifications";
import AlertConfirm from "react-alert-confirm";
import Spinners1 from "../../../../Components/Spinners/Spinners1";
import { mkConfig, generateCsv, download } from "export-to-csv";
import {  createDownloadLinks, downloadFile} from "../../../../Utils/commonFunctions";
import moment from "moment";
import { fetchUrl } from "../../../../APIs/server";
import ClipLoader from "react-spinners/ClipLoader";
import ProfileLink from "../../../../Atoms/ProfileLink";
const url_ = fetchUrl;

const columnHeaders = [
    { key : "farmername", displayLabel : "Farmer Name" }, 
    { key : "status", displayLabel : "Pipe Selection Status" }, 
    { key : "tehsil", displayLabel : "Tehsil" }, 
    { key : "village", displayLabel : "Village" }, 
    { key : "pipesRequired", displayLabel : "Pipes Required" }, 
    { key : "pipeStatus", displayLabel : "Pipe Installed Status" },  
    { key : "firstDryStatus", displayLabel : "First Dry Status" }, 
    { key : "secondDryStatus", displayLabel : "Second Dry Status" }, 
]

const csvConfig = mkConfig({ 
    filename : "Aeration",
    columnHeaders
});


const AerationTable = () => {
    const [flag, setFlag] = useState(false);
    const navigate = useNavigate();
    const userType = localStorage.getItem('type');
    const [data, setData] = useState(undefined);

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [success, setSuccess] = useState(0);

    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
   
    const [pipeInstallationLength, setPipeInstallationLength] = useState(-1);
    const [firstDryLength, setFirstDryLength] = useState(-1);
    const [secondDryLength, setSecondDryLength] = useState(-1);
    const columns = [
        {
            accessorKey: 'farmername',
            header: 'Farmer Name',
            Cell : (data) => {
                const {_id, farmername} = data.row.original;
                return (
                    <> <ProfileLink _id={_id} farmername={farmername}/> </>
                )
            }
        },
        {
            accessorKey: 'guardian',
            header: 'Guardian Name',
            Cell : ({cell}) => {
                return (
                    <span>{cell.getValue().toUpperCase()}</span>
                )
            }
        },
        {
            accessorKey: 'isAWDFarmer',
            header: 'IS AWD Farmer',
            Cell : ({cell}) => {
                return (
                    <span>{cell.getValue() === true ? 'TRUE' : 'FALSE'}</span>
                )
            }
        },
        {
            accessorKey : 'tehsil',
            header : 'Tehsil',
            Cell : (data) => {
                const {tehsil} =  data.row.original;
                return <>{tehsil!=undefined ? tehsil.toUpperCase() : <span className="text-danger">Not Entered</span> }</>
            }
        },
        {
            accessorKey : 'village',
            header : 'Village',
            Cell : (data) => {
                const {village} =  data.row.original;
                return <>{village!=undefined ? village.toUpperCase() : <span className="text-danger">Not Entered </span> }</>
            }
        },
        {
            header : 'Pipes Required',
            
            Cell : (data) => {
                const val = data.row.original.aeration?.pipesRequired;
                return (
                    <>{val!=undefined ? val : <span className="text-warning" style={{fontStyle : 'italic'}}><i className="typcn typcn-warning"></i>Pipe not Selected</span>}</>
                )
            }
        },
        {
           
            header : 'Pipe Installments Documents',
            Cell : (data) => {
                const val = data.row.original.aeration?.pipeInstallments;
                const pipesRequired = data.row.original.aeration?.pipesRequired;
                return (
                    <>{
                        pipesRequired!=undefined ? 
                        <div>
                            {
                                val?.map((item, index) => <>{ item.date_ !="" ? <>
                                <p>{index+1}. <span style={{fontSize:'22px'}}><i className="typcn typcn-calendar"></i></span> {moment(item.date_).format('DD-MM-YYYY')}</p>
                                <p>Field Photo : </p>
                                <button className="btn btn-sm btn-primary" onClick={() => downloadFile(item.file)}><i className="typcn typcn-download"></i></button> &nbsp;
                                <a className="btn btn-sm btn-info" href={item.file} target="_blank" ><i className="typcn typcn-export"></i></a>
                                <p>Pipe Photo : </p>
                                <button className="btn btn-sm btn-primary" onClick={() => downloadFile(item.file2)}><i className="typcn typcn-download"></i></button> &nbsp;
                                <a className="btn btn-sm btn-info" href={item.file2} target="_blank" ><i className="typcn typcn-export"></i></a>
                                <hr/></> : <p className="text-danger">Pending <i className="typcn typcn-warning"></i></p> }</>)
                            }
                        </div>
                        : <span className="text-warning" style={{fontStyle : 'italic'}}><i className="typcn typcn-warning"></i>Pipe not Selected</span>}</>
                )
            }            
        },
        {
            header : 'Pipe Installments',
            Cell: (data) => {
                const val = data.row.original.aeration?.pipeInstallments;
                const pipesRequired = data.row.original.aeration?.pipesRequired;
                let number = 0;
                let number2 = 0;
                if (val !== undefined && val !== null) {
                    val.forEach(element => {
                        if (element.date_ !== "") {
                            number++;
                        } else {
                            number2++;
                        }
                    });
                }
                return (
                    <>{pipesRequired !== undefined ? 
                        <div>
                            <p><span className="badge bg-success text-white">{number} Success <i className="typcn typcn-input-checked"></i></span></p><p></p><hr/> <p><span className="badge bg-danger text-white">{number2} Pending <i className="typcn typcn-warning"></i></span></p>
                        </div>
                     : <span className="text-warning" style={{fontStyle : 'italic'}}><i className="typcn typcn-warning"></i>Pipe not Selected</span>}</>
                )
            }            
        },
        {
            accessorKey : 'pipeInstallationStatus',
            header : 'Pipe Installed Status',
            Cell: (data) => {
                const val = data.row.original.aeration;
                const pipesRequired = data.row.original.aeration?.pipesRequired;

                return (
                    <>
                        {
                            pipesRequired === undefined ? 
                            <span className="text-warning" style={{fontStyle : 'italic'}}><i className="typcn typcn-warning"></i>Pipe not Selected</span> : 
                            <div>
                                <p>
                                    {
                                        val.pipeInstallments.findIndex((item_) => item_.date_ === '') === -1 ? <p className="text-success">Success ✅</p> : 
                                        <span className="text-danger">Pending ⚠️</span>
                                    }
                                </p> 
                            </div> 
                        }
                    </>
                )
            },           
        },
        {
            header : 'First Dry Documents',
            Cell : (data) => {
                const val = data.row.original.aeration?.firstDry;
                const pipesRequired = data.row.original.aeration?.pipesRequired;
                return (
                    <>{
                        pipesRequired!=undefined ? 
                        <div>
                            {
                                val?.map((item, index) => <>{ item.date_ !="" ? <>
                                <p>{index+1}. <span style={{fontSize:'22px'}}><i className="typcn typcn-calendar"></i></span> {moment(item.date_).format('DD-MM-YYYY')}</p>
                                <p>Field Photo : </p>
                                <button className="btn btn-sm btn-primary" onClick={() => downloadFile(item.file1)}><i className="typcn typcn-download"></i></button> &nbsp;
                                <a className="btn btn-sm btn-info" href={item.file1} target="_blank" ><i className="typcn typcn-export"></i></a>
                                <p>Pipe Photo : </p>
                                <button className="btn btn-sm btn-primary" onClick={() => downloadFile(item.file2)}><i className="typcn typcn-download"></i></button> &nbsp;
                                <a className="btn btn-sm btn-info" href={item.file2} target="_blank" ><i className="typcn typcn-export"></i></a>
                                <hr/></> : <p className="text-danger">Pending <i className="typcn typcn-warning"></i></p> }</>)
                            }
                        </div>
                        : <span className="text-warning" style={{fontStyle : 'italic'}}><i className="typcn typcn-warning"></i>Pipe not Selected</span>}</>
                )
            }
        },
        {
            header : 'First Dry Statistics',
            Cell: (data) => {
                const val = data.row.original.aeration?.firstDry;
                const pipesRequired = data.row.original.aeration?.pipesRequired;

                let number = 0;
                let number2 = 0;
                if (val !== undefined && val !== null) {
                    val.forEach(element => {
                        if (element.date_ !== "") {
                            number++;
                        } else {
                            number2++;
                        }
                    });
                }
                return (
                    <>{pipesRequired !== undefined ? 
                        <>
                        {
                            val ? <div>
                                        <p><span className="badge bg-success text-white">{number} Success <i className="typcn typcn-input-checked"></i></span></p><p></p><hr/> <p><span className="badge bg-danger text-white">{number2} Pending <i className="typcn typcn-warning"></i></span></p>
                                 </div> : ''
                        }
                        </>
                        : <span className="text-warning" style={{fontStyle : 'italic'}}><i className="typcn typcn-warning"></i>Pipe not Selected</span>}</>
                )
            }            
        },

        {
            accessorKey : 'firstDryStatus', 
            header : 'First Dry Status',
            Cell: (data) => {
                const val = data.row.original.aeration;
                const pipesRequired = data.row.original.aeration?.pipesRequired;

                return (
                    <>
                    {
                        pipesRequired!=null ? <>
                         <p>{val?.firstDry.findIndex((item_) => item_.date_ === '') === -1 ? <p className="text-success">Success ✅</p> : 
                            <span className="text-danger">Pending ⚠️</span>}</p>  
                        </> : 
                        <span className="text-warning" style={{fontStyle : 'italic'}}><i className="typcn typcn-warning"></i>Pipe not Selected</span>
                    }
                    </>
                )
            },           
        },
        
        {
            header : 'Second Dry Documents',
            Cell : (data) => {
                const val = data.row.original.aeration?.secondDry;
                const pipesRequired = data.row.original.aeration?.pipesRequired;
                return (
                    <>{pipesRequired != undefined ? 
                        <div>
                             {
                                val?.map((item, index) => <>{ item.date_ !="" ? <><p>{index+1}. <span style={{fontSize:'22px'}}><i className="typcn typcn-calendar"></i></span> {moment(item.date_).format('DD-MM-YYYY')}</p>
                                <p>Field Photo : </p>
                                <button className="btn btn-sm btn-primary" onClick={() => downloadFile(item.file1)}><i className="typcn typcn-download"></i></button>  &nbsp;
                                <a className="btn btn-sm btn-info" href={item.file1} target="_blank" ><i className="typcn typcn-export"></i></a>
                                <p>Pipe Photo : </p>
                                <button className="btn btn-sm btn-primary" onClick={() => downloadFile(item.file2)}><i className="typcn typcn-download"></i></button> &nbsp;
                                <a className="btn btn-sm btn-info" href={item.file2} target="_blank" ><i className="typcn typcn-export"></i></a>
                                <hr/></> : <p className="text-danger">Pending <i className="typcn typcn-warning"></i></p> }</>)
                            }
                        </div>
                        : <span className="text-warning" style={{fontStyle : 'italic'}}><i className="typcn typcn-warning"></i>Pipe not Selected</span>}</>
                )
            }
        },
        
        {
            header : 'Second Dry Statistics',
            Cell: (data) => {
                const val = data.row.original.aeration?.secondDry;
                const pipesRequired = data.row.original.aeration?.pipesRequired;
                let number = 0;
                let number2 = 0;
                if (val !== undefined && val !== null) {
                    val.forEach(element => {
                        if (element.date_ !== "") {
                            number++;
                        } else {
                            number2++;
                        }
                    });
                }
                return (
                    <>{pipesRequired !== undefined ? 
                        <div>
                             <p><span className="badge bg-success text-white">{number} Success <i className="typcn typcn-input-checked"></i></span></p><p></p><hr/> <p><span className="badge bg-danger text-white">{number2} Pending <i className="typcn typcn-warning"></i></span></p>
                        </div>
                        : <span className="text-warning" style={{fontStyle : 'italic'}}><i className="typcn typcn-warning"></i>Pipe not Selected</span>}</>
                )
            }            
        },
        
        {
            accessorKey : 'secondDryStatus', 
            header : 'Second Dry Status',
           
            Cell: (data) => {
                const val = data.row.original.aeration;
                const pipesRequired = data.row.original.aeration?.pipesRequired;
                return (
                    <>
                        {
                            pipesRequired === undefined ? 
                            <span className="text-warning" style={{fontStyle : 'italic'}}><i className="typcn typcn-warning"></i>Pipe not Selected</span> :
                            <p>{val?.secondDry.findIndex((item_) => item_.date_ === '') === -1 ? <p className="text-success">Success ✅</p> : 
                            <span className="text-danger">Pending ⚠️</span>}</p>  
                        }
                    </>
                )
            },           
        }
    ];
   
    const handleCancelRowEdits = (row) => {
        // console.log(row);
        AlertConfirm({
            title: 'Are you sure?',
            desc: 'This cannot be undone.',
            onOk: () => {
              // console.log('ok');
              deleteAerationDetails(row.original.aeration._id, row.original._id).then(async (response) => {
                // console.log(response);

                if(response.result === 'success'){
                    setData(response.data);
                    NotificationManager.success('Deleted the Record successfully.')
                }
                setFlag(!flag);
              })
            },
            onCancel: () => {
              // console.log('cancel');
            }
        });
    }

    const handleExportZip = async () => {
        // console.log(rows);
        // const rowData = rows.map((row) => row.original);
        await createDownloadLinks(data);
    }

    const handleExportData = async () => {
        try {
            const export_url = new URL(`${url_}/export-aeration`);
            for(let item of columnFilters){
                export_url.searchParams.set(item.id, item.value)
            }
            const blob = await exportCSV(export_url.href);
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Aeration.csv');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
        console.error('Error downloading CSV:', error);
        }
    };

    const handleExportRows = (rows) => {
        const rowData = rows.map((row) => row.original);
        const csv = generateCsv(csvConfig)(rowData);
        download(csvConfig)(csv);
    }

    useEffect(() => {
        (async() => {
            const url = new URL(`${url_}/manage-aeration`);
            url.searchParams.set('page', pagination.pageIndex + 1);
            url.searchParams.set('limit', pagination.pageSize);

            for(let item of columnFilters){
                url.searchParams.set(item.id, item.value)
            }

            if(globalFilter.length > 0) {
                url.searchParams.set('farmername', globalFilter)
            }
            
            setIsRefetching(true);
            let response = await getTableData(url.href);
            console.log(response);
            setIsRefetching(false);
            let data = response.data;
            let count1 = response.filteredCount.length > 0 ? response.filteredCount[0].total : 0;
            let count2 = response.totalCount[0].total;
            setTotalCount(count2)
            setData(data);
            setRowCount(count1);

            response = await getAerationCounts();

            setPipeInstallationLength(response.pipeInstallments||0);
            setFirstDryLength(response.firstDry||0);
            setSecondDryLength(response.secondDry||0);
        })();

    },[columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize]);
    if(data=== undefined){
        return <Spinners1/>
    }
    return (
        <>
        
        <p style={{fontWeight : 550}}>{pipeInstallationLength === -1 ? 
                                            <ClipLoader
                                                loading={true}
                                                size={14}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            /> : `${pipeInstallationLength}/${totalCount}`} <span> Farmer pipe installation success✅✅</span></p>
        <p style={{fontWeight : 550}}>{firstDryLength === -1 ? 
                                            <ClipLoader
                                                loading={true}
                                                size={14}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            /> : `${firstDryLength}/${totalCount}`} <span> Farmer First Dry success ✅✅</span></p>
        <p style={{fontWeight : 550}}>{secondDryLength === -1 ?
                                            <ClipLoader
                                                loading={true}
                                                size={14}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            /> : `${secondDryLength}/${totalCount}`} <span> Farmer Second Dry success ✅✅</span></p>
        
            <MaterialReactTable
                columns={columns}
                data={data}
                enableSorting = {false}
                muiTableProps={{
                    sx: {
                    border: '1px solid rgba(81, 81, 81, 1)',
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                    border: '1px solid rgba(81, 81, 81, 1)',
                    },
                }}
                muiTableBodyCellProps={{
                    sx: {
                        border: '1px solid rgba(81, 81, 81, 1)',
                    },
                }}
                muiTablePaginationProps={{
                    showFirstButton: true,
                    showLastButton: true,
                }}
                renderTopToolbarCustomActions={({table}) => (
                    <div>
                        {/* <button className='btn btn-sm btn-primary btn-icon-text'
                            onClick={handleExportData}><i className='typcn typcn-download btn-icon-prepend'></i> <span>Export CSV</span>  
                        </button> &nbsp; */}
                        {/* Custom bootstrap upload file */}
                        
                        {/* {
                            data.length !=0 ? 
                            <><button
                                className='btn btn-sm btn-primary btn-icon-prepend btn-icon-text'
                                
                                onClick={() => handleExportZip()}
                                >
                                Export ZIP FILE <i className='typcn typcn-download'></i>
                            </button> &nbsp;
                            
                            </> : ''
                        } */}
                        {
                            userType != 'viewer' ? 
                            <><button className='btn btn-sm btn-bluish-green  btn-icon-text'
                            onClick={() => navigate('/add-aeration')} variant="contained">
                            <i className='typcn typcn-plus btn-icon-prepend'></i> <span>New </span>
                            </button> &nbsp;</> : null
                        }
                    </div>
                )}
                onEditingRowCancel={handleCancelRowEdits}
                renderRowActions={({ row  }) => (
                    <>
                    {
                        !row.original.aeration ? 'No Actions' : 
                        <div style={{ display: 'flex'}}>
                            <button type="button" className="btn btn-sm btn-danger btn-icon-text" style={{width : '100px'}} onClick={()=> handleCancelRowEdits(row)}>
                                Delete <i className="typcn typcn-trash btn-icon-append"></i>                                                                              
                            </button>
                        </div>
                    }
                    </>
                )}

                manualPagination={true}
                onPaginationChange={setPagination}
                manualFiltering = {true}
                onColumnFiltersChange={setColumnFilters}
                
                state={{
                    pagination,
                    showProgressBars: isRefetching,
                }}
                rowCount={rowCount}
                
                onGlobalFilterChange = {setGlobalFilter}
                
            />
            <NotificationContainer/>
        </>
    );
}

export default AerationTable;