import { useFormik } from "formik";
import styles from './../AddFeildInformation.module.css';
import { units } from "./dropdownOptions";
import { getTodaysDate } from "../../../../Utils/commonFunctions";
import WarningMessage from "../../../../Components/WarningMessage/WarningMessage";

const validate = (values) => {
    const errors = {};
    if(values.majorPest === '' && !values.otherPest){
        errors.majorPest = "Pest is required"
    }
    if(values.majorPest === 'other' && values.otherPest===""){
        errors.otherPest = "Pest is required"
    }
    if(values.pesticideUsed === ''){
        errors.pesticideUsed = "This Feild is required"
    }
    if(values.pesticideUsed === 'other' && values.otherPesticide === ''){
        errors.otherPesticide = "This Feild is required"
    }
    if(values.dosage === ''){
        errors.dosage = "Dosage is required"
    }
    if(values.unit === ''){
        errors.unit = "Unit is required"
    }
    if(values.dosage<0){
        errors.dosage = "Dosage can't negative"
    }
    if(values.date === ''){
        errors.date = "Date is required"
    }
    // console.log(errors);
    return errors;
}

const PestManagementForm = ({crop, pest, pesticides, change}) => {
    crop = crop.toLowerCase();
    const todaysDate = getTodaysDate();
    const formik = useFormik({
        initialValues : {
            majorPest : '',
            otherPest : '',
            unit : '',
            pesticideUsed : '',
            otherPesticide : '',
            dosage : '',
            date : ''
        },
        validate,
        onSubmit : (values) => {
            change('pests', values);
            formik.resetForm({});
        }
    })
    const handleChange = (event) => {
        let {value} = event.target;
        formik.setFieldValue('pesticideUsed', value);
        if(value!='other' && value!=''){
            formik.setFieldValue('otherPesticide', '');
            let idx = pesticides.findIndex(item => item.name === value);
            formik.setFieldValue('unit', pesticides[idx].unit);
        }else{
            formik.setFieldValue('unit', '');
        }
    }
    if(!pesticides){
        return <></>;
    }
    return (
        <div>
            <form>
                <div className="row">
                    {
                        crop === 'other' ? 
                        '' :
                        <div className="col-md-3">
                            <label className="col-form-label">Major Pest</label>   
                            <select
                            className={`form-control ${ formik.submitCount>0 && formik.errors.majorPest ? styles.formInputError : ""}`}
                            type="text" id='majorPest' placeholder="Major Pest" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.majorPest}>
                                <option value="">Select</option>
                                {
                                    pest?.map((item, index) => <option key={index} value={item}>{item}</option>)
                                }

                                <option value="other">Other</option>
                            </select>
                            {formik.submitCount>0 && formik.errors.majorPest ? <div className={styles.error}>{formik.errors.majorPest}</div> : ""} 
                        </div>
                    }

                    {
                        formik.values.majorPest === 'other' || crop === 'other' ? 
                        <div className="col-md-3">
                            <label className="col-form-label">Mention name of Other Pest</label>   
                            <input
                            className={`form-control ${ formik.submitCount>0 && formik.errors.otherPest ? styles.formInputError : ""}`}
                            type="text" id='otherPest' placeholder="Other Pest" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.otherPest}/> 
                            {formik.submitCount>0 && formik.errors.otherPest ? <div className={styles.error}>{formik.errors.otherPest}</div> : ""}
                        </div> : ''
                    }
                    
                    <div className="col-md-3">
                        <label className="col-form-label">Name of Pesticide Used</label>   
                        <select className={`form-control ${ formik.submitCount>0 && formik.errors.pesticideUsed ? styles.formInputError : ""}`}
                        id='pesticideUsed' placeholder="Pesticide Used" onChange={(event) => handleChange(event)} onBlur={formik.handleBlur} value={formik.values.pesticideUsed}>
                            <option value=''>Select</option>
                            {
                                pesticides.map((item, index) => <option key={index} value={item.name}>{item.name}</option> )
                            }
                            <option value="other">Other</option>
                        </select>
                        {formik.submitCount>0 && formik.errors.pesticideUsed ? <div className={styles.error}>{formik.errors.pesticideUsed}</div> : ""}
                    </div>
                    {
                        formik.values.pesticideUsed === 'other' ? 
                        <div className="col-md-3">
                            <label className="col-form-label">Mention name of Pesticide Used</label>   
                            <input
                            className={`form-control ${ formik.submitCount>0 && formik.errors.otherPesticide ? styles.formInputError : ""}`}
                            type="text" id='otherPesticide' placeholder="Pesticide Name" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.otherPesticide}/> 
                            {formik.submitCount>0 && formik.errors.otherPesticide ? <div className={styles.error}>{formik.errors.otherPesticide}</div> : ""}
                        </div> : null
                    }
                    {
                        formik.values.pesticideUsed != 'other' ? 
                        <div className="col-md-3">
                                <label className="col-form-label">Unit</label>   
                                <input type="text" 
                                placeholder="unit"
                                className={`form-control form-unit ${ formik.submitCount>0 && formik.errors.unit ? styles.formInputError : ""}`} 
                                disabled={true}
                                id='unit' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.unit}/>     
                                 {formik.submitCount>0 && formik.errors.unit ? <div className={styles.error}>{formik.errors.unit}</div> : ""} 
                        </div> : 
                        <div className="col-md-3">
                            <label className="col-form-label">Unit</label>   
                            <select type="text" 
                            className={`form-control form-unit ${ formik.submitCount>0 && formik.errors.unit ? styles.formInputError : ""}`} 
                            id='unit' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.unit}>
                                <option value=''>Select Unit</option>
                                {
                                    units.map((item) => <option value={item}>{item}</option>)
                                }
                            </select>  
                            {formik.submitCount>0 && formik.errors.unit ? <div className={styles.error}>{formik.errors.unit}</div> : ""}    
                        </div>
                    }
                    <div className="col-md-3">
                        <label className="col-form-label">Dosage of Pesticide</label>   
                        <input
                        className={`form-control ${ formik.values.dosage <0 || (formik.submitCount>0 && formik.errors.dosage) ? styles.formInputError : ""}`} 
                        type="number" id='dosage' placeholder="Dosage" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.dosage}/>
                        {formik.values.dosage<0 || (formik.submitCount>0 && formik.errors.dosage) ? <div className={styles.error}>{formik.errors.dosage}</div> : ""}
                    </div>
                    <div className="col-md-3">
                        <label className="col-form-label">Date of Application</label>   
                        <input className={`form-control ${ formik.submitCount>0 && formik.errors.date ? styles.formInputError : ""}`} 
                        type="date" max={todaysDate} id='date' placeholder="Applied Date" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.date}/>
                        {formik.submitCount>0 && formik.errors.date ? <div className={styles.error}>{formik.errors.date}</div> : ""} 
                    </div>
                    
                </div>
                <div className="row col-md-3">
                    <button className="btn btn-secondary mt-2" type="button" onClick={formik.handleSubmit}>Add</button>
                </div>
                <WarningMessage/>
            </form>
        </div>
    ); 
};

export default PestManagementForm;