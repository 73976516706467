
import Layout from "../../../Layout";
import FarmerData from "./FarmerData/FarmerData";

const ManageOnboardingInformation = () => {
    
    return (
        <FarmerData/>
    );
}

export default ManageOnboardingInformation;