import Layout from "../../../Layout";
import LandRecords from "./LandRecords/LandRecords";

const AddLandRecords = () => {
    
    
    return (
       
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row" style={{paddingTop : '80px'}}>
                            <div className="col-12 grid-margin">
                                <div className="card">
                                    <div className="card-body1">
                                        <h4>Land Records</h4>
                                        <LandRecords />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    );
}

export default AddLandRecords;