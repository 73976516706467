import Map from "./Map";

const ViewPolygonFile = () => {
    
    return ( 
        <div>
            <Map/>
        </div>
    );
}

export default ViewPolygonFile;