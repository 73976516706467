
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
// This component shows AWD Area percentage in doughnut chart.
const AWDAreaPieChart = ({data}) => {
   
    
    // This is for line number 42, for data part. [awdArea, (totalArea - awdArea).toFixed(2)]
    return (
        <>
        <div className="dashboard-heading">
            <h5 className="m-0 text-white">AWD Area Vs Non AWD Area</h5>
        </div>
        <div className="dashboard-chart">
            <Doughnut
                data={{
                    labels: ['AWD Area', 'Non-AWD Area'], 
                    datasets: [
                    {
                        label: "AWD Area Covered",
                        data: [data.totalAWDArea, data.totalArea-data.totalAWDArea],
                        backgroundColor: [
                            '#efc639',
                            '#d18a49'
                        ],
                        borderColor: "black",
                        borderWidth: 1,
                    }
                    ]
                }}
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text : 'Pie chart shows the area covered by AWD Farmers in sq.metres'
                    },
                    legend: {
                      display: true
                    },
                    datalabels: {
                      display : true,
                      color: 'black', // label text color
                      textAlign: 'center',
                      font: {
                        size: 16, // font size
                        weight: 'bold',
                      },
                      formatter: (value, context) => {
                        console.log(context)
                        if(value!=0){
                          return `${value} Acres`;
                        }
                        else{
                          return '';
                        }
                       
                        // Or any other formatting you want to apply
                      },
                    },
                    pieceLabel: {
                        render: 'value'
                     }
                  }
                }} 
                plugins={[ChartDataLabels]}
              />
        </div>
        </>
    );
}

export default AWDAreaPieChart;