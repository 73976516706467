import { useFormik } from "formik";
import styles from './Documents.module.css';
import { addDocument } from "../../APIs/apiCalls";
import { compressImage, generateDataURIFromImage, getFormData } from "../../Utils/commonFunctions";
import { NotificationManager } from "react-notifications";
import DisplayFile from "./DisplayFile";
import { useState } from "react";
import { acceptedMimeTypes } from "./options";
import SubmitButton from "../../Components/Buttons/SubmitButton";
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const validate = (values) => {
    const errors = {};
    const panFormat = new RegExp("^[A-Z]{5}[0-9]{4}[A-Z]{1}$");
    if(values.panNumber === ''){
        errors.panNumber = 'PAN Number is required.';
    }else if(panFormat.test(values.panNumber) === false){
        errors.panNumber = 'PAN Number is invalid.';
    }
    if(values.document === ''){
        errors.document = "PAN Card is required";
    }
    return errors;
}
const PanCardForm = ({type, farmerId, farmer, reset}) =>{
    const [disabled, setDisabled] = useState(false);
    const formik = useFormik({
        initialValues : {
            panNumber : '',
            document : '',
            uri : ''
        },
        validate,
        onSubmit : async (values) => {
          
            setDisabled(true);
            if(farmerId===undefined){
                NotificationManager.error("Farmer Id is required.")
            }
            
            const obj = {...values, type, farmerId};
            const entry = getFormData(obj);
            const response = await addDocument(entry);

            setDisabled(false);
            
            if(response.result === 'success'){
                formik.resetForm({});
                reset();
                NotificationManager.success('Document saved Successfully');
            }
        }
    });
    const maxFileSize = process.env.REACT_APP_FILE_MAX_SIZE; //in kbs
    const changeHandler = async (event) => {
        let file = event.target.files[0];
        if(file.type != 'application/pdf'){
            file = await compressImage(file);
        }
        if(file){
            const {size, type} = file;
            //convert size(bytes) to kbs.
            const fileSizeInKB = size / 1024;
            if(acceptedMimeTypes.includes(type) === false){
                formik.setFieldError('document',`File extension should be pdf, jpg or png.`);
            }else if(fileSizeInKB > maxFileSize){
                formik.setFieldError('document',`Maximum size limit is ${maxFileSize/1024}mb`);
            }else{
                formik.setFieldValue('document', file);
            }
        }
    }
    useState(() => {
        // console.log(farmer);
    })
    return (
        <form className="mt-5" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
             <h5>Upload PAN Card</h5>
            <div className="row">
                <div className="col-md-12 mt-2">
                    <label className="col-form-label">PAN Number</label>
                    <input type="text" className={`form-control ${formik.touched.panNumber === true && formik.errors.panNumber ? styles.formInputError : ''}`} 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.panNumber}
                    placeholder="PAN Number"
                    id="panNumber"/>
                    {formik.touched.panNumber === true && formik.errors.panNumber ? <div className={styles.error}>{formik.errors.panNumber}</div> : ""}
                </div>
                <div className="col-md-12 mt-2">
                    <label className="col-form-label">PAN Card <span className="text-warning"><i className="typcn typcn-warning"></i>Maximum file size limit is {maxFileSize/1024}mb</span></label>
                    <input type="file" className={`form-control ${formik.touched.document === true && formik.errors.document ? styles.formInputError : ''}`} 
                    onChange={(event) => changeHandler(event)}
                    onBlur={formik.handleBlur}
                    id="document"/>
                    {formik.touched.document === true && formik.errors.document ? <div className={styles.error}>{formik.errors.document}</div> : ""}
                    {
                        formik.values.document!="" && <DisplayFile mimetype = {formik.values.document.type} uri={URL.createObjectURL(formik.values.document)} />
                    }
                </div>
                <div className="col-md-12">
                    <SubmitButton disabled={disabled}/> &nbsp;
                    <button type="reset" id="resetbtn" className="btn btn-danger mt-2">Reset</button>
                </div>
            </div>
        </form>
    )
}

export default PanCardForm;