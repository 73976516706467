import { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
const Spinners1 = () => {
    
    const [loading, setLoading] = useState(true);
    const [color, setColor] = useState("#173f48");
    const override = {
        display: "block",
        margin: "0 auto",
        height : '65px',
        width : '65px',
        alignItems : "center",
    };
    return (
        <div className="d-flex">
            
            <ClipLoader
                color={color}
                loading={loading}
                cssOverride={override}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>
    );
}

export default Spinners1;