import { useFormik } from "formik";
import styles from './Documents.module.css';
import { compressImage, generateDataURIFromImage, getFormData } from "../../Utils/commonFunctions";
import DisplayFile from "./DisplayFile";
import { addDocument } from "../../APIs/apiCalls";
import { NotificationManager } from "react-notifications";
import { useState } from "react";
import { acceptedMimeTypes } from "./options";
import SubmitButton from "../../Components/Buttons/SubmitButton";

const validate = (values) => {
    const errors = {};
    
    const aadharFormat = new RegExp('^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$');

    if(values.aadharNumber === ''){
        errors.aadharNumber = 'Aadhar Number is required';
    }else if(aadharFormat.test(values.aadharNumber) ===false){
        errors.aadharNumber = 'Aadhar Number is invalid';
    }
    if(values.frontImage === ''){
        errors.frontImage = 'Front Image is required';
    }
    if(values.backImage === ''){
        errors.backImage = 'Back Image is required';
    }
    return errors;
}

const AadharCardForm = ({type, farmerId, farmer, reset}) =>{
   // console.log(farmerId);
    const [disabled, setDisabled] = useState(false);
    const [backImageUploaded, setBackImageUploaded] = useState(false);
    const [frontImageUploaded, setFrontImageUploaded] = useState(false);
    const maxFileSize = process.env.REACT_APP_FILE_MAX_SIZE; //in kbs
    const formik = useFormik({
        initialValues : {
            aadharNumber : '',
            frontImageUri : '',
            frontImage : '',
            backImageUri : '',
            backImage : '',
        },
        validate,
        onSubmit : async(values) => {
            // console.log(values);
            if(farmerId===undefined){
                NotificationManager.error("Farmer Id is required.")
            }
            const obj = {...values, type, farmerId};
            const entry = getFormData(obj);
            setDisabled(true);
            const response = await addDocument(entry);
            setDisabled(false);
            if(response.result === 'success'){
                NotificationManager.success('Document saved Successfully');
                formik.resetForm({});
                reset();
            }
        }
    })
    const changeHandler = async (event) => {
        const {files, name} = event.target;
        let file = files[0];
        if(file.type != 'application/pdf'){
            file = await compressImage(file);
        }
        if(file){
            if(name === 'frontImage'){
                
                const {size, type} = file;
                //convert size(bytes) to kbs.
                const fileSizeInKB = size / 1024;
                if(acceptedMimeTypes.includes(type) === false){
                    formik.setFieldError('frontImage',`File extension should be pdf, jpg or png.`);
                }else if(fileSizeInKB > maxFileSize){
                    formik.setFieldError('frontImage',`Maximum size limit is ${maxFileSize/1024}mb`);
                }else{
                    formik.setFieldValue('frontImage', file);
                }
            }

            if(name === 'backImage'){
                const {size, type} = file;
                //convert size(bytes) to kbs.
                const fileSizeInKB = size / 1024;
                if(acceptedMimeTypes.includes(type) === false){
                    formik.setFieldError('backImage',`File extension should be pdf, jpg or png.`);
                }else if(fileSizeInKB > maxFileSize){
                    formik.setFieldError('backImage',`Maximum size limit is ${maxFileSize/1024}mb`);
                }else{
                    formik.setFieldValue('backImage', file);
                }
            }
            
            
        }
    }
    useState(() => {
        if(farmer.aadharNumber!=''){
            formik.setFieldValue('aadharNumber', farmer.aadharNumber);
        }
    })
    return (
        <form className="mt-5" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <h5>Upload Aadhar Card</h5>
            <div className="row">
                <div className="col-md-12 mt-2">
                    <label className="col-form-label">Aadhar Number*</label>
                    <input type="text" className={`form-control ${formik.touched.aadharNumber === true && formik.errors.aadharNumber ? styles.formInputError : ''}`} id="aadharNumber"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.aadharNumber}
                    placeholder="Aadhar Number"/>
                    {formik.touched.aadharNumber === true && formik.errors.aadharNumber ? <div className={styles.error}>{formik.errors.aadharNumber}</div> : ""}
                </div>
                <div className="col-md-12">
                    <label className="col-form-label">Aadhar Card Front Image* <span className="text-warning"><i className="typcn typcn-warning"></i>Maximum file size limit is {maxFileSize/1024}mb</span></label>
                    <input type="file" name="frontImage" className={`form-control ${formik.touched.frontImage === true && formik.errors.frontImage ? styles.formInputError : ''}`} id="frontImage"
                    onChange={(event) => changeHandler(event)}
                    onBlur={formik.handleBlur}
                    />
                    {formik.touched.frontImage === true && formik.errors.frontImage ? <div className={styles.error}>{formik.errors.frontImage}</div> : ""}
                    {
                        formik.values.frontImage!="" ?  <DisplayFile uri = {URL.createObjectURL(formik.values.frontImage)} mimetype={formik.values.frontImage.type}/> : null
                    }
                   
                </div>

                <div className="col-md-12">
                    <label className="col-form-label">Aadhar Card Back Image* <span className="text-warning"><i className="typcn typcn-warning"></i>Maximum file size limit is {maxFileSize/1024}mb</span></label>
                    <input type="file" name="backImage" className={`form-control ${formik.touched.backImage === true && formik.errors.backImage ? styles.formInputError : ''}`} id="backImage"
                    onChange={(event) => changeHandler(event)}
                    onBlur={formik.handleBlur}
                    />
                    {formik.touched.backImage === true && formik.errors.backImage ? <div className={styles.error}>{formik.errors.backImage}</div> : ""}
                    {
                        formik.values.backImage !="" ? <DisplayFile uri = {URL.createObjectURL(formik.values.backImage)} mimetype={formik.values.backImage.type}/> : null
                    }
                    
                </div>
                
                <div className="col-md-12">
                    <SubmitButton disabled={disabled}/> &nbsp;
                    <button type="reset" id="resetbtn" className="btn btn-danger mt-2">Reset</button>
                </div>
            </div>
        </form>
    )
}

export default AadharCardForm;