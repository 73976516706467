import { useEffect, useState } from "react";
import StatusRecords from "./StatusRecords";

import 'chart.piecelabel.js';
import BarChart from "./BarChart";
import { getDashboardData, getfarmers } from "../../APIs/apiCalls";
import Layout from "../../Layout";
import StatesCoveredMap from "./StatesCoveredMap";
import StatesBarChart from "./StatesBarChart";
import PieChart from "./PieChart";
import ClipLoader from "react-spinners/ClipLoader";
import ToggleSwitch from "./ToggleSwitch";
import { useNavigate } from "react-router-dom";
import AWDDashboard from "./AWDDashboard/AWDDashboard";
const Dashboard = () => {
    const navigate = useNavigate();
    const [data, setData] = useState(undefined);
    const [checked, setChecked] = useState(false);
    
    useEffect(() => {
        (async() => {
                const data = await getDashboardData();
                console.log(data);
                setData(data);
                // checked === false ? setFarmers(farmers_) : setFarmers(farmers_.filter((farmer) =>  farmer.isAWDFarmer === true));
        })();
    }, []); 
    
    return(
   
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row" style={{paddingTop : '78px'}}>
                            <div className="col-md-12 grid-margin">
                                <div className="card" >
                                    <div  className='dashboard'>
                                        <ToggleSwitch ischecked={checked} change={(value) => setChecked(value)}/>
                                        {
                                            data!=undefined ? <StatusRecords data={data} isAWDFarmer={checked}/> : 
                                            <>Loading <ClipLoader
                                                loading={true}
                                                size={14}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            /> <ClipLoader
                                                loading={true}
                                                size={14}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            /> <ClipLoader
                                                loading={true}
                                                size={14}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            /></>
                                        }

                                        {
                                            checked === false ? 
                                            <div>
                                                <div className="row mt-3">
                                                    {
                                                        data!=undefined ? <div className="col-md-4" style={{ padding : '10px'}}> <StatesBarChart data={data}/> </div> : ''
                                                    }
                                                    {
                                                        data!=undefined ? <div className="col-md-8" style={{ padding : '10px'}}><BarChart data={data}/></div> : ''
                                                    }
                                                </div>

                                                <div className="row mt-3">
                                                    {
                                                        data!=undefined ? <div className="col-md-6"> <StatesCoveredMap data={data}/> </div> : ''
                                                    }

                                                    {
                                                        data!=undefined ? 
                                                        <div className="col-md-6"> <PieChart data={data}/></div> : ''
                                                    }
                                                </div>
                                            </div> : 
                                            <AWDDashboard isAWDFarmer={checked} data={data}/>
                                        }
                                    </div>
                                  
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
                
    );
};

export default Dashboard;