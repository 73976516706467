
import TrackingTable from "./TrackingTable";

const TrackUsers = () => {
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="row" style={{paddingTop : '80px'}}>
                    <div className="col-12 grid-margin">
                        <div className="card">
                            <div className="card-body">
                                <h4>Log Records</h4>
                                <TrackingTable/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default TrackUsers;