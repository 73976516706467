import React, { useEffect, useState } from 'react';
import { getFeildTeamById, isTokenValid } from '../../APIs/apiCalls';
import Layout from '../../Layout';

const AdminProfile = () => {
    const [data, setData] = useState(undefined);
    
    useEffect(() => {
        const token = localStorage.getItem('token');
       
        isTokenValid({token}).then((response) => {
            const id = response.data._id;
            // console.log(response);
            getFeildTeamById(id).then((response_) => {
                // console.log(response_);
                setData(response_.data);
            })
        })
    }, [])
    if(typeof data === 'undefined'){
        return <>Loading..</>
    }
  return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="row" style={{paddingTop : '80px'}}>
                    <div className="col-12 grid-margin">
                        <div className="card">
                            <div className="card-body">
                                <h5><u>Admin Profile</u></h5> <br/>
                                {
                                    data && data.firstname ? <>Name : { data.firstname.toUpperCase() + ' ' + data.lastname.toUpperCase() } <br/><br/> </> : null
                                }
                                Email : { data.email } <br/><br/>
                                Role : { data.type.toUpperCase() } <br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default AdminProfile

{/*  */}