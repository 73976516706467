export const mapping = {
    'ownnumber'  :'Own Number',
    'selfemployed' : 'Self Employed',
    'otherType' : 'Other Type',
    'otherDisease' : 'Other Disease',
    'otherFertilizer' : 'Other Fertilizer',
    'otherPest' : 'Other Pest',
    'otherPesticide' : 'Other Pesticide',
    'majorDiseases' : 'Major Diseases',
    'otherBactericideOrFungicideUsed' : 'Other Bactericide Or Fungicide Used',
}

